import React, { Component } from 'react';

import Header from '../../header/Header';
import './AddressMobile.scss';
import iconBack from '../../../resources/svg/address/iconBack.svg';
import iconLocation from '../../../resources/svg/address/iconLocation.svg';
import Button from '@material-ui/core/Button';
import BotonVolver from '../../common/elements/ReturnButton';
import Footer from '../../common/elements/Footer';

import { CONSTANTS } from '../../../utils/Constants';

class AddressMobile extends Component {

    render() {
        const {onBack, onClick, user, provision,validHeader} = this.props; 
        return (
            <div className='contenedor-home'>
                <Header />  
                 <section id="addressMobile">
                    <div className="container">
                        <div className={validHeader===1?"content-body-wo-header":"content-body"}>
                            <div className="box-info">
                                <BotonVolver onClick={onBack} />
                            </div>  
                            <div>
                                 <span><p id="lbl-title-address" className="texto">Tu dirección registrada es:</p></span>
                            </div> 
                            <div className="box-address">
                                <div className="address-info d-flex">
                                    <div className="info d-flex">
                                        <div className="icon">
                                            <img src={iconLocation} alt="" />
                                        </div>
                                        <div className="text-address">

                                            <div className="div-address"> 
                                               {provision.customer.address}
                                            </div>
                                            
                                            <p id="lbl-reference-address" className="text-reference">{provision.customer.reference}</p>
                                            <span><p id="lbl-district-address" className="text-district">{provision.customer.district}, {provision.customer.province}</p></span>
                                        </div>
                                    </div>
                                </div>
                            </div>  

                            { (() => {
                                if(provision.scheduler === null || provision.scheduler === 'PSI'){
                                    return  <div>
                                                <div className="check-address">
                                                    Si tu dirección de instalación es incorrecta haz click para corregir y te llamaremos en breve
                                                </div>  

                                                {
                                                    provision.activeStatus != CONSTANTS.STATUS.ACTIVE ? 
                                                        <Button  className="btn-tz primaryButton" 
                                                                variant="contained"
                                                                id="btn-update-address" 
                                                                color="primary"
                                                                disabled>
                                                                CORREGIR DIRECCIÓN
                                                        </Button>
                                                        
                                                    :
                                                        <Button  className="primaryButton" 
                                                            variant="contained"
                                                            id="btn-update-address" 
                                                            color="primary"
                                                            onClick={onClick}>
                                                            CORREGIR DIRECCIÓN
                                                        </Button>
                                                }
                                
 
                                            </div>
                                }
                                else{
                                    return null;
                                }
                                })()
                            }
                            

                        </div>
                    </div>    
                </section>
                <Footer/>
            </div>
        )
    }

}

export default AddressMobile;