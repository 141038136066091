import React from 'react';
import iconBranding from '../../../resources/svg/footer/iconBranding.svg';
import './Footer.scss';
export default   ({}) => (
    <div id="footer">
        <div className="container">
            <div className="footer d-flex">
                
                 <img id="img-branding-footer" className="icon-branding" src={iconBranding} alt=""/>
                
                
            </div>
        </div>
    </div>
)