import React from 'react';
import './Alert.scss';
import iconClose from '../../resources/svg/login/iconClose.svg';
import iconAlert from '../../resources/svg/alertError/iconAlert.svg';
import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';

export default function Alert({ openAlert, errorMsj, onClose, onAccept }) 
{
  return (

      <Modal 
        classNames={{
          overlay: 'alert-overlay',
          modal: 'alert-modal',
        }}
        open={openAlert} 
        onClose={onClose}
        showCloseIcon={null} 
        center>

          <div id="errorModal" className="bxModalAlert d-flex flex-column justify-content-center align-items-center">
            <div className="close-wrapper">
                <img id="img-close-conditions" 
                    className="icon-close"
                    src={iconClose} 
                    onClick={onClose} 
                    alt="" />
            </div>
            <img className="llamada" src={iconAlert} alt="" />
            <h2 id="lbl-title-modalerror" className="title">{'¡Se ha producido un error!'}</h2>
            <p id="lbl-detail-modalerror" className="detalle" dangerouslySetInnerHTML={{__html: errorMsj}} />
            <div className="button-box">
              <Button id="btn-accept-modalerror" className="buttonAcept" variant="contained" color="primary" onClick={onAccept}>
                  ACEPTAR
              </Button>
            </div>

          </div>

      </Modal>
      
  )
}