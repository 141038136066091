import React, { Component } from 'react';
//import Radio from '../../common/elements/styledRadio/StyledRadio';
import Radio from '@material-ui/core/Radio';
import { connect } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import { CONSTANTS } from '../../../utils/Constants';

import './ContactsRadio.scss';


class ContactsRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'on'
        }
    }
    handleChange = (event) => {
        this.setState({
            selected: event.target.value
        });
        this.props.onChange(event.target.value);
        
    }
    componentDidMount =()=>{
        
        if(this.props.orderDetail.contacts !== null){
            if(this.props.orderDetail.contacts.length > 0){
                
                this.setState({selected: 'off'});
                
            } 
            
        } 
    }
    render() {
        
        return (
            <div id="contacts-radio">

                <FormControl component="fieldset">

                    <RadioGroup
                        aria-label="position"
                        className="wrp-radio"
                        name="position"
                        value={this.state.selected}
                        onChange={this.handleChange} row>

                        <FormControlLabel
                            value="on"
                            disabled={this.props.orderDetail.activeStatus != CONSTANTS.STATUS.ACTIVE ? true : false }
                            control={<Radio color="primary"/>}
                            label="Yo recibiré al técnico"
                            labelPlacement="top"
                            className={`radio-item ${this.state.selected == 'on' ? 'border-on': 'item-off'}
                            ${this.props.orderDetail.activeStatus != CONSTANTS.STATUS.ACTIVE ? 'item-disabled' : ''}
                            `}
                        />

                        <FormControlLabel
                            value="off"
                            disabled={this.props.orderDetail.activeStatus != CONSTANTS.STATUS.ACTIVE ? true : false}
                            control={<Radio color="primary"/>}
                            label="Otra persona recibirá al técnico"
                            className={`radio-item ${this.state.selected == 'off' ? 'border-on': 'item-off'}
                            ${this.props.orderDetail.activeStatus != CONSTANTS.STATUS.ACTIVE ? 'item-disabled' : ''}
                            `}
                            labelPlacement="start"
                        />

                    </RadioGroup>
                </FormControl>



            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
    }
}


const mapDispatchToProps = dispatch => {
    return {
       
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsRadio);