import React, { Component } from 'react';
import './Cancel.scss';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import Loading from '../../components/common/elements/Loading';
import * as securityActions from '../../store/actions/security.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as provisionSelectors from '../../store/selectors/provision.selectors';
import * as commonSelectors from '../../store/selectors/common.selectors';
import Alert from '../modals/Alert';

import CancelWeb from './web/CancelWeb';
import CancelMobile from './mobile/CancelMobile';
import { CONSTANTS } from '../../utils/Constants';
import { animateScroll as scroll } from 'react-scroll';



class Cancel extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showLoading: false,
            errorMsj: '',
            openAlertError: false,
            selectedRadio: '',
            showReason: false,
            reason: '',
            title: CONSTANTS.MESSAGE.CANCEL.TITLE_UNCHECKED,
            disabledCancel: true
        }
    }

    componentDidMount = () => {
        scroll.scrollToTop();
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'cancelar pedido',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });
    }


    onCloseError = () => {
        this.setState({
            openAlertError: false
        })

    }

    errorMensaje = (mensaje, estado) => {
        this.setState({
            errorMsj: mensaje,
            openAlertError: estado
        })
    }

    onClick = () => {
        //Se valida el tiempo límete de sesión
        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.cancelProvision();
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }

    cancelProvision = () => {

        this.setState({ showLoading: true });
        
        this.props.setProvisionCancellation(this.state.selectedRadio, this.state.reason).then(resp => {
            
            if (resp.status === 'OK') {
                this.props.history.push({
                    pathname: CONSTANTS.PATH.CONFIRMATION_CANCEL
                });
            } else {
                this.setState({ showLoading: false });
                this.errorMensaje(resp.message, true);
            }
        });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'cancelar pedido',
            eventAction: 'click',
            eventLabel:  'cancelar pedido',
            valorElemento: this.props.orderDetail.activeStatus,
            idPedido:  this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: this.props.schedule != null ? this.props.schedule.stpsiCode: '',
            fechaAgendamiento: this.props.schedule != null ? this.props.schedule.scheduleDate: '',
            turno: this.props.schedule != null ? this.props.schedule.scheduleRange: '',
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.orderDetail.customer.phoneNumber,
             productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
        
    }

    onBack = () => {

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'cancelar pedido',
            eventAction: 'click',
            eventLabel:  'volver',
            valorElemento: this.props.orderDetail.activeStatus,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.goBack();
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }

    onChangeValue = event => {
        let disabled = true;
        var valor = event.target.value;

        if (valor != "")
            disabled = false;

        this.setState({
            reason: valor,
            disabledCancel: disabled
        })

    }

    selectRadio = (value) => {

        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.selectRadioOption(value);
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

    }

    selectRadioOption = (value) => {
        
        let showReson = false;
        let disabled = true;
        let titulo = '';

        if (value === "OTRO") {
            showReson = true;
        }
        else
            disabled = false;

        titulo = CONSTANTS.MESSAGE.CANCEL.TITLE_UNCHECKED;
           
        this.setState({
            selectedRadio: value,
            showReason: showReson,
            title: titulo,
            disabledCancel: disabled
        });


        let estadoElemento = '';
        if(value == 'NO_SOLICITE'){
            estadoElemento = 'no solicité el pedido';
        } else if(value == 'NO_DESEO'){
            estadoElemento = 'ya no deseo el pedido';   
        } else {
            estadoElemento = `Otro motivo`
        }
        
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'cancelar pedido',
            eventAction: 'click',
            eventLabel: estadoElemento,
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            estadoElemento: estadoElemento,
            nonInteraction: '1',

            
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

    }

    GTMclick = () =>{
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'cancelar pedido',
            eventAction: 'click',
            eventLabel:  'comentario',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.orderDetail.customer.phoneNumber,
             productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
    }

    onCancelClick = () => {
        if(!commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }

    render() {
        return (
            <section id="cancel">
                <Loading id="loading-cancel" showLoading={this.state.showLoading} />
                <div>
                    {
                        isMobile ?
                            <CancelMobile
                                selectRadio={this.selectRadio}
                                state={this.state}
                                onBlur= {this.GTMclick}
                                onClick={this.onClick}
                                onChangeValue={this.onChangeValue}
                                onBack={this.onBack}
                                validHeader = {this.props.validHeader}
                                onHeaderClick={this.onCancelClick}
                            />

                            :

                            <CancelWeb
                                selectRadio={this.selectRadio}
                                state={this.state}
                                onBlur= {this.GTMclick}
                                onClick={this.onClick}
                                onChangeValue={this.onChangeValue}
                                onBack={this.onBack}
                                onHeaderClick={this.onCancelClick}
                            />
                    }
                </div>
                <Alert id="mdl-error-cancel" openAlert={this.state.openAlertError} errorMsj={this.state.errorMsj} onClose={this.onCloseError} onAccept={this.onCloseError} />
            </section>
        )

    }

}

const mapStateToProps = state => ({
    user: state.security.userData,
    schedule: state.schedule.scheduleData,
    orderDetail: state.security.orderDetail,
    validHeader: state.security.userWebView,
    userLoginTime: state.security.userLoginTime,
})

const mapDispatchToProps = dispatch => ({
    
    getOrders: (request) => dispatch(securityActions.getOrders(request)),

    //setProvisionCancellation: (cause, detail) => dispatch(provisionActions.setProvisionCancellation(cause, detail)),
    setProvisionCancellation: (cause, detail) => dispatch(securityActions.setProvisionCancellation(cause, detail)),
    provisionUpdate:(data) =>dispatch(securityActions.provisionUpdate(data))
})

//export default Cancel;
export default connect(mapStateToProps, mapDispatchToProps)(Cancel);