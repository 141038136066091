import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
//import Radio from '../../common/elements/styledRadio/StyledRadio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './ScheduleRadio.scss';
import { isMobile } from "react-device-detect";

export default class ScheduleRadio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: ''
        }
    }

    handleChange = (event) => {
        this.setState({
            selected: event.target.value
        });
        this.props.onChangeTurn(event.target.value);
    }

    render() {
        
        return (
            <div id="schedule-radio">
                <FormControl component="fieldset">
                
                    <RadioGroup
                        aria-label="position"
                        className="wrp-radio"
                        name="position"
                        value={this.props.disabledTurns? '':this.state.selected}
                        onChange={this.handleChange} 
                        row>
                        {/* <p id="lbl-turntitlemorning-schedule" className="detailTurns">Turno Mañana:</p> */}
                        <FormControlLabel
                            value="AM"
                            id="lbl-turnmorning-schedule"
                            //control={<Radio id="rb-turnmorning-schedule" color="primary" />}
                            control={<Radio id="rb-turnmorning-schedule" color="primary"/>}
                            label={"Turno mañana / De 9 am a 1 pm"}
                            labelPlacement="top"
                            disabled = {this.props.disabledMorning}
                            className={`radio-item ${!this.props.disabledMorning ? (!this.props.disabledTurns && this.state.selected == 'AM' && 'border-on') : 'item-off'}`}
                            //className={`radio-item ${!this.props.disabledTurns && this.state.selected == 'AM' && 'border-on'}`}
                        />
                        {this.props.disabledMorning?
                            <p id="lbl-detailturnmorning-schedule" className="detailDisabled"><span>El turno mañana no está disponible en este día</span></p>
                            : null
                        }
                        {/* <p id="lbl-turntitleafternoon-schedule" className="detailTurns">Turno Tarde:</p> */}
                        <FormControlLabel
                            value="PM"
                            id="lbl-turnafternoon-schedule"
                            control={<Radio id="rb-turnafternoon-schedule" color="primary"/>}
                            ///label={isMobile? "Turno tarde / De 1 pm a 6 pm" : "De 1:00 pm a 6:00 pm" }
                            label={"Turno tarde / De 1 pm a 6 pm"}
                            disabled = {this.props.disabledEvening}
                            className={`radio-item ${!this.props.disabledEvening ? (!this.props.disabledTurns && this.state.selected == 'PM' && 'border-on') : 'item-off'}`}
                            labelPlacement="start"
                        />
                        {this.props.disabledEvening?
                            <p id="lbl-detailturnafternoon-schedule" className="detailDisabled"><span>El turno tarde no está disponible en este día</span></p>
                            : null
                        }
                    </RadioGroup>

                </FormControl>

            </div>

        )
    }
}