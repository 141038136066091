import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TrackingFault.scss';

import { CONSTANTS } from '../../../utils/Constants';
import iconRegistered from '../../../resources/svg/home/iconPencil.svg';
import iconPreNotDone from '../../../resources/svg/home/tracking/iconPreNotDone.svg';
import iconPreNotDoneTraza from '../../../resources/svg/home/tracking/iconPreNotDoneTraza.svg';
import iconCancel from '../../../resources/svg/home/iconCancel.svg';
import iconWoInit from '../../../resources/svg/home/fault/iconCircleTools.svg';
import iconWoCompleted from '../../../resources/svg/home/iconWoCompleted.svg';
import iconTechnician from '../../../resources/svg/home/iconCar.svg';
import iconPendientePago from '../../../resources/svg/home/tracking/iconPendientePago.svg'

import * as faultActions from '../../../store/actions/averia.actions';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import * as commonSelectors from '../../../store/selectors/common.selectors';

class Tracking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iconStatus:'',
            textStatus:'',
            borderStatus:'',
            triangleBorder: ''
        }
    }

    componentDidMount = () => {

        if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART) {
            if(this.props.orderDetail.woPreStart.availableTracking == true){
                window.dataLayer.push({
                    event: 'atm.event',
                    eventCategory: 'averia detalle',
                    eventAction: 'view',
                    eventLabel: 'ver recorrido del tecnico',
                    valorElemento: this.props.orderDetail.activeStatus,
                    correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
                    nonInteraction: '1',
                    //datos genéricos 
                    userId: this.props.user.documentNumber,
                    tipoCliente: this.props.user.documentType.toLowerCase(),
                    numero: this.props.orderDetail.customer.phoneNumber,
                    productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : '',
                    hasTrackingUrl: this.props.orderDetail.woPreStart.availableTracking
                });
            }
        }

        if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CANCEL) {
            this.setState({
                textStatus: 'La visita técnica fue cancelada',
                iconStatus: iconCancel,
                borderStatus: 'border-red',
                triangleBorder: 'red-side'
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOT_DONE){
            this.setState({
                textStatus: 'Tu avería no fue solucionada',
                iconStatus: iconCancel,
                borderStatus: 'border-red',
                triangleBorder: 'red-side'
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.COMPLETE) {
            this.setState({
                textStatus:  'Solucionamos tu avería',
                iconStatus: iconWoCompleted,
                borderStatus: 'border-green',
                triangleBorder: 'green-side'
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_INIT) {
            this.setState({
                textStatus: 'El técnico está solucionando tu avería',
                iconStatus: iconWoInit
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART) {
            this.setState({
                textStatus: 'El técnico está en camino',
                iconStatus: iconTechnician
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ACTIVE) {
            this.setState({
                textStatus: 'Estamos atendiendo el reporte de tu avería',
                iconStatus: iconRegistered
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TRIAJE_LIQUIDADO) {
            this.setState({
                textStatus: 'Solucionamos tu avería',
                iconStatus: iconRegistered,
                borderStatus: 'border-green',
                triangleBorder: 'green-side'
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TRIAJE_PENDIENTE) {
            this.setState({
                textStatus: 'Estamos atendiendo el reporte de tu avería',
                iconStatus: iconRegistered
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TRIAJE_MASIVA) {
            this.setState({
                textStatus: 'Estamos atendiendo el reporte de tu avería',
                iconStatus: iconRegistered
            })
        }
        else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE){
            this.setState({
                iconStatus: iconPreNotDone,
                borderStatus:'border-red',
                triangleBorder: 'red-side'
            })
        }
        else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA){
            this.setState({
                iconStatus: iconPreNotDoneTraza,
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOTDONE_TRAZA){
            this.setState({
                iconStatus: iconCancel,
                borderStatus:'border-red',
                triangleBorder: 'red-side'
            })
        }
        else{
            this.setState({
                textStatus: 'En espera de pago por instalación',
                iconStatus: iconPendientePago,
                borderStatus: 'border-red',
                triangleBorder: 'red-side'
              })
        }
       
    }

    clickTracker = () => {

        this.props.setUpdateShowLocation().then(resp => {
            console.log("resp UpdateShowLocation", resp);
        });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'avería detalle',
            eventAction: 'click',
            eventLabel: 'ver ubicación',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos  
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.props.onOpenMapView()
    }

    render() {
        return (
            <div id="tracking-fault">
                <div className={`triangle ${this.state.triangleBorder}`}></div>
                <div className={`box-tracking ${this.state.borderStatus}`}>
                        <div className="info d-flex">
                            <img id="img-status-tracking" className="icon" src={this.state.iconStatus} alt="" />
                            <div id="lbl-status-tracking" className="text">
                                 {/* {this.state.textStatus} */}
                                 {this.props.orderDetail.frontSpeech}
                            </div>
                        </div>
                    <div className="track-act">
                    {/* {
                        (() => {
                            if (this.props.order[0].activeStatus === CONSTANTS.STATUS.WO_PRESTART && this.props.order[0].woPreStart.availableTracking == true) {
                                return <button id="btn-view-tracking" onClick={this.clickTracker} className="btn-tz btn-tracking-web pointer">Ver recorrido</button>
                            } else {
                                return null;
                            }
                        })()
                    } */}
                    </div>
                </div>
                {/* {
                    (() => {
                        if (this.props.order[0].activeStatus === CONSTANTS.STATUS.WO_PRESTART && this.props.order[0].woPreStart.availableTracking == true) {
                            return <button id="btn-view-tracking" onClick={this.clickTracker} className="btn-tz btn-tracking-mob pointer">VER RECORRIDO DEL TÉCNICO</button>
                        } else {
                            return null;
                        } 
                    })()
                } */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail, //provisionSelectors.getOrderById(state.security.ordersIdSelected, state.security.ordersData),         
        technician: state.schedule.scheduleTechnician
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setUpdateShowLocation: () => dispatch(faultActions.setUpdateShowLocation())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tracking);