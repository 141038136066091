import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../utils/Constants';
import './List.scss';

import Alert from '../modals/Alert'
import Loading from '../common/elements/Loading';
import BoxProduct from './boxProduct/BoxProduct';
import backgroundWeb from '../../resources/svg/login/backgroundWeb.svg';
import iconMovistar from '../../resources/svg/onboarding/iconMovistarBlue.svg';

import Grid from '@material-ui/core/Grid';

import * as faultActions from '../../store/actions/averia.actions';
import * as securityActions from '../../store/actions/security.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as commonSelectors from '../../store/selectors/common.selectors';

class List extends Component {
    constructor() {
        super();
        this.state = {
            showLoading: false,
            openAlert: false,
            errorMsj: ''
        }
    }

    componentDidMount(){

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'list',
            path: this.props.history.location.pathname,
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: '',
            productos: '' 
        });

        this.props.scheduleReset();
        this.props.setOrderDetailReset();

    }

    getOrderManagement = (selectedOrder) => {

        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.getOrderDetail(selectedOrder);
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

        //GTM
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'list',
            eventAction: 'click',
            eventLabel:  selectedOrder.activityType === CONSTANTS.PRODUCT.PROVISION_TYPE ? 'ver pedido' : 'ver avería',
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: '',
            productos: ''
        }); 
        
    }

    getOrderDetail = (selectedOrder) =>{

        this.setState({ showLoading: true })

        //cambios de jwt validateuser
        let body = {
            traceId: selectedOrder.traceId,
            orderCode: selectedOrder.orderCode,
            jwt: this.props.jwtAuthUser
        };

        if(selectedOrder.activityType.toLowerCase() === CONSTANTS.PRODUCT.PROVISION_TYPE){                        

            // enviar token
            this.props.sendProvisionToken(body).then(respToken => {
                this.setState({ showLoading: false })
                
                if(respToken.status === 'OK'){
                    this.props.setOrderDetail(selectedOrder);
                    this.props.setOrderType(selectedOrder.activityType);

                    // config security
                    this.props.setOrdeCode(selectedOrder.orderCode);
                    this.props.setDocNumber(selectedOrder.documentNumber);

                    this.props.history.push({
                        pathname: CONSTANTS.PATH.TOKEN
                    });

                } else {
                    this.setState({
                        openAlert : true,
                        errorMsj: respToken.message
                    });

                }
            });
        }else {

            this.props.sendFaultToken(body).then(respToken => {
                this.setState({ showLoading: false })

                if(respToken.status === 'OK'){
                    this.props.setOrderDetail(selectedOrder);
                    this.props.setOrderType(selectedOrder.activityType);

                    // config security
                    this.props.setOrdeCode(selectedOrder.orderCode);
                    this.props.setDocNumber(selectedOrder.documentNumber);

                    this.props.history.push({
                        pathname: CONSTANTS.PATH.TOKEN
                    });

                } else {
                    this.setState({
                        openAlert : true,
                        errorMsj: respToken.message
                    });

                }
            });
        }
               
    }

    apiGetSheduleFault = (order) => {

        this.setState({showLoading: true});
        this.props.getScheduledFault().then(resp => {
            this.setState({showLoading: false});

            if(resp.status === "OK"){
                this.props.history.push({
                    pathname: CONSTANTS.PATH.FAULT
                });
                
            }else {
                if(resp.status == 'ERROR_SECURITY') {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LOGIN
                    });
                } else {
                    this.setState({
                        errorMsj: resp.message,
                        openAlert : true
                    });
                }
            }

        });

    }

    apiScheduledOrder = () => {
        this.setState({showLoading: true});
        this.props.getScheduledOrder().then(resp => {
            this.setState({showLoading: false});
            if (resp.status === 'OK') {
                
                /* ===== Política de días de reagendamiento ===== */
                this.props.getApplicationParam(CONSTANTS.PARAM.PARAM_RESCHEDULE).then(response => {
                    if(response.status === 'OK'){
                        this.props.setSecurityReschedulePolicy(response.data.content.value);
                    }else{
                        this.props.setSecurityReschedulePolicy("1");
                    }
                });

                this.props.history.push({
                    pathname: CONSTANTS.PATH.HOME
                });
            } else {
                this.setState({
                    errorMsj: resp.message,
                    openAlert : true
                })
            }
        });
    }

    apiGetTechnician = () => {
        this.setState({showLoading: true});
        this.props.getTechnicianInfo().then(response => {
            this.setState({showLoading: false});
            if (response.status === 'OK') {
                this.props.history.push({
                    pathname: CONSTANTS.PATH.HOME
                });
            }else {
                this.setState({
                    errorMsj: response.message,
                    openAlert : true
                })
            }
        })
    }

    onCloseAlert = () => {
        this.setState({
            openAlert: false
        })
    }

    render() {
        return (
            <div id="list-products">
                <div style={{
                    backgroundImage: `url(${backgroundWeb})`, 
                    backgroundRepeat: 'no-repeat', 
                    backgroundPosition: 'center',
                    backgroundAttachment:'fixed', 
                    height: 'fit-content'}}>

                    <Loading showLoading={this.state.showLoading}/>

                    <img className="logo" src={iconMovistar} />
                    <div className="container">
                        <div className="content-body">
                            <div className="list-products">
                                <p id="lbl-title-list" className="list-title">
                                    Ahora selecciona el pedido o registro de avería que deseas gestionar
                                </p>
                                <Grid container spacing={3}>
                                    {
                                        this.props.provision.map(item =>(
                                            <Grid item xs={6}>
                                                <BoxProduct order= {item}  eventGetSchedule = {this.getOrderManagement}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <Alert openAlert={this.state.openAlert} errorMsj={this.state.errorMsj} onClose={this.onCloseAlert} onAccept={this.onCloseAlert} />            
            </div>

        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        validHeader: state.security.userWebView,
        provision: state.security.orderList,
        //validate user seguridad
        jwtAuthUser: state.security.jwtAuthUser,

        userLoginTime: state.security.userLoginTime,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        setOrderType: (activityType) => dispatch(securityActions.setOrderType(activityType)),
        setOrderDetail: (order) => dispatch(securityActions.setOrderDetail(order)),
        sendFaultToken: (idFault) => dispatch(securityActions.sendFaultToken(idFault)),
        sendProvisionToken: (idProvision) => dispatch(securityActions.sendProvisionToken(idProvision)),        

        setOrdeCode: (orderCode) =>  dispatch(securityActions.setOrdeCode(orderCode)),
        setDocNumber: (docNumber) => dispatch(securityActions.setDocNumber(docNumber)),

        setOrderDetailReset: () => dispatch(securityActions.setOrderDetailReset()),
        scheduleReset: () => dispatch(scheduleActions.scheduleReset()),        

        getApplicationParam: (request) => dispatch(securityActions.getApplicationParam(request)),
        setSecurityReschedulePolicy: (value) => dispatch(securityActions.setSecurityReschedulePolicy(value)),

        getScheduledOrder : () => dispatch(scheduleActions.getScheduledOrder()),
        getScheduledFault : () => dispatch(scheduleActions.getScheduledFault()),
        getTechnicianInfo: () => dispatch(scheduleActions.getTechnicianInfo()),
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(List);