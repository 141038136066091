import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './DevolutionWeb.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import iconDevolution from '../../../resources/svg/home/detail/iconDevolutionWeb.svg';
import iconWarning from '../../../resources/svg/home/detail/iconWarning.svg';
import * as commonSelectors from '../../../store/selectors/common.selectors';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';

class DevolutionWeb extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            collapse: false,
            collapseAddress: false,
            iconService: '',

            isPeruvian: this.props.orderDetail.customer.documentType == "DNI" ? true : false,
        }
    }

    onChangeCollapse = (e) => {
        this.setState({ collapse: !this.state.collapse });
    }

    onChangeCollapseAddress = (e) => {
        this.setState({ collapseAddress: !this.state.collapseAddress });
    }

    fullNameDate = () =>{
        let arrayDate = (moment(this.props.orderDetail.registerDate).format('YYYY-MM-DD')).split('-');
        let getNameMonth = commonSelectors.getMonthName(arrayDate[1]);

       return `${arrayDate[2]} de ${getNameMonth.charAt(0).toUpperCase() + getNameMonth.slice(1).toLowerCase()} del ${arrayDate[0]}`;
    }

    fullReferencesAddress = () => {
        let ref = this.props.orderDetail.customer.reference;
        ref = ref != null ? `Ref: ${ref}, ` : "";
        return ref;
    }

    fullReferencesDistrict = () => {
        let ref = this.props.orderDetail.customer.district;
        ref = ref != null ? ` ${ref}, ` : "";
        return ref;
    }

    fullReferencesProvince = () => {
        let ref = this.props.orderDetail.customer.province;
        ref = ref != null ? ` ${ref} ` : "";
        return ref;
    }

    clickPlacesOfPayment = () => {

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: 'ver lugares de pago',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

        this.props.onOpenPlaces()

    }

    fullNameDate = () =>{
        let arrayDate = (moment(this.props.orderDetail.upFront.paymentDate).format('YYYY-MM-DD')).split('-');
        let coma = this.state.isPeruvian ? ', ' : '';

        return ` ${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0] + coma}`;
    }

    render() {
        return (
            <div className="wrp-payment">
                <div className="container">
                    <div className="box-full-payment">
                        <div className="d-flex">
                            <p id="lbl-subtitle-payment" className="subtitle">Devolución de </p>
                            <p id="lbl-type-payment" className="type"> PAGO ADELANTADO</p>
                        </div>                                    
                        <div className="item-payment">
                            <div className="d-flex">
                                <div className="icon">
                                    <img id="img-icon-payment" src={iconDevolution} alt=""/>
                                </div>
                                <div className="info">
                                    <div className="wrap-cip-monto">
                                        <p id="lbl-code-payment" className="code">Fecha de devolución:</p>
                                        <p id="lbl-amount-payment" className="text"><strong className="text-bold lightblue" >{"A partir de" + this.fullNameDate() }</strong>{ this.state.isPeruvian ? 'Vigencia 5 meses.': ''}</p>
                                    </div>
                                    <p id="lbl-places-payment" className="text">Lugares de pago: <strong className="text-bold" >{ this.state.isPeruvian ? 'Ventanilla de agencias BCP' : 'Centros de atención Movistar '}</strong></p>
                                    <div className="wrap-warning">
                                        <img id="img-icon-payment" className="logo" src={iconWarning} alt=""/>
                                        {(() => {
                                            if(this.state.isPeruvian){
                                                return <p className="text">Recuerda acercarte con tu DNI.</p> 
                                            }else{
                                                return <p className="text">Acércate con tu documento de identidad y los siguientes datos: <strong className="lightblue" >{" C. Cte. " + this.props.orderDetail.codClienteAtis}</strong> y <strong className="lightblue" >{" C. Cta. " + this.props.orderDetail.codCuentaAtis}</strong></p>
                                            }
                                        })()}
                                    </div>
                                                                                
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DevolutionWeb);