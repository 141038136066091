import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../../utils/Constants';

import iconTrioMov from '../../../resources/svg/home/iconTrioMov.svg';
import iconHome from '../../../resources/svg/home/iconGeneric.svg';

import * as provisionSelectors from '../../../store/selectors/provision.selectors';


class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ADDRESS_CHANGED 
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE_PAGO
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.INGRESADO
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CANCEL
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CAIDA
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOT_DONE                   
        }
    }
    
    render() {
        console.log(":::::::::::::");
        console.log(this.props);
        console.log(":::::::::::::");
        const {onClickSchedule} = this.props;
        
        return (
            <div className={`box-product-info box-border ${
                            (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.FINALIZADO 
                                || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TERMINADA ) 
                                ? 'box-border-green' : 'box-border-greey'} `}>
                <div id="lbl-head-product" className="text-head">
                    NUEVO PEDIDO
                </div>
                <div className="wrp-text">
                    <div className="info d-flex">
                        <img id="img-icon-product" className="icon" src={iconHome} alt="" />
                        <div id="lbl-name-product" className="name">
                      
                            {this.props.orderDetail.productName}
                        </div>
                    </div>
                    <div className="act">
                        { this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ACTIVE ? 
                            <button id="btn-schedule-product" className="pointer btn-schedule" onClick={onClickSchedule} >AGENDAR PEDIDO</button>   
                        // : this.state.isDisabled ?
                        //     <button id="btn-schedule-product" className="btn-schedule opacity" disabled >AGENDAR PEDIDO</button>   
                        :
                            null
                        }
                    </div>

                </div>
                


            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        //provision: provisionSelectors.getOrderById(state.provision.provisionIdSelected, state.provision.provisionData)

        orderDetail: state.security.orderDetail,
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Product);