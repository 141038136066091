import axios from 'axios';

import { CONSTANTS } from '../../utils/Constants';
import { ENDPOINTS } from '../../utils/Endpoints';
import { REQUESTS } from '../../utils/Requests';

export const SET_FAULT_TIMER = 'SET_FAULT_TIMER';
export const SET_FAULT_RESET = 'SET_FAULT_RESET';
export const SET_FAULT_PHONE = 'SET_FAULT_PHONE';

export const faultReset = () => async (dispatch, getState) => {
    dispatch({ type: SET_FAULT_RESET });
}

export const setUpdateShowLocation = () => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.FAULT()}/updateShowLocation`;

    let request = {
        
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SHOW_LOCATION
        },
        body: {
            faultId: getState().security.orderDetail.idFault,
            /* genericos request */
            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.orderDetail.xaRequest,
            bucket: getState().security.orderDetail.workZone,
            activityType: getState().security.orderDetail.activityType,
        }
    }

    console.log("=========================== request updateShowLocation FAULT ==============");
    console.log(request);

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.FAULT()
    }).then(response => {
        console.log("=========================== updateShowLocation FAULT ==============");
        console.log(response);

        return { status: 'OK'}

    }).catch(error => {
        console.log("=========================== ERROR updateShowLocation FAULT ==============");
        console.log(error);

        return { status: 'ERROR' }

    });
}

export const sendFaultToken = (idFault) => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.FAULT()}/v1/token?code=${idFault}`;

    return axios.post(API_URL, null,{
        headers: REQUESTS.HEADERS.FAULT()
    }).then(response => {
        console.log(response);

        if(response.data != null && response.data != ""){
            let timerParam = CONSTANTS.PARAM.DEFAULT_TIMER;
            if( response.data.body.sendCount != null ){
                timerParam = response.data.body.sendCount;
            }
            dispatch({ type: SET_FAULT_TIMER, payload: timerParam });
            dispatch({ type: SET_FAULT_PHONE, payload: response.data.body.phone });
        }

        return { status: 'OK'}

    }).catch(error => {
        console.log(error);

        let codigoHTTP = error.response ? error.response.status : 400;  
        
        if(codigoHTTP === 401){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_TOKEN }
        }else if(codigoHTTP === 403){
            return { status: 'NO_VALID', message: CONSTANTS.MESSAGE.SECURITY.MAX_ATTEMPTS }
        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }
    });

}