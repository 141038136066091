import React, { Component } from 'react';
import { connect } from 'react-redux';
//import LogoMovistar from '../../resources/svg/header/logoMovistar.svg';
import LogoMovistarMob from '../../resources/svg/header/logoMovistarMob.svg';
//import iconUser from '../../resources/svg/header/icon-user.svg';
import iconMenu from '../../resources/svg/header/iconMenu.svg';


import { isMobile } from "react-device-detect";

import MenuBurger from '../common/elements/MenuBurger';
import './Header.scss';

class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isMenu: false
        }
    }
    openMenu = () => {
        //
        console.log("prop header",this.props)
        this.setState({ isMenu: !this.state.isMenu });
        // const existeOnHeader = this.props.filter(e => e=="onHeaderClick")
        // console.log("exite header",existeOnHeader)
        if(this.props.hasOwnProperty("onHeaderClick")){
            console.log("yes have")
            this.props.onHeaderClick() 
        } 

        //this.setState({ isMenu: false })
    }
    
    capitalizeUserName = () => {
        let fullName = '';
        let name = '';
        if(this.props.orderDetail.customer.name != null){
            fullName = this.props.orderDetail.customer.name.trim().split(' ')[0];
            name = fullName.charAt(0).toUpperCase() + fullName.slice(1).toLowerCase();
        }
        return name;
    }
    
    render() {
        return (
            <div id="header" style={this.props.validHeader === 1 && isMobile?{visibility:'hidden'}:{visibility:'visible'}}>
                <MenuBurger isMenu={this.state.isMenu} openMenu={this.openMenu}/>
                <div className="container-header">
                    <div className="box-header d-flex justify-content-between align-items-center">
                        <div>
                            {/* <img id="img-logo-header" className="icon-web" src={LogoMovistar} alt="" /> */}
                            <img id="img-logo-header" className="icon-mob" src={LogoMovistarMob} alt=""/>
                        </div>
                        <div className="d-flex align-items-center">
                            {/* <div>
                                <span id="lbl-hello-header" className="text">Hola,</span> <span id="lbl-name-header" className="name">{this.capitalizeUserName()}</span> 
                            </div> */}
                            
                            
                            <img id="img-menu-header" className="pointer" onClick={this.openMenu} src={iconMenu} alt="" />

                            
                        </div>
                    </div>

                </div>


            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        validHeader: state.security.userWebView,
        orderDetail: state.security.orderDetail,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);