export default class Utils{


    static validarCampo(name, value, opts){
        switch(name){
            case 'nombreEmpresa':
            case 'direccion':
            case 'marca':
            case 'modelo':
                return Utils.validarAlfanumerico(value);
            case 'dscSiniestro':
                return Utils.validarComentarios(value);
            case 'nombreCompleto':
            case 'tipo':
                return Utils.validarSoloTexto(value);
            case 'telefono':
                return Utils.validarTelefono(value);
            case 'cuenta':
                return Utils.validarNumero(value);
            case 'correo':
                return Utils.validarEmail(value);
            case 'cantidad':
                return Utils.validarNumeroPositivos(value);
            case 'costo':
                return Utils.validarNumeroDecimal(value);
            case 'ideDireccion':
            case 'ideSeguro':
            case 'ideCobertura':
            case 'ideTipoActivo':
            case 'rdoCuenta':
            case 'ideBanco':
            case 'rdoSiniestro':
            case 'v_fecha':
                return Utils.validarNull(value);
            default:
                return true;
        }
    }

    static validarNull(value){
        if(value === undefined || value === null || value.length === 0){
            return false;
        }
        return true;
    }

    static validarNumero(value){
        let regExp = /^[0-9]+$/

        return  Utils.validarNull(value) ? regExp.test(value) : false
    }

    static validarAlfanumerico(value){
        let regExp = /^[A-Za-z0-9]+$/;
        return  Utils.validarNull(value) ? regExp.test(value) : false
    }

    static validarTelefono(value){
        
        let regExp = /^[0-9]+$/;

        return  Utils.validarNull(value) ? (regExp.test(value) && value.length > 6 && value.length < 10) : false;

    }

    static validarUsername(name, value,minlength){
        let resp = {}
        let valnumber = Utils.validarNumero(value);
        let valrangomin = Utils.validarLongitudMin(value,minlength);

        if(valnumber){
            if(valrangomin){
                resp.validate = true;
                resp.msj = '';
            }else{
                resp.validate = false;
                resp.msj = 'El campo '+ name +' debe contener por lo menos '+ minlength +' caracteres';
            }
        }else{
            resp.validate = false;
            resp.msj = 'El campo '+ name +' solo permite números';
        }
        
        return resp;
    }

    static validarPass(name, value,minlength){
        let resp = {}
        let valrangomin = Utils.validarLongitudMin(value,minlength);

        if(valrangomin){
            resp.validate = true;
            resp.msj = '';
        }else{
            resp.validate = false;
            resp.msj = 'El campo '+ name +' debe contener por lo menos '+ minlength +' caracteres';
        }

        
        return resp;
    }
    
    static validarLongitud(value,minlength,maxlength){
        if(value === undefined || value === null || value.length === 0){
            return false;
        }else{
            if(value.length >= minlength && value.length <= maxlength){
                return true;
            }else{
                return false;
            }
        }        
    }

    static validarLongitudMin(value,minlength){
        if(value === undefined || value === null || value.length === 0){
            return false;
        }else{
            if(value.length >= minlength){
                return true;
            }else{
                return false;
            }
        }        
    }

    static validarLongitudMax(value,maxlength){
        if(value === undefined || value === null || value.length === 0){
            return false;
        }else{
            if(value.length <= maxlength){
                return true;
            }else{
                return false;
            }
        }        
    }

    static validarComentarios(value){
        let regExp = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/ 
        return  Utils.validarNull(value) ? !regExp.test(value) : false
    }

    static validarNumeroPositivos(value){
        let regExp = /^[0-9]*$/;

        return  Utils.validarNull(value) ? regExp.test(value) && value > 0 : false
    }

    static validarNumeroDecimal(value){
        let regExp = /^[0-9]*(.[0-9]+)?$/

        return  Utils.validarNull(value) ? regExp.test(value) && value > 0 : false
    }

    static validarEmail(value){
        let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/

        return  Utils.validarNull(value) ? regExp.test(value) : false
    }

    static validarSoloTexto(value){
        let regExp = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/i

        return Utils.validarNull(value) ? regExp.test(value) : false
    }

    static validarAlfanumerico(value){
        let regExp = /^[0-9A-Za-záÁéÉíÍóÓúÚ&-\s]*$/i

        return Utils.validarNull(value) ? regExp.test(value) : false
    }
    
    static validarRUC(numeroDocumento,idpTipoDocumento){
        switch(idpTipoDocumento){
            case 3:
                if(numeroDocumento.substring(0,2) == "10")
                   return true;
                else
                   return false;
            default:
                return true;          
        }
    }

    static validarDocumento(numeroDocumento, idpTipoDocumento){

        switch(idpTipoDocumento){
            case 1: //DNI
            if(Utils.validarNumero(numeroDocumento) && numeroDocumento.length <= 8){
                return true
            } else {
                return false
            }
            case 2: //CE máximo 12
                if(numeroDocumento.length > 12){
                    return true
                } else {
                    return false
                }
            case 3: //RUC
            if(Utils.validarNumero(numeroDocumento) && numeroDocumento.length === 11){
                return true
            } else {
                return false
            }
            case 4: //PAS máximo 12
                if(numeroDocumento.length === 12){
                    return true
                } else {
                    return false
                }
            case 5: //PTP
                if(Utils.validarNumero(numeroDocumento) && numeroDocumento.length === 9){
                    return true
                } else {
                    return false
                }
            default:
                return true
        }

    }

    static validateContinue (numeroDocumento, idpTipoDocumento){

        switch(idpTipoDocumento){
            case 1: //DNI
            if(numeroDocumento.length === 8){
                return true
            } else {
                return false
            }
            case 2: //CE máximo 12
                if(numeroDocumento.length > 8 && numeroDocumento.length <= 12){
                    return true
                } else {
                    return false
                }
            case 3: //RUC

            let validaRuc = this.validarRUC(numeroDocumento, idpTipoDocumento);

            if(numeroDocumento.length === 11 && validaRuc){
                return true
            } else {
                return false
            }
            case 4://PAS
                if(numeroDocumento.length > 0 && numeroDocumento.length <= 12){
                    return true
                } else {
                    return false
                }
            case 5: //PTP
                if(numeroDocumento.length === 9){
                    return true
                } else {
                    return false
                }
            default:
                return true;
        }
    }

    static validarFormState(state, tipo){
        var result = true;
        var arrayKeys = Object.keys(state).filter((key)=>{ return (key.indexOf('v_',0) !== -1)})

        arrayKeys.forEach(key => {
            if( "formulario" === tipo){
                result = result && state[key];
            }else if( "filtro" === tipo){
                result = result || state[key];            
            }else {
                result = result && state[key];
            }

        });
        return result
    }

    static convertirMayusculas(value){
        let upperValue = value.toUpperCase();
        return upperValue;
    }

    static convertirMinusculas(value){
        let lowerValue = value.toLowerCase();
        return lowerValue;
    }

    static soloNumeros(event){
        let { value } = event.target
        let regExp = /^[0-9]+$/

        return regExp.test(value)
    }

}