import React, { Component } from 'react';
import Header from '../../header/Header';
import { connect } from 'react-redux';

import './ConfirmationCancel.scss';
import iconConfirmation from '../../../resources/svg/cancel/iconConfirmation.svg';
import Button from '@material-ui/core/Button';
import { CONSTANTS } from '../../../utils/Constants';
import Footer from '../../common/elements/Footer';
import { isMobile } from "react-device-detect";

import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import * as commonSelectors from '../../../store/selectors/common.selectors';


class ConfirmationCancel extends Component {

    constructor(props) {
        super(props)

        this.state = {
            description : CONSTANTS.MESSAGE.CONFIRMATION_CANCEL.MOBILE
        } 
      

    }

    onAccept = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'cancelar pedido',
            eventAction: 'click',
            eventLabel:  'aceptar',
            valorElemento: this.props.orderDetail.activeStatus,
            idPedido:  this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: this.props.schedule != null ? this.props.schedule.stpsiCode: '',
            fechaAgendamiento: this.props.schedule != null ? this.props.schedule.scheduleDate: '',
            turno: this.props.schedule != null ? this.props.schedule.scheduleRange: '',
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.orderDetail.customer.phoneNumber,
             productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
        
        if(this.props.provisionAll.length> 1){
            this.props.history.push({
                pathname: CONSTANTS.PATH.LIST
            });
        } else {
            this.props.history.push({
                pathname: CONSTANTS.PATH.HOME
            });
        }
        
    }

    render(){
        return (
            <div>
                <Header /> 
                <div id="confirmation-cancel">
                    <div className="container">
                        <div className="content-body">
                            <div className="d-flex flex-column justify-content-center align-items-center"> 
                                <img className="llamada" src={iconConfirmation} alt="" />
                                <span id="lbl-title-confirmation" className="title">Tu pedido ha sido <br/> cancelado</span>
                                {/* <p className="detalle" dangerouslySetInnerHTML={{__html: this.state.description}} /> */}
                                <Button id="btn-accept-confirmation" className="buttonAcept" variant="contained" color="primary" onClick={this.onAccept}>
                                    ACEPTAR
                                </Button>
                            </div>
                        </div>
                    </div>        
                </div>
                <Footer/>
            </div>
        )
    }


}

const mapStateToProps = state => ({
    user: state.security.userData,
    // provisionAll: state.provision.provisionData,
    // provision: provisionSelectors.getOrderById(state.provision.provisionIdSelected, state.provision.provisionData),
    provisionAll: state.security.orderList, 
    orderDetail: state.security.orderDetail, 
        
    schedule: state.schedule.scheduleData,
})

const mapDispatchToProps = dispatch => ({
    
})

//export default Cancel;
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationCancel);

