import React from 'react'
import './SimpleMessage.scss'
import { CONSTANTS } from '../../../utils/Constants';
// eslint-disable-next-line

export default function SimpleMessage({activityType}) {
    return (
        
        <div class = "text-message">
            {activityType == CONSTANTS.PRODUCT.PROVISION_TYPE ?
                <p>No logramos contactarte, por favor, confirma la atención de tu pedido</p>                
                :
                <p>No logramos contactarte, por favor, confirma la atención para la revisión de tu avería</p>
            }
            
        </div>
    )
}


