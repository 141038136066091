import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Confirmation.scss';
import { CONSTANTS } from '../../utils/Constants';
import { isMobile } from "react-device-detect";

import moment from 'moment';
import Button from '@material-ui/core/Button';

import * as commonSelectors from '../../store/selectors/common.selectors';
import * as scheduleActions from '../../store/actions/schedule.actions';
import * as provisionSelectors from '../../store/selectors/provision.selectors';

import Loading from '../common/elements/Loading';
import Header from '../header/Header';
import Footer from '../common/elements/Footer';
import Alert from '../modals/Alert';

import iconCheck from '../../resources/svg/agendamientoConf/iconCheck.svg';
import iconCalendar from '../../resources/svg/agendamientoConf/iconCalendar.svg';
import iconHour from '../../resources/svg/agendamientoConf/iconHour.svg';

class Confirmation extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showLoading: false,
            openAlert: false,
            errorMsj: '',
            scheduleType: '0' //0: Agendado, 1: Reagendado
        }
    }

    componentDidMount(){
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'agendar pedido resumen',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });
        this.setState({
            scheduleType: this.props.history.location.state !=null ? this.props.history.location.state.scheduleType : '0'
        })
    }

    textFullMonth = () => {
        let scheduleDate = this.props.schedule.scheduleDate.split('-');
        let getNameMonth = commonSelectors.getMonthName(scheduleDate[1]);
        return `${getNameMonth.toLowerCase()}`;
    }

    textFullDay = () => {
        let nameDay = moment(this.props.schedule.scheduleDate,'YYYY-MM-DD').format('dddd');
        let scheduleDate = this.props.schedule.scheduleDate.split('-');
        return `${nameDay.charAt(0).toUpperCase() + nameDay.slice(1) +" "+ scheduleDate[2]+' '}`;
    }

    textFullTurn = () => {
        let texto = 'Turno ';
        let opcion = ''
        if( this.props.schedule.scheduleRange === 'AM' ){
            texto = texto + 'mañana';
            opcion = opcion + 'de 9 am a 1 pm';
        }else{
            texto = texto + 'tarde';
            opcion = opcion + 'de 1 pm a 6 pm';
        }

        return `${texto}: ${opcion}`;
    }

    onSelectOther = () => {
        this.setState({ showLoading: true })
        this.props.getScheduleAvailability().then(resp => {
            this.setState({ showLoading: false })

            if(resp.status==="ERROR"){
                this.setState({
                    errorMsj: resp.message,
                    openAlert : true
                })
            }else{
                this.props.history.push({
                    pathname: CONSTANTS.PATH.SCHEDULE
                });
            }

        }
        )
    }

    onContinue = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'agendar pedido resumen',
            eventAction: 'click',
            idPedido: this.props.orderDetail.xaRequest,
            eventLabel: 'confirmar',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            fechaAgendamiento: moment(this.props.schedule.scheduleDate).format('DD/MM/YYYY'),
            nonInteraction: '1',
            
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : '' 
        });
        
        this.props.history.push({
            pathname: CONSTANTS.PATH.HOME
        });
    }

    onCloseAlert = () => {
        this.setState({
            openAlert: false
        })
    }
    onTabClick = () => {
        if(!commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }

    render() {

        return (
            <div>
                <Loading showLoading={this.state.showLoading} />
                <Header onHeaderClick = {this.onTabClick}/>
                <div id="confirmation">
                    <div className="container">
                        <div className={this.props.validHeader === 1 && isMobile ? "content-body-wo-header":"content-body"}>
                        
                        <div className="boxConfirm d-flex flex-column">
                            <img className="icon" src={iconCheck} alt="" />
                            <p id="lbl-title-confirmschedule" className="title">¡Perfecto!</p>
                            <p id="lbl-subtitle-confirmschedule" className="detail">Hemos {this.state.scheduleType==='0'?'programado':'reprogramado'} la instalación de tu pedido para el día:</p>

                            <div className="boxInstalation">
                                <div className="subTitle">
                                    <p id="lbl-subtitledet-confirmschedule" className="subTitleDetail">FECHA DE INSTALACIÓN</p>
                                </div>
       
                                <div className="boxDate d-flex row"> 
                                    <div className="imgDate">
                                        <img src={iconCalendar} alt="" />
                                    </div>
                                    <p id="lbl-datedetail-confirmschedule" className="dateDetail"><span>{this.textFullDay()}</span>de {this.textFullMonth()}</p>
                                </div>
                                <div className="boxTime d-flex row">
                                    <img className="timeImagen" src={iconHour} alt="" />
                                    <p id="lbl-timedetail-confirmschedule" className="timeDetail">{this.textFullTurn()}</p>
                                </div>
                            </div>
                            <div className="boxButton">
                                {/* <Button className="buttonOtherDay" variant="contained" color="primary" onClick={this.onSelectOther}>
                                    ELEGIR OTRO DÍA
                                </Button> */}
                                <Button id="btn-accept-confirmschedule" variant="contained" color="primary" onClick={this.onContinue} >
                                    ACEPTAR
                                </Button>                                
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <Alert id="mdl-error-confirmschedule" openAlert={this.state.openAlert} errorMsj={this.state.errorMsj} onClose={this.onCloseAlert} onAccept={this.onCloseAlert} />
                {/* <Footer/> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
        schedule: state.schedule.scheduleData,
        validHeader: state.security.userWebView,
        //scheduleData : state.schedule.userData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getScheduleAvailability: () => dispatch(scheduleActions.getScheduleAvailability()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);