import React, { Component } from 'react';
import { connect } from 'react-redux';

import iconTecnico from '../../../resources/svg/home/iconTecnico.svg';
import iconCalendarMini from '../../../resources/svg/home/iconCalendarMini.svg';
import iconClockGrey from '../../../resources/svg/home/iconClockGrey.svg';
import iconHome from '../../../resources/svg/home/iconHome.svg';
import { CONSTANTS } from '../../../utils/Constants';

import * as commonSelectors from '../../../store/selectors/common.selectors';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import moment from 'moment';
import 'moment/locale/es';

class Technician extends Component{
 
    fullNameTecCapitalize = () =>{
        return this.props.orderDetail.woPreStart.fullName.split(' ').map(item =>(
            item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() + ' '
       ));
        // return this.props.technician.fullName.split(' ').map(item =>(
        //      item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() + ' '
        // ));
    }
    
    fullNameDateInstall = () =>{
        let scheduleDate = this.props.schedule.scheduleDate.split('-');

       let nameDay = moment(this.props.schedule.scheduleDate,'YYYY-MM-DD').format('dddd');
        
       let getNameMonth = commonSelectors.getMonthName(scheduleDate[1]);
        return `Hoy ${nameDay.charAt(0).toUpperCase() + nameDay.slice(1).toLowerCase()} ${scheduleDate[2]} de 
        ${getNameMonth.charAt(0).toUpperCase() + getNameMonth.slice(1).toLowerCase()} del ${scheduleDate[0]}
        `;
        
    }
    typeDocument = () => {
        let countDoc = this.props.orderDetail.woPreStart.documentNumber.length;
        return countDoc === 8 ? 'DNI': 'N° de Documento';
        // let countDoc = this.props.technician.documentNumber.length;
        // return countDoc === 8 ? 'DNI': 'N° de Documento';
    }

    getTimeValue = () => {
        return this.props.schedule.scheduleTimeRange.replace(/:00/gi, '');
    }

    getTimeSchedule = () => {
        let turn = '';
        //if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA){ 
        console.log("entr cel")
        turn = `Hoy en el turno ${this.props.schedule.scheduleRange.toLowerCase() === 'am' ? 
        'mañana' : 'tarde'} de `

        return turn;
    }

    detalleDeAveria = () => {
        let detalle_arr = this.props.orderDetail.productName.split(' ') ;
        const res = detalle_arr.reduce((acc, val) => val.toLowerCase() == 'averia'  ? acc += '': acc+=val+" ", '')
        return res;
    }

    render(){
        return (
            <div className="box-border-blue">
            <div className="box-technician">
                <div id="lbl-head-technical" className="text-head">
                    TÉCNICO ASIGNADO
                </div>
                <div className="wrp-items">
                    <div className={`info-tec ${this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE && 'wo-line'}`}>
                        <div className="icon">
                            <img id="img-icon-technical" className="img-tech" src={iconTecnico} alt="" />
                        </div>
                        <div className="text">
                            <p id="lbl-fullname-technical" className="full-name">{this.fullNameTecCapitalize()}</p>
                            
                            <div className="doc"> <span  id="lbl-document-technical" className="doc-txt1">{ this.typeDocument()}:</span> <span id="lbl-documentnumber-technical" className="doc-txt2">{this.props.orderDetail.woPreStart.documentNumber}</span></div>
                        </div>
                    </div>
                    {
                        this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE ? 
                            <div/>:
                        <div className="date-install">
                            {
                            this.props.schedule ?
                                <div>
                                    <p id="lbl-title-technical" className="title">Fecha de visita técnica:</p>
                                    <div className="wrp1 d-flex">
                                        <img id="img-calendar-technical" src={iconClockGrey} alt="" />
                                        <p id="lbl-date-technical" className="date-txt1">{this.getTimeSchedule()}<span>{this.getTimeValue()}</span></p>
                                    </div>
                                </div>
                            :
                                <div>
                                    <p id="lbl-title-technical" className="title">Avería registrada:</p>
                                    <div className="wrp1 d-flex">
                                        <img id="img-calendar-technical" src={iconHome} alt="" />
                                        <p id="lbl-date-technical" className="date-txt1">{this.detalleDeAveria()}</p>
                                    </div>
                                </div>
                            }
                            {(() => {
                                if(this.props.orderDetail.xaRequest != null){
                                    return  <div>
                                                <p id="lbl-time-technical" className="date-txt2">{"Código: " + this.props.orderDetail.xaRequest}</p>
                                            </div>
                                }else {
                                    return null;
                                }
                                })()
                            }   

                        </div>
                    }
                </div>
                
            </div>

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail, //provisionSelectors.getOrderById(state.security.ordersIdSelected, state.security.ordersData), 
        schedule: state.schedule.scheduleData,
        technician: state.schedule.scheduleTechnician
    }
}


const mapDispatchToProps = dispatch => {
    return {
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Technician);