import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { CONSTANTS } from '../../utils/Constants';

import './Maintenance.scss';
import logo from '../../resources/svg/mantenimiento/iconQuedateEnCasa.svg';
import Loading from '../common/elements/Loading'; 

import * as userActions from '../../store/actions/security.actions';

class Maintenance extends Component {

    constructor(props){
        super(props);

        this.state = {
            showLoading: false
        }

    }
    componentDidMount () {
        this.setState({ showLoading: true });
        this.props.getApplicationParam(CONSTANTS.PARAM.PARAM_MAINTANCE).then(resp => {
            this.setState({ showLoading: false });
            if (resp.status === 'OK') {
                this.props.history.push({
                   pathname: CONSTANTS.PATH.MAINTENANCE
                });
            } else if (resp.status === 'NO_PARAM') {
                this.props.history.push({
                   pathname: CONSTANTS.PATH.LOGIN
                });
            } else if (resp.status === 'ERROR') {
                this.props.history.push({
                   pathname: CONSTANTS.PATH.MAINTENANCE
                });
            }
        });
    }

    render() {

        return (
            <section id="mantenimiento">
                <Loading id="loading-maintenance" showLoading={this.state.showLoading} />
                <div className="container">
                    <div className={`boxGroup ${isMobile ? 'd-flex flex-column' : 'd-flex flex-row'}`}>
                        <img className="logo align-self-center" src={logo} />
                        <div className="boxTexto">
                        <p id="lbl-title-maintenance" className="titleSingle">#QuédateEnCasa</p>
                            <p id="lbl-subtitle-maintenance" className="descSingle">Hola, porque cuidamos tu salud y la de nuestros colaboradores; por prevención, hemos 
                            suspendido la venta de nuestros servicios.</p>
                            <p id="lbl-subtitle2-maintenance" className="descSingle">Retomaremos nuestra atención regular al culminar el estado de emergencia.</p>
                            <p class="mant-footer">Gracias por tu comprensión</p>
                        </div>
                    </div> 
                </div>
            </section>

        )
    
    }
    
}

const mapDispatchToProps = dispatch => ({
    getApplicationParam: (request) => dispatch(userActions.getApplicationParam(request))
})

export default connect(null, mapDispatchToProps)(Maintenance);