/*******************************************************************
 *
 AMBIENTES DE DESPLIEGUE
 ***********************
 PRUEBA: 'TEST'
 DESARROLLO: 'DEV'
 PRODUCCIÓN: 'PROD'
 PRODUCCIÓN UK: 'PROD_UK'
 *
 ********************************************************************/

let environment = 'PROD';
// let environment = 'TEST';

export const ENDPOINTS = {
  BASE: {
    SECURITY: () => {
      if (environment === 'DEV') {
        return '';
      } else if (environment === 'TEST') {
        return 'https://wa-trazabilidad-mssecurity-cert.azurewebsites.net';
      } else if (environment === 'PROD') {
        return 'https://wa-trazabilidad-mssecurity-prod.azurewebsites.net';
      }
    },

    PROVISION: () => {
      if (environment === 'DEV') {
        return '';
      } else if (environment === 'TEST') {
        return 'https://wa-trazabilidad-msprovision-cert.azurewebsites.net';
      } else if (environment === 'PROD') {
        return 'https://wa-trazabilidad-msprovision-prod.azurewebsites.net';
      }
    },

    FAULT: () => {
      if (environment === 'DEV') {
        return '';
      } else if (environment === 'TEST') {
        return 'https://wa-trazabilidad-msfaults-cert.azurewebsites.net/fault';
      } else if (environment === 'PROD') {
        return 'https://wa-trazabilidad-msfaults-prod.azurewebsites.net/fault';
      }
    },

    SCHEDULE: () => {
      if (environment === 'DEV') {
        return '';
      } else if (environment === 'TEST') {
        return 'https://wa-trazabilidad-msappointment-cert.azurewebsites.net';
      } else if (environment === 'PROD') {
        return 'https://wa-trazabilidad-msappointment-prod.azurewebsites.net';
      }
    },

    SECURITY_SEC: () => {
      if (environment === 'PROD') {
        return 'https://apimngr-genesis-prod.azure-api.net/api-ne-customer-accessmanager-oc/v1';
      } else {
        //return 'https://apimngr-genesis-cert.azure-api.net/api-ne-customer-accessmanager-oc/v1';
        return 'https://aks-trazabilidad-ingress-dev.eastus2.cloudapp.azure.com/accessmanager/v1';
      }
    },
    TRACEABILITY: () => {
      if (environment === 'PROD') {
        return 'https://apimngr-genesis-prod.azure-api.net/api-ne-customer-deliverytraceability-oc/v1';
      } else {
        //return 'https://apimngr-genesis-cert.azure-api.net/api-ne-customer-deliverytraceability-oc/v1';
        return 'https://aks-trazabilidad-ingress-dev.eastus2.cloudapp.azure.com/deliverytraceability/v1';
      }
    },
  },
};
