import axios from 'axios';

import { CONSTANTS } from '../../utils/Constants';
import { ENDPOINTS } from '../../utils/Endpoints';
import { REQUESTS } from '../../utils/Requests';

export const SET_PROVISION_DATA = 'SET_PROVISION_DATA';
export const SET_PROVISION_RESET = 'SET_PROVISION_RESET';
export const SET_PROVISION_ID_SELECTED = 'SET_PROVISION_ID_SELECTED';

export const SET_PROVISION_TIMER = 'SET_PROVISION_TIMER';
export const SET_PROVISION_PHONE = 'SET_PROVISION_PHONE';

/**
|--------------------------------------------------
| Actions
|---*/

export const provisionReset = () => async (dispatch, getState) => {
    dispatch({ type: SET_PROVISION_RESET });
}

export const setProvisionIdSelected = (orderId) => async (dispatch, getState) => {
    dispatch({ type: SET_PROVISION_ID_SELECTED, payload: orderId });
}

export const getProvision = () => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/aftersales/services-contracted-by-customer`;

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_PROVISION
        },
        body: {

            /* genericos request */
            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: '',
            bucket: ''
        }
    }
    console.log("====================reuqest getOrders==========");
    console.log(request);

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log("==================get provision ===================");
        console.log(response);
        dispatch({ type: SET_PROVISION_DATA, payload: response.data.body });
        if (response.data.body.length === 1) {
            dispatch({ type: SET_PROVISION_ID_SELECTED, payload: response.data.body[0].idProvision });
        }
        return { status: 'OK', data: response.data.body }

    }).catch(error => {
        console.log('getOrder error: ' + error);
        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }

        } else if (codigoHTTP >= 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }

        }

    });
}

export const provisionUpdate = (data) => async (dispatch, getState) => {
    let order = getState().provision.provisionData.filter(item => (item.idProvision != getState().provision.provisionIdSelected));
    console.log(order);
    order.push(data);
    console.log(order);
    dispatch({ type: SET_PROVISION_DATA, payload: order });
}

export const setProvisionValidate = () => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/setProvisionValidated`;

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().user.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SET_PROVISION_VALIDATED
        },
        body: {
            provisionId: getState().provision.provisionData[0].idProvision,

            /* genericos request */
            documentNumber: getState().user.userData.documentNumber,
            documentType: getState().user.userData.documentType,
            orderCode: getState().provision.provisionData[0].xaRequest,
            bucket: ''
        }
    }

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log("===================response===============");
        if (response.data.body !== null) {
            dispatch({ type: SET_PROVISION_DATA, payload: response.data.body });
            return { status: 'OK' }
        }
        return { status: 'ERROR', message: CONSTANTS.MENSAJES.SERVICIO.ERROR_SERVICIO }

    }).catch(error => {
        console.log('setProvisionValidated error: ' + error);
        return { status: 'ERROR', message: CONSTANTS.MENSAJES.SERVICIO.ERROR_SERVICIO }

    });


}

export const setProvisionUpdateAddress = () => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/requestAddressUpdate`;

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_UPDATE_ADDRESS
        },
        body: {
            provisionId: getState().security.orderDetail.idProvision,
            
            /* genericos request */
            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.orderDetail.xaRequest,
            bucket: '',
            activityType: getState().security.orderType,
        }
    }
    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log("=========================== requestAddressUpdate ==============");
        console.log(response);

        if (response.data.body !== null) {
            console.log(response.data.body);
            
            return { status: 'OK', data: response.data.body[0]}
        }
        return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

    }).catch(error => {
        console.log("=========================== requestAddressUpdate ==============");
        console.log(error);
        return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

    });

}

export const setProvisionCancellation = (cause, detail) => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/orderCancellation`;

    let request = {
        
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_FAULT_CANCELLATION
        },
        body: {
            provisionId: getState().security.ordersData.filter(item => (item.idProvision === getState().security.ordersIdSelected.orderId))[0].idProvision,
            cause: cause,
            detail: detail,

            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.ordersData.filter(item =>(item.idProvision === getState().security.ordersIdSelected.orderId))[0].xaRequest,
            bucket: '',
            activityType: getState().security.ordersData.filter(item => (item.idProvision === getState().security.ordersIdSelected.orderId))[0].activityType,
        }
    }

    console.log("============= request orderCancellation =============");
    console.log(request);

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log("============= orderCancellation =============");
        console.log(response);
        if (response.data.body !== null) {
            //dispatch({ type: SET_PROVISION_DATA, payload: response.data.body });
            return { status: 'OK', data: response.data.body[0] }
        }
        return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

    }).catch(error => {
        console.log("============= ERROR orderCancellation =============");
        console.log(error);
        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

        } else if (codigoHTTP == 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_400 }

        } else if (codigoHTTP == 401) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_401 }

        } else if (codigoHTTP == 404) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_404 }

        } else if (codigoHTTP == 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_409 }

        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }
        }

    });

}

export const setUpdateShowLocation = () => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/updateShowLocation`;

    let request = {
        
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SHOW_LOCATION
        },
        body: {
            idProvision: getState().security.orderDetail.idProvision,
            /* genericos request */
            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.orderDetail.xaRequest,
            bucket: getState().security.orderDetail.workZone,
            activityType: getState().security.orderDetail.activityType,
        }
    }

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log("=========================== updateShowLocation ==============");
        console.log(response);

        if (response.data.body !== null) {

            return { status: 'OK', data: response.data.body[0]}
        }
        return { status: 'ERROR' }

    }).catch(error => {
        console.log("=========================== ERROR updateShowLocation ==============");
        console.log(error);
        return { status: 'ERROR' }

    });

}

export const sendProvisionToken = (idProvision) => async(dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/v1/token?code=${idProvision}`;

    return axios.post(API_URL, null, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log(response);

        if(response.data != null && response.data != ""){
            let timerParam = CONSTANTS.PARAM.DEFAULT_TIMER;
            if( response.data.body.sendCount != null ){
                timerParam = response.data.body.sendCount;
            }    
            dispatch({ type: SET_PROVISION_TIMER, payload: timerParam});
            dispatch({ type: SET_PROVISION_PHONE, payload: response.data.body.phone });
        }

        return { status: 'OK'}

    }).catch(error => {
        console.log(error);

        let codigoHTTP = error.response ? error.response.status : 400;  
        if(codigoHTTP === 401){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_TOKEN }
        }else if(codigoHTTP === 403){
            return { status: 'NO_VALID', message: CONSTANTS.MESSAGE.SECURITY.MAX_ATTEMPTS }
        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }

    });
}


