import axios from 'axios';
import moment from 'moment';

import { CONSTANTS } from '../../utils/Constants';
import { ENDPOINTS } from '../../utils/Endpoints';
import { REQUESTS, headerSecurity, headerSecurityAuth } from '../../utils/Requests';

// adecuacion security
import FormData from 'form-data';


// Prueba3
export const SET_SECURITY_RESET = 'SET_SECURITY_RESET';
export const SET_SECURITY_RESET_SELECTED_ORDER = 'SET_SECURITY_RESET_SELECTED_ORDER';
export const SET_SECURITY_CUSTOMER_DATA = 'SET_SECURITY_CUSTOMER_DATA';
export const SET_SECURITY_LOGIN_TOKEN = 'SET_SECURITY_LOGIN_TOKEN';
export const SET_SECURITY_DATA = 'SET_SECURITY_DATA';
export const SET_SECURITY_SOURCE = 'SET_SECURITY_SOURCE';
export const SET_SECURITY_TIMER = 'SET_SECURITY_TIMER';
export const SET_SECURITY_WEBVIEW = 'SET_SECURITY_WEBVIEW';
export const SET_SECURITY_RESCHEDULE_POLICY = 'SET_SECURITY_RESCHEDULE_POLICY';

export const SET_ORDER_TYPE = 'SET_SECURITY_ORDER_TYPE';
export const SET_ORDER_LIST = 'SET_SECURITY_ORDER_LIST';
export const SET_ORDER_TOKEN = 'SET_SECURITY_ORDER_TOKEN';
export const SET_ORDER_DETAIL = 'SET_SECURITY_ORDER_DETAIL';
export const SET_ORDER_DETAIL_RESET = 'SET_SECURITY_ORDER_DETAIL_RESET';

export const SET_SECURITY_LOGIN_TIME = 'SET_SECURITY_LOGIN_TIME';

export const SET_ORDERS_RESET = 'SET_SECURITY_ORDERS_RESET';
export const SET_ORDERS_ID_SELECTED = 'SET_SECURITY_ORDERS_ID_SELECTED';

//token
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_DOC_NUMBER = 'SET_DOC_NUMBER';
export const SET_ORDER_CODE = 'SET_ORDER_CODE';

// jwt validate user
export const SET_AUTH_JWT_LOGIN = 'SET_AUTH_JWT_LOGIN';



export const securityOrdersReset = () => async (dispatch, getState) => {
    dispatch({ type: SET_ORDERS_RESET });
}

export const securityReset = () => async (dispatch, getState) => {
    dispatch({ type: SET_SECURITY_RESET });
}

export const securityResetSelectedOrder = () => async (dispatch, getState) => {
    dispatch({ type: SET_SECURITY_RESET_SELECTED_ORDER });
}

export const securityHideHeader = (value) => async (dispatch, getState) => {
    dispatch({ type: SET_SECURITY_WEBVIEW, payload: value });
}

export const setSecurityLoginToken = (token) => async (dispatch, getState) => {
    dispatch({ type: SET_SECURITY_LOGIN_TOKEN, payload: token });
}

export const setSecurityReschedulePolicy = (value) => async (dispatch, getState) => {
    dispatch({ type: SET_SECURITY_RESCHEDULE_POLICY, payload: value });
}

export const setOrderDetail = (order) => async (dispatch, getState) => {
    dispatch({ type: SET_ORDER_DETAIL, payload: order });
}

export const setOrderDetailReset = () => async (dispatch, getState) => {
    dispatch({ type: SET_ORDER_DETAIL_RESET });
}

export const setOrderType = (activityType) => async (dispatch, getState) => {
    dispatch({ type: SET_ORDER_TYPE, payload: activityType})
}


//agregado para las modificaciones de seguridad
// En tus acciones (actions)
export const setAuthToken = (token) => (dispatch, getState) => {
    dispatch({type: SET_AUTH_TOKEN, payload: token,})
};

export const setOrdeCode = (orderCode) => (dispatch, getState) => {
    dispatch({type: SET_ORDER_CODE, payload: orderCode,})
};

export const setDocNumber = (docNumber) => (dispatch, getState) => {
    dispatch({type: SET_DOC_NUMBER, payload: docNumber,})
};

// valudate user jwt token
export const setAuthTokenValidateUser = (token) => (dispatch, getState) => {
    dispatch({type: SET_AUTH_JWT_LOGIN, payload: token})
}
  

export const securityLoginToken = (request) => async (dispatch, getState) => {

    let data = {
        header : {
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_VALIDATE_USER
        },
        body: request
    }

    return axios.post(ENDPOINTS.BASE.SECURITY() + '/validateUser', data, {
        headers: REQUESTS.HEADERS.SECURITY()
    }).then(resp => {
        console.log("================== validateUser =================== ");
        console.log(resp);

        if (resp.data.body.content != null) {
            dispatch({ type: SET_SECURITY_DATA, payload: resp.data.body.content });
            dispatch({ type: SET_SECURITY_SOURCE, payload: request.source });

            return { status: 'OK', data: resp.data.body.content };

        } else {
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_WEB }
        }

    }).catch(error => {
        console.log("validateUser error: " + error);

        let codigoHTTP = error.response ? error.response.status : 400; 
        if(codigoHTTP === 401){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_WEB }
        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR }
        }

    });

}


export const securityLoginTokenAcess = (request) => async (dispatch, getState) => {

    // replica de getorders
    const formData = new FormData();
    formData.append('documentType', request.documentType);
    formData.append('documentNumber', request.documentNumber);
    formData.append('source', request.source);

    return axios.post(ENDPOINTS.BASE.SECURITY_SEC() + '/validateuser', formData, {
        headers: headerSecurity()

    }).then((response) => {

        console.log("validateExtraUser: ");
        console.log(response)
        console.log(JSON.stringify(response));

        dispatch({ type: SET_SECURITY_DATA, payload: request });
        dispatch({ type: SET_ORDER_LIST, payload: response.data.data });
        dispatch({ type: SET_SECURITY_LOGIN_TIME, payload: moment() });
        dispatch({ type: SET_AUTH_JWT_LOGIN, payload: response.data.jwt });

        if (response.data.data.length === 1) {
            dispatch({ type: SET_ORDER_TYPE, payload: response.data.data[0].activityType})
            dispatch({ type: SET_ORDER_DETAIL, payload: response.data.data[0]});            
            // modificacion de seguridad
            dispatch({ type: SET_ORDER_CODE, payload: response.data.data[0].orderCode});
            dispatch({ type: SET_DOC_NUMBER, payload: response.data.data[0].documentNumber});            

            if(response.data.data[0].activityType.toLowerCase() == CONSTANTS.PRODUCT.PROVISION_TYPE){
                return { status: 'TOKEN', order: response.data.data[0] }
            }else{
                return { status: 'TOKEN', order: response.data.data[0] }
            }
            
        }else{
            return { status: 'OK' }
        }

    }).catch(error => {

        let codigoHTTP = error.response ? error.response.status : 400;

        if(codigoHTTP === 401 || codigoHTTP === 404){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP_LOGIN }
        }else if(codigoHTTP === 405){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_405 }
        }else if (codigoHTTP === 429){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_429 }
        }else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }

    });
}


/* export const securityLogin = (request) => async (dispatch, getState) => {

    let dataValidateUser = {
        header:{
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_VALIDATE_USER
        },
        body: request
    }

    return axios.post(ENDPOINTS.BASE.SECURITY() + '/validateUser', dataValidateUser, {
        headers: REQUESTS.HEADERS.SECURITY()
    }).then(resp => {
        let userBody = resp.data.body.content;
        
        if (userBody !== null) {
            //Envios sms
            let dataSendToken = {
                header:{
                    appName: REQUESTS.DATA.APP_NAME,
                    user: request.documentNumber,
                    operation: REQUESTS.DATA.OPERATION_SEND_TOKEN
                },
                body: {
                    phoneNumber: userBody.phoneNumber,
                    carrier: userBody.carrier,
                    token: '',
                    customerName: userBody.name,
                    productName: userBody.productName,
                    customerEmail: userBody.mail,
                    customerIDType: userBody.documentType,
                    customerIDNumber: userBody.documentNumber,
                    requestType: userBody.hasProvisions === true ? 'provision' : 'fault',
                }
            }

            return axios.post(ENDPOINTS.BASE.SECURITY() + '/sendToken', dataSendToken, {
                headers: REQUESTS.HEADERS.SECURITY()
            }).then(response => {
                dispatch({ type: SET_SECURITY_DATA, payload: userBody });
                dispatch({ type: SET_SECURITY_SOURCE, payload: request.source });
                dispatch({ type: SET_SECURITY_TIMER, payload: response.data.body.content.value})
                return { status: 'OK' }
                
            }).catch(error => {
                console.log("sendToken error: "+JSON.stringify(error))

                let codigoHTTP = error.response ? error.response.status : 400;  
                if(codigoHTTP === 401){
                    return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP }
                }
                else if(codigoHTTP === 403){
                    return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.MAX_ATTEMPTS }
                }
                else{
                    return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
                }
            });

        } else {
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP }
        }

    }).catch(error => {
        console.log("validateUser error: "+JSON.stringify(error))
        let codigoHTTP = error.response ? error.response.status : 400;  
        if(codigoHTTP === 401){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP_LOGIN }
        }else if(codigoHTTP === 411){     
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_411, messageTitle:'¡Se ha producido un error!'}
        }else if(codigoHTTP === 405){     
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_405, messageTitle:'Regresaremos pronto' }
        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR, messageTitle:'¡Se ha producido un error!' }
        }
    });

} */

export const securityLoginAdmin = (request) => async (dispatch, getState) => {

    let data = {
        header : {
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_VALIDATE_USER
        },
        body: request
    }

    return axios.post(ENDPOINTS.BASE.SECURITY() + '/validateExtraUser', data, {
        headers: REQUESTS.HEADERS.SECURITY()
    }).then(resp => {
        console.log("validateExtraUser: ");
        console.log(resp)
        console.log(JSON.stringify(resp));
        
        if (resp.data.body.content != null) {
            dispatch({ type: SET_SECURITY_DATA, payload: resp.data.body.content });
            dispatch({ type: SET_SECURITY_SOURCE, payload: request.source });
            return { status: 'OK', data: resp.data.body.content }
        } else {
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_WEB }
        }

    }).catch(error => {
        console.log("validateExtraUser ERROR");
        console.log(error)

        let codigoHTTP = error.response ? error.response.status : 400; 
        if(codigoHTTP === 401){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_WEB }
        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR }
        }

    });
}
//desuso
/* export const userResendToken = () => async (dispatch, getState) => {

    let data = {
        header:{
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_RESEND_TOKEN
        },
        body: {
            phoneNumber: getState().security.userData.phoneNumber,
            carrier: getState().security.userData.carrier,
            token: '',
            customerName: getState().security.userData.name,
            productName: getState().security.userData.productName,
            customerEmail: getState().security.userData.mail,
            customerIDType: getState().security.userData.documentType,
            customerIDNumber: getState().security.userData.documentNumber,
            requestType: getState().security.userData.hasProvisions === true ? 'provision' : 'fault',

        }
    }

    return axios.post(ENDPOINTS.BASE.SECURITY() + '/resendToken', data, {
        headers: REQUESTS.HEADERS.SECURITY()

    }).then(response => {
        return { status: 'OK', message: CONSTANTS.MESSAGE.SECURITY.SENT_TOKEN }

    }).catch(error => {
        console.log("resendToken error: "+JSON.stringify(error));
        let codigoHTTP = error.response ? error.response.status : 400; 

        if(codigoHTTP === 403){
            return { status: 'NO_VALID', message: CONSTANTS.MESSAGE.SECURITY.MAX_ATTEMPTS }
        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }
    });

} */

export const userValidToken = (code) => async (dispatch, getState) => {

    const formData = new FormData();
    formData.append('documentType', getState().security.userData.documentType);
    formData.append('documentNumber', getState().security.documentNumber);
    formData.append('code', getState().security.orderCode);
    //TODO: debe ir traceID 
    formData.append('traceId', "DELIVERY");
    formData.append('otp', code);

    let auth = getState().security.jwtAuthUser;

    console.log("validateToken");
    
    return axios.post(ENDPOINTS.BASE.SECURITY_SEC() + '/validateotp', formData, {
        headers: headerSecurityAuth(auth)
    }).then(response => {
        //console.log(response);
        dispatch({ type: SET_AUTH_TOKEN, payload: response.data.jwt });
        return { status: 'OK', token: response.data.jwt };

    }).catch(error => {
        console.log(error);

        let codigoHTTP = error.response ? error.response.status : 400; 
        if(codigoHTTP === 401){
            //Intento inválido
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_TOKEN }

        }else if(codigoHTTP === 403){
            //Superó los intentos de token inválidos    
            return { status: 'NO_VALID', message: CONSTANTS.MESSAGE.SECURITY.MAX_ATTEMPTS } 

        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR }

        }
    });

}

export const getApplicationParam = (param) => async (dispatch, getState) => {

    let data = {
        header:{
            appName: REQUESTS.DATA.APP_NAME,
            user: 'TRAZABILIDAD_APP',
            operation: REQUESTS.DATA.OPERATION_GET_PARAMETER
        },
        body: {
            key: param
        } 
    }

    return axios.post(ENDPOINTS.BASE.SECURITY() + '/params/getParam', data, 
        {   headers: REQUESTS.HEADERS.SECURITY() }
    ).then(response => {
        console.log("============= getApplicationParam =============")
        console.log(response)

        if(response.data?.body.content.value === 'true'){        //Valida la ventana de mantenimiento
            return { status: 'OK' }
        }else if(Number(response.data?.body.content.value)){     //Valida la política de reagendamiento  
            return { status: 'OK' , data: response.data.body}  
        }else{
            return { status: 'NO_PARAM' }
        }

    }).catch(error => {
        console.log("============= ERROR getApplicationParam =============")
        console.log(error);
    })
}

export const setOrdersIdSelected = (selectedOrder) => async (dispatch, getState) => {
    dispatch({ type: SET_ORDERS_ID_SELECTED, payload: selectedOrder });
}

export const getOrders = (request) => async (dispatch, getState) => {

    let requestUser = {
        header:{
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_VALIDATE_USER
        },
        body: request
    }

    return axios.post(ENDPOINTS.BASE.SECURITY() + '/validateUser', requestUser, {
        headers: REQUESTS.HEADERS.SECURITY()

    }).then(response => {
        
        dispatch({ type: SET_SECURITY_DATA, payload: request });
        dispatch({ type: SET_ORDER_LIST, payload: response.data.body });
        dispatch({ type: SET_SECURITY_LOGIN_TIME, payload: moment() });

        if (response.data.body.length === 1) {
            dispatch({ type: SET_ORDER_TYPE, payload: response.data.body[0].activityType})
            dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body[0]});
            
            // modificacion de seguridad
            dispatch({ type: SET_ORDER_CODE, payload: response.data.body[0].orderCode});
            dispatch({ type: SET_DOC_NUMBER, payload: response.data.body[0].documentNumber});            

            

            if(response.data.body[0].activityType.toLowerCase() == CONSTANTS.PRODUCT.PROVISION_TYPE){
                return { status: 'TOKEN', order: response.data.body[0] }
            }else{
                return { status: 'TOKEN', order: response.data.body[0] }
            }
            
        }else{
            return { status: 'OK' }
        }

    }).catch(error => {
        console.log(error);
        let codigoHTTP = error.response ? error.response.status : 500;

        if(codigoHTTP === 404){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP_LOGIN }
        }else if(codigoHTTP === 405){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_405 }
        }else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }
    });

}

export const provisionUpdate = (data) => async (dispatch, getState) => {
    //let order = getState().security.ordersData.filter(item => (item.idProvision != getState().security.ordersIdSelected.orderId));
    let order = getState().security.orderList.filter(item => (item.idProvision != getState().security.orderDetail.orderId));
    console.log(order);
    order.push(data);
    console.log(order);
    dispatch({ type: SET_ORDER_LIST, payload: order });
}

export const getFaultDetail = (request) => async (dispatch, getState) => {

    let requestFault = {
        header:{
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_FAULT
        },
        body: request
    }

    const headerDefault = REQUESTS.HEADERS.FAULT();
    const headerToken   = { 'token': getState().security.authToken };    
    const headerTotal   = { ...headerDefault, ...headerToken };

    return axios.post(ENDPOINTS.BASE.FAULT() + '/getFaultsDetail', requestFault, {
        headers: headerTotal

    }).then(response => {
     
        dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body });
        return { status: 'OK' }
        

    }).catch(error => {

        let codigoHTTP = error.response ? error.response.status : 500;

        if(codigoHTTP === 404){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP_LOGIN }
        }else if(codigoHTTP === 405){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_405 }
        } else if (codigoHTTP === 401) {
            return { status: 'ERROR_SECURITY', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }
    });

}

/* export const getProvisionDetailDesuso = (request) => async (dispatch, getState) => {

    let requestProvision = {
        header:{
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_UPDATE_STATUS,
            messageId: '',
            timestamp: ''
        },
        body: request
    }

    console.log("getProvisionDetailById");

    return axios.post(ENDPOINTS.BASE.PROVISION() + '/provision/getProvisionDetailById', requestProvision, {
        headers: REQUESTS.HEADERS.PROVISION()

    }).then(response => {
        console.log(response);
    
        dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body});
        return { status: 'OK' }

    }).catch(error => {
        console.log(error);

        let codigoHTTP = error.response ? error.response.status : 500;

        return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
    });

} */

export const getProvisionDetail = (request) => async (dispatch, getState) => {

    let requestProvision = {
        header:{
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_UPDATE_STATUS,
            messageId: '',
            timestamp: ''
        },
        body: request
    }

    const headerDefault = REQUESTS.HEADERS.PROVISION();
    const headerToken   = { 'token': getState().security.authToken };
    const headerTotal   = { ...headerDefault, ...headerToken };

    return axios.post(ENDPOINTS.BASE.PROVISION() + '/provision/getProvisionDetail', requestProvision, {
        headers: headerTotal

    }).then(response => {
    
        dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body});
        return { status: 'OK' }

    }).catch(error => {
        console.log(error);

        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP === 401) {
            return { status: 'ERROR_SECURITY', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }
    });

}

export const sendFaultToken = (body) => async (dispatch, getState) => {

    //let API_URL = `${ENDPOINTS.BASE.FAULT()}/v1/token?code=${idFault}`;

    let {traceId, orderCode, jwt} = body;
    let sendBody = {
        traceId: traceId,
        orderCode: orderCode
    };

    return axios.post(ENDPOINTS.BASE.SECURITY_SEC() +'/sendotp', sendBody, {
        headers: headerSecurityAuth(jwt)
    }).then(response => {
        console.log(response);

        dispatch({ type: SET_ORDER_TOKEN, payload: response.data.data });
        
        return { status: 'OK'}

    }).catch(error => {
        console.log(error);

        let codigoHTTP = error.response ? error.response.status : 400;  
        
        if(codigoHTTP === 401){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP_LOGIN }
        }
        else if(codigoHTTP === 403){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.MAX_ATTEMPTS }
        }
        else if(codigoHTTP === 406){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_411 }
        }
        else if (codigoHTTP === 429){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_429 }
        }
        else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }
    });

}

export const sendProvisionToken = (body) => async(dispatch, getState) => {

    //let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/v1/token?code=${idProvision}`;
    
    let {traceId, orderCode, jwt} = body;
    let sendBody = {
        traceId: traceId,
        orderCode: orderCode
    };

    return axios.post(ENDPOINTS.BASE.SECURITY_SEC() +'/sendotp', sendBody, {
        headers: headerSecurityAuth(jwt)
    }).then(response => {

        dispatch({ type: SET_ORDER_TOKEN, payload: response.data.data });

        return { status: 'OK'}

    }).catch((error) => {

        console.log(error);
        let codigoHTTP = error.response ? error.response.status : 400;  
        if(codigoHTTP === 401){
            return { status: 'NO_DATA', message: CONSTANTS.MESSAGE.SECURITY.INVALID_DOC_APP_LOGIN }
        }
        else if(codigoHTTP === 403){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.MAX_ATTEMPTS }
        }
        else if(codigoHTTP === 406){//cambio 
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_411 }
        }
        else if (codigoHTTP === 429){
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_429 }
        }
        else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
        }

    });
}

export const setProvisionUpdateAddress = () => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/requestAddressUpdate`;

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_UPDATE_ADDRESS
        },
        body: {
            provisionId: getState().security.orderDetail.idProvision,
            
            /* genericos request */
            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.orderDetail.xaRequest,
            bucket: '',
            activityType: getState().security.orderType,
        }
    }

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log("=========================== response AddressUpdate ==============");
        console.log(response);

        if (response.data.body !== null) {
            dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body });
            return { status: 'OK'}
        }else{
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }
        }

    }).catch(error => {
        console.log("=========================== error AddressUpdate ==============");
        console.log(error);
        
        return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

    });

}

export const setProvisionCancellation = (cause, detail) => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/orderCancellation`;

    let request = {
        
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_FAULT_CANCELLATION
        },
        body: {
            provisionId: getState().security.orderDetail.idProvision,
            cause: cause,
            detail: detail,
            scheduler: getState().security.orderDetail.scheduler != null ? getState().security.orderDetail.scheduler : 'PSI',

            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.orderDetail.xaRequest,
            bucket: '',
            activityType: getState().security.orderDetail.activityType,
        }
    }

    console.log("============= request orderCancellation =============");
    console.log(request);

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(response => {
        console.log("============= orderCancellation =============");
        console.log(response);
        if (response.data.body !== null) {
            dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body });
            return { status: 'OK' }
        }
        return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

    }).catch(error => {
        console.log("============= ERROR orderCancellation =============");
        console.log(error);
        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

        } else if (codigoHTTP == 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_400 }

        } else if (codigoHTTP == 401) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_401 }

        } else if (codigoHTTP == 404) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_404 }

        } else if (codigoHTTP == 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SCHEDULE_CANCEL_ERROR_409 }

        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }
        }

    });

}

export const setContactInfoUpdateFaultOrProvision = (request) => async (dispatch, getState) => {

    const API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/setContactInfoUpdateWeb`;
    const HEADER_URL = REQUESTS.HEADERS.PROVISION();

    request.activityType = getState().security.orderType;
    request.documentNumber = getState().security.userData.documentNumber;
    request.documentType = getState().security.userData.documentType;
    request.orderCode = getState().security.orderDetail.xaRequest;
    request.bucket = '';
    request.scheduler = getState().security.orderDetail.scheduler != null ? getState().security.orderDetail.scheduler : 'PSI';

    let requestContactInfo = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SET_CONTACT_INFO_UPDATE
        },
        body: request
    }
    
    console.log("============= request setContactInfoUpdate =============");
    console.log(requestContactInfo)

    return axios.post(API_URL, requestContactInfo, {
        headers: HEADER_URL
    }).then(response => {
        console.log("============= setContactInfoUpdate =============")
        console.log(response)

        if (response.data.body != null) {
            dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body });
            return { status: 'OK' }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }
        }

    }).catch(error => {
        console.log("============= ERROR setContactInfoUpdate =============")
        console.log(error)

        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_500 }
        } else if (codigoHTTP === 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_400 }
        } else if (codigoHTTP === 401) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_401 }
        } else if (codigoHTTP === 404) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_404 }
        } else if (codigoHTTP === 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_409 }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_400 }
        }

    });
}
//p
export const setUpdateActivity = (value) => async (dispatch, getState) => {

    const API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/updateActivity`;
    const HEADER_URL = REQUESTS.HEADERS.PROVISION();

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SET_UPDATE_ACTIVITY
        },
        body:  {
            idProvision: getState().security.orderDetail.idProvision,
            indicador: value   
        }
    }
    
    console.log("============= request setContactInfoUpdate =============");
    console.log(request)

    return axios.post(API_URL, request, {
        headers: HEADER_URL
    }).then(response => {
        console.log("============= setContactInfoUpdate =============")
        console.log(response)

        if (response.data.body != null) {
            dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body });
            return { status: 'OK' }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }
        }

    }).catch(error => {
        console.log("============= ERROR setContactInfoUpdate =============")
        console.log(error)
          let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.ERROR_405 }
        } else if (codigoHTTP === 406) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CLIENT_ABSENT.INACTIVITY }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_400 }
        }


    });
}
 //fault/UpdateActivity
export const setUpdateActivityFault = (value) => async (dispatch, getState) => {

    const API_URL = `${ENDPOINTS.BASE.FAULT()}/updateActivity`;
    const HEADER_URL = REQUESTS.HEADERS.FAULT();

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SET_UPDATE_ACTIVITY
        },
        body:  {
            faultId: getState().security.orderDetail.idFault,
            indicador: value   
        }
    }
    
    console.log("============= request Fault setContactInfoUpdate =============");
    console.log(request)

    return axios.post(API_URL, request, {
        headers: HEADER_URL
    }).then(response => {
        console.log("============= setContactFaultInfoUpdate =============")
        console.log(response)

        if (response.data.body != null) {
            dispatch({ type: SET_ORDER_DETAIL, payload: response.data.body });
            return { status: 'OK' }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }
        }

    }).catch(error => {
        console.log("============= ERROR setContactInfoUpdate =============")
        console.log(error)

        return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }

    });
}

