import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import { animateScroll as scroll } from 'react-scroll';

import iconArrowNMO from '../../resources/svg/login/iconArrow.svg';
import ReturnButton from '../common/elements/ReturnButton';
import Tracking from './tracking/TrackingFault';
import Footer from '../common/elements/Footer';
import './Fault.scss';

//Components 
import Header from '../header/Header';
import Frame from '../frame/Frame';
import Technician from './technician/Technician';
import Product from './product/Product';
import Schedule  from './schedule/Schedule'
import Detail from './detail/Detail';
import Triage from './triage/Triage';
import Returned from '../home/returned/Returned'
import SimpleMessage from '../common/elements/SimpleMessage'
import AlertMessage from '../common/elements/AlertMessage'
import Loading from '../common/elements/Loading'

import * as faultActions from '../../store/actions/averia.actions';
import * as commonSelectors from '../../store/selectors/common.selectors';
import * as provisionSelectors from '../../store/selectors/provision.selectors';
import * as securityActions from '../../store/actions/security.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';

import { CONSTANTS } from '../../utils/Constants';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Alert from '../modals/Alert';

class Fault extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isFullMap:false,
            openTooltip:false,
            openAlert: false,
            errorMsj: '',

            disabledContinue:true,
            iconSelectComp: iconArrowNMO,
            valueAbsentOption:''
        }
    }

componentDidMount() {
        scroll.scrollToTop();

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'avería detalle',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

        if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART) {
            window.dataLayer.push({
                event: 'atm.event',
                eventCategory: 'avería detalle',
                eventAction: 'view',
                eventLabel: 'datos de tu técnico asignado',
                valorElemento: this.props.orderDetail.activeStatus,
                correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
                nonInteraction: '1',
                //datos genéricos
                userId: this.props.user.documentNumber,
                tipoCliente: this.props.user.documentType.toLowerCase(),
                numero: this.props.orderDetail.customer.phoneNumber,
                productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
            });

            this.setState({
                openTooltip: this.receiveDelay()
            })
        }

        //Actualizar los servicios para los estados WO_PRESTART y WO_INIT
        // if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART
        //     || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_INIT){
        //         this.apiGetOrders();
        // }
        this.apiGetOrders();
    }

    apiGetOrders = () => {

        let request = {
            documentType: this.props.user.documentType,
            documentNumber: this.props.docNumber,
            orderCode: this.props.orderCode
        };

        this.props.getFaultDetail(request).then(resp => {

            if(resp.status=='OK'){
                this.apiGetSheduleFault(); //Obtiene la agenda de la avería

            } else {
                if(resp.status == 'ERROR_SECURITY') {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LOGIN
                    });
                }
            }
        }); 

        // this.props.getOrders().then(response => {
        //     if (response.status === 'OK') {
        //         this.apiGetSheduleFault();
        //         console.log("avería actualizada");
        //     } 
        // })
        
    }

    apiGetSheduleFault = () => {

        this.props.getScheduledFault().then(resp => {

            if(resp.status === "OK"){
                console.log("avería actualizada");
            } else {
                if(resp.status == 'ERROR_SECURITY') {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LOGIN
                    });
                }
            }

        });

    }

    receiveDelay (){
        setTimeout(() => {  
            this.setState({ openTooltip: false })
        }, 4000); //4 segundos
        return true;
    }  

    goBack = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'avería detalle',
            eventAction: 'click',
            eventLabel: 'volver',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber ? this.props.orderDetail.customer.phoneNumber : '',
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.props.history.goBack();
    }

    onOpenMapView = () => {
        this.props.history.push({
            pathname: CONSTANTS.PATH.MAP
        });
    }

    onFullMap = () => {
        if(this.state.isFullMap){
            this.setState({ isFullMap: false })
        }else{
            this.setState({ isFullMap: true })
        }
        
        this.props.setUpdateShowLocationFault().then(resp => {
        });
        

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'avería detalle',
            eventAction: 'click',
            eventLabel: this.state.isFullMap ? 'reducir mapa' : 'expandir mapa',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
    }

    onHeaderClick = () => {
        if(!commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }

    onChangeAbsentOption = event => {
        this.setState({
            disabledContinue:false,
            valueAbsentOption: event.target.value
        });

        this.inputDocTypeFull();
    }

    inputDocTypeFull = () => {
        let labelType = document.querySelector('#home-fault .container .box-nmo .option .formControlDocument .MuiInputLabel-outlined.MuiInputLabel-shrink');
        labelType.style.color = "#50535A";

        let borderType = document.querySelector('#home-fault .container .box-nmo .option .formControlDocument .MuiOutlinedInput-notchedOutline');
        borderType.style.border = "1px solid #50535A";
    }

    onCloseAlert = () => {
        this.setState({
            openAlert: false
        })
    }

    onContinue = () => {

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'avería detalle',
            eventAction: 'click',
            eventLabel: 'continuar cliente ausente',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricosd
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
        this.setState({ showLoading: true })
        this.props.setUpdateActivityFault(this.state.valueAbsentOption).then(res=>{
            this.setState({showLoading: false})
            if(res.status == 'OK'){
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.ABSENT
                });
            }
            else{
                this.setState({
                    errorMsj: res.message,
                    openAlert: true
                })
            }
        })
       
    }


    render() {
        
        return (
            <div  className='contenedor-home'>
                <Loading showLoading={this.state.showLoading} />
                <Header onHeaderClick = {this.onHeaderClick} />
                { 
                (() => {
                    if(this.state.isFullMap){
                        {
                            scroll.scrollToTop();
                        }
                        return <Frame url={this.props.orderDetail.woPreStart.trackingUrl} onFullMap={this.onFullMap} isFullMap = {this.state.isFullMap}/>;
                    }else{
                        return <div id="home-fault">
                            <div className={this.props.validHeader === 1 && isMobile ? "content-body-wo-header":"content-body"}>
                                <div className="container">
                                    {this.props.orderList.length > 1 && 
                                        <div className="icon-back">
                                            <ReturnButton id="btn-return-home" onClick={this.goBack} /> 
                                        </div>}

                                    {(() => {
                                        if (this.props.orderDetail != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART) {
                                            return <Technician />
                                        }
                                        if (this.props.orderDetail != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_INIT) {
                                            return <Technician />
                                        } 
                                        if (this.props.orderDetail != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE) {
                                            return <Technician />
                                        }
                                        if (this.props.orderDetail != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA) {
                                            return <Technician />
                                        }
                                        else if (this.props.orderDetail != null && this.props.schedule == null) {
                                            return <Product onClickSchedule={this.onClickSchedule} />
                                        }
                                        else if (this.props.orderDetail != null && this.props.schedule != null) {
                                            if(this.props.schedule.scheduleStatus == CONSTANTS.STATUS.CANCEL && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ACTIVE){
                                                return <Product onClickSchedule={this.onClickSchedule} />
                                            }else{
                                                return <Schedule onReschedule={this.onClickSchedule} />
                                            }
                                           
                                        } else {
                                            return <Product />
                                        }
                                            
                                    })()}
                                    {
                                        <Tracking onOpenMapView = {this.onOpenMapView} />
                                    }

                                    {
                                    //NMO   select
                                    (() => {
                                        {
                                        return this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE 
                                            &&  <div>
                                                    <SimpleMessage/>
                                                    <div className="box-nmo">
                                                        <div className="option">
                                                            <FormControl 
                                                                variant="outlined" 
                                                                className = "formControlDocument"
                                                            >
                                                                <InputLabel>Selecciona una opción</InputLabel>
                                                                <Select 
                                                                    labelId="lbl-doctype-login"
                                                                    id="sel-doctype-login"
                                                                    className="combo-select"
                                                                    value={this.state.valueAbsentOption}
                                                                    onChange={this.onChangeAbsentOption}
                                                                    label="Selecciona una opción"
                                                                    displayEmpty 
                                                                    IconComponent={
                                                                        props => (
                                                                            <img {...props} 
                                                                                src={this.state.iconSelectComp}
                                                                                style={{padding:'6px 8px 6px 6px'}}
                                                                            />)
                                                                    }
                                                                    MenuProps={{
                                                                        getContentAnchorEl:null,
                                                                        anchorOrigin:{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        },
                                                                        transformOrigin:{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }
                                                                    }}>
                                                                    <MenuItem value={0}>Sí, confirmo la atención</MenuItem>
                                                                    <MenuItem value={1}>Deseo coordinar otra visita</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        {   this.state.disabledContinue?
                                                            <div className="continue-button">
                                                                <Button 
                                                                    id="btn-continue-home" 
                                                                    className="absent-button"
                                                                    variant="contained" 
                                                                    color="primary" 
                                                                    disabled={true} 
                                                                >
                                                                    CONTINUAR
                                                                </Button>
                                                            </div>
                                                            :
                                                            <div className="continue-button">
                                                                <Button 
                                                                    id="btn-continue-home" 
                                                                    className="absent-button-active"
                                                                    variant="contained" 
                                                                    color="primary"  
                                                                    onClick={this.onContinue}
                                                                >
                                                                    CONTINUAR
                                                                </Button>
                                                            </div>
                                                        }
                                                         <AlertMessage className="alert-msg"/>
                                                    </div>
                                                   
                                                </div>
                                        }
                                    })()
                                    } 

                                    {(() => {
                                        if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TRIAJE_MASIVA){
                                            return <Triage />
                                        }
                                        })()
                                    }
                                    
                                    {(() => {
                                        if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRESTART && this.props.orderDetail.woPreStart.availableTracking == true){
                                            return <Frame url={this.props.orderDetail.woPreStart.trackingUrl} onFullMap={this.onFullMap} openTooltip={this.state.openTooltip} /> 
                                        }
                                    })()
                                    }                           
                                </div> 
                                <div></div>
                                {  (this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_NOTDONE_TRAZA) ?
                                    <Returned onOpenPlaces = {this.onOpenPlaces} /> 
                                    :
                                    null
                                }
                                { 
                                    (() => {
                                        if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART 
                                            && this.props.orderDetail.woPreStart.availableTracking == true){
                                            return null;
                                        }
                                        else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE){
                                            return null;
                                        }else{
                                            return  <Detail/>
                                        }
                                    })()
                                }
                            </div>
                           
                        </div>
                        
                        
                        
                    }
                })()
                }
                <Alert openAlert={ this.state.openAlert } errorMsj={ this.state.errorMsj } onClose={ this.onCloseAlert } onAccept={ this.onCloseAlert } />
                 {
                (() => {
                    if(this.state.isFullMap){
                        return null;
                    } else if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE 
                        || this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA ){
                        return null;
                    }
                    else if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_NOTDONE_TRAZA){
                        return null;
                    }
                    else{
                        return <Footer /> ;
                    }
                })()
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderList: state.security.orderList, 
        orderDetail: state.security.orderDetail, 
        technician: state.schedule.scheduleTechnician,
        schedule: state.schedule.scheduleData,
        validHeader: state.security.userWebView,
        userLoginTime: state.security.userLoginTime,

        //config seguridad
        orderCode: state.security.orderCode,
        docNumber: state.security.documentNumber
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUpdateShowLocationFault: () => dispatch(faultActions.setUpdateShowLocation()),
        getOrders: () => dispatch(securityActions.getOrders()),
        getScheduledFault : () => dispatch(scheduleActions.getScheduledFault()),
        getFaultDetail: (request) => dispatch(securityActions.getFaultDetail(request)),
        setUpdateActivityFault: (value) => dispatch(securityActions.setUpdateActivityFault(value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Fault);