/*******************************************************************
 * 
    AMBIENTES DE DESPLIEGUE
    ***********************
    PRUEBA: 'TEST'
    DESARROLLO: 'DEV'
    PRODUCCIÓN: 'PROD'
    PRODUCCIÓN UK: 'PROD_UK' 
*
********************************************************************/


import config from '../configuracion';
// adecuacion security
import { v4 as uuidv4 } from 'uuid';

const { getKeysCredentials } = config;

export const REQUESTS = {

    HEADERS : {

        SECURITY: () => { return getKeysCredentials('SECURITY'); },
          
        PROVISION: () => { return getKeysCredentials('PROVISION');},
        
        FAULT: () => { return getKeysCredentials('FAULT');},
        
        SCHEDULE: () => { return getKeysCredentials('SCHEDULE');},
    },

    DATA : {

        APP_NAME: 'APP_WEB_FRONT_TRAZABILIDAD',
        
        //Seguridads
        OPERATION_VALIDATE_USER: "OPER_VALIDATE_USER",
        OPERATION_SEND_TOKEN: "OPER_SEND_TOKEN",
        OPERATION_VALIDATE_TOKEN: "OPER_VALIDATE_TOKEN",
        OPERATION_RESEND_TOKEN: "OPER_RESEND_TOKEN",
        OPERATION_GET_PARAMETER: "OPER_GET_PARAMETER",
        OPERATION_GET_ORDERS: "OPER_GET_ORDERS",
        OPERATION_SET_UPDATE_ACTIVITY: 'OPER_SET_UPDATE_ACTIVITY',

        //Provisión
        OPERATION_UPDATE_STATUS: 'OPER_UPDATE_STATUS',
        OPERATION_GET_PROVISION: 'OPER_GET_PROVISION',
        OPERATION_SET_PROVISION_VALIDATED: 'OPER_SET_PROVISION_VALIDATED',
        OPERATION_UPDATE_ADDRESS: 'OPER_UPDATE_ADDRESS',
        OPERATION_PROVISION_CANCELLATION: 'OPER_PROVISION_CANCELLATION',

        //Agendamiento
        OPERATION_GET_SCHEDULE: 'OPER_GET_SCHEDULE',
        OPERATION_GET_TECHNICIAN_INFO: 'OPER_GET_TECHNICIAN_INFO',
        OPERATION_SET_CONTACT_INFO_UPDATE: 'OPER_SET_CONTACT_INFO_UPDATE',
        OPERATION_GET_SCHEDULE_AVAILABILITY: 'OPER_GET_SCHEDULE_AVAILABILITY',
        OPERATION_SET_SCHEDULE: 'OPER_SET_SCHEDULE',

        //Avería
        OPERATION_GET_FAULT: 'OPER_GET_FAULT',
        OPERATION_FAULT_CANCELLATION: 'OPER_FAULT_CANCELLATION',
        OPERATION_SHOW_LOCATION: 'OPER_SHOW_LOCATION',        
        OPERATION_GET_RATING: "OPER_GET_RATING",
        OPERATION_SET_RATING: "OPER_SET_RATING",
    }
    
}


export function headerSecurity() {

    const serviceIdUUID = uuidv4();
    const userUUID = uuidv4();

    return {
        "unica-serviceid": userUUID,
        "unica-application": 'web-traceability',
        "unica-pid": serviceIdUUID,
        "unica-user": 'web-traceability',
        "Content-Type": 'multipart/form-data'
    };
}

export function headerSecurityAuth(auth) {

    const serviceIdUUID = uuidv4();
    const userUUID = uuidv4();
    
    return {
        "unica-serviceid": userUUID,
        "unica-application": 'web-traceability',
        "unica-pid": serviceIdUUID,
        "unica-user": 'web-traceability',
        "Content-Type": 'application/json',
        "Authorization": auth
    };
    
}

