import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// import iconTools from '../../../resources/svg/home/fault/iconTools.svg';
// import iconPoint from '../../../resources/svg/home/fault/iconPoint.svg';
import iconTools from '../../../resources/svg/home/fault/iconToolFull.svg';
import iconPoint from '../../../resources/svg/home/fault/iconPlaceFull.svg';
import iconArrow from '../../../resources/svg/home/iconArrow.svg';

import iconTV from '../../../resources/svg/home/detail/tvService.svg';
import iconInternet from '../../../resources/svg/home/detail/internetService.svg';
import iconTelephone from '../../../resources/svg/home/detail/telephoneService.svg';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as commonSelectors from '../../../store/selectors/common.selectors';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';

class Detail extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            collapse: false,
            collapseAddress: false,
            iconService: ''
        }
    }

    onChangeCollapse = (e) => {
        this.setState({ collapse: !this.state.collapse });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'avería detalle',
            eventAction: 'click',
            eventLabel: 'detalle de avería',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
    }

    onChangeCollapseAddress = (e) => {
        this.setState({ collapseAddress: !this.state.collapseAddress });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'avería detalle',
            eventAction: 'click',
            eventLabel: 'dirección de avería registrada',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
    }

    fullNameDate = () =>{
        let arrayDate = (moment(this.props.orderDetail.registerDate).format('YYYY-MM-DD')).split('-');
        let getNameMonth = commonSelectors.getMonthName(arrayDate[1]);

       return `${arrayDate[2]} de ${getNameMonth.charAt(0).toUpperCase() + getNameMonth.slice(1).toLowerCase()} del ${arrayDate[0]}`;
    }

    fullReferencesAddress = () => {
        let ref = this.props.orderDetail.customer.reference;
        ref = ref != null ? `Ref: ${ref}, ` : "";
        return ref;
    }

    fullReferencesDistrict = () => {
        let ref = this.props.orderDetail.customer.district;
        ref = ref != null ? ` ${ref}, ` : "";
        return ref;
    }

    fullReferencesProvince = () => {
        let ref = this.props.orderDetail.customer.province;
        ref = ref != null ? ` ${ref} ` : "";
        return ref;
    }

    componentDidMount = () => {

        if(this.props.orderDetail.productType != null){
            if(this.props.orderDetail.productType.toUpperCase() === "TV"){
                this.setState({iconService : iconTV})
            }else if(this.props.orderDetail.productType.toUpperCase() === "FIJO"){
                this.setState({iconService : iconTelephone})
            }else{
                this.setState({iconService : iconInternet})
            }
        }
    }

    render() {
        
        return (

            <div className="wrp-product-fault">
                <div className="container">
                    <div className="bg-full-web d-flex">
                        <div className="item-product">
                           
                            <div className="d-flex">
                                <div className="icon">
                                    <img id="img-tools-detailFault" src={iconTools} alt=""/>
                                </div>
                                <div className="info">
                                    <p id="lbl-detailTitle-detailFault" className="subtitle">Detalle de Avería</p>
                                    <p id="lbl-name-detailFault" className="name">{"Servicio afectado: " + this.props.orderDetail.faultDetail}</p>
                                    <p id="lbl-report-detailFault" className="name">{"Fecha de reporte de avería:"}</p>
                                    <p id="lbl-date-detailFault" className="date">{this.fullNameDate()}</p>
                                </div>
                            </div>  
                        </div>

                        <div className="item-product">
                            
                            <div className="d-flex">
                                <div className="icon">
                                    <img id="img-internet-detail" src={iconPoint} alt=""/>
                                </div>
                                <div className="info">
                                    <p id="lbl-addressTitle-detailFault" className="subtitle">Dirección de avería registrada</p>
                                    <p id="lbl-address-detailFault" className="name">{this.props.orderDetail.customer.address}</p>
                                    <p id="lbl-reference-detailFault" className="ref">{this.fullReferencesAddress()}</p>
                                    <p id="lbl-district-detailFault" className="text-district">{this.fullReferencesDistrict()} {this.fullReferencesProvince()}</p>   
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                    <div className="bg-full-mob">      
                        <div className="box-links">
                            <div className={`collapse-home ${this.state.collapse ? 'color-bluee' : 'color-greey'} `}>
                                <ExpansionPanel
                                    expanded={this.state.collapse}
                                    onChange={this.onChangeCollapse}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography component="div">

                                            <div className="d-flex">
                                                <img id="img-fault-detailFault" className="img-fault" src={iconTools} alt="" />
                                                <span id="lbl-detail-detailFault" className="txt1">Detalle de la avería</span>
                                            </div>

                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div className="wrp-detail flex-wrap">
                                            {
                                            <div className="items-desc d-flex  flex-column">
                                                <div className="d-flex">   
                                                    <div className="icon">
                                                        <img id="img-arrow3-detailFault" src={iconArrow} alt="" />
                                                    </div>
                                                    <div className="desc">
                                                        <p id="lbl-service-detailFault" className="txt1-desc">{"Servicio afectado:"}</p>
                                                        <div className="box-service d-flex">
                                                            {this.props.orderDetail.productType ? <img id="img-service-detailFault" className="img-service" src={this.state.iconService} alt="" /> : null}
                                                            <p id="lbl-description-detailFault" className="txt2-desc">{this.props.orderDetail.faultDetail}</p>
                                                        </div>    
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="icon">
                                                        <img id="img-arrow3-detailFault" src={iconArrow} alt="" />
                                                    </div>
                                                    <div className="desc">
                                                        <p id="lbl-report-detailFault" className="txt1-desc">{"Fecha de reporte de avería:"}</p>
                                                        <p id="lbl-date-detailFault" className="date">{this.fullNameDate()}</p>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            }
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>

                        { !this.state.collapse && <div className= "line"/>}
                            <div className={`collapse-home ${this.state.collapseAddress ? 'color-bluee' : 'color-greey'} `}>
                                <ExpansionPanel
                                    expanded={this.state.collapseAddress}
                                    onChange={this.onChangeCollapseAddress}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography component="div">

                                            <div className="d-flex">
                                                <img id="img-detail-home" src={iconPoint} alt="" />
                                                <div  className="d-flex flex-column justify-content-between">
                                                    <span id="lbl-detail-home" className="txt1">Dirección de avería registrada</span>
                                                    <span id="lbl-detail-home-subtitle" className="txt2">{this.props.orderDetail.customer.address}</span>
                                                </div>
                                                
                                            </div>

                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div className="wrp-detail flex-wrap">
                                        {
                                            <div className="items-desc d-flex">
                                                <div className="icon">
                                                    <img id="img-arrow3-home" src={iconArrow} alt="" />
                                                </div>
                                                <div className="desc">
                                                    <p id="lbl-address-detailFault" className="txt1-desc">{this.props.orderDetail.customer.address}</p>
                                                    <p id="lbl-reference-detail" className="txt2-desc">{this.fullReferencesAddress()}</p>
                                                    <p id="lbl-district-detailFault" className="text-district">{this.fullReferencesDistrict()} {this.fullReferencesProvince()}</p>
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </div>
                    </div>
                
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail //provisionSelectors.getOrderById(state.security.ordersIdSelected, state.security.ordersData), 
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Detail);