import React from 'react';
import './ReturnButton.scss';
import iconVolverFlecha from '../../../resources/svg/header/iconVolverFlecha.svg';
import iconVolverMobile from '../../../resources/svg/header/iconVolverMobile.svg';

const ReturnButton = ({onClick, isAbsolute, clases = '' }) => 
(
    <div id="btn-back" className={clases} >
        <div className={`wrp-back d-flex-inline pointer ${isAbsolute?'back-abs':null}`} onClick={onClick}>
            <img id="img-arrow-return" className="icon-web"  src={iconVolverFlecha} alt="" />
            <img id="img-arrow-return" className="icon-movil"  src={iconVolverMobile} alt="" />
            <div id="lbl-title-return" className="text" >Volver</div>
        </div>
    </div>
)

export default ReturnButton;