import React from 'react';
import './Info.scss';
import iconInformation from '../../resources/svg/alertInformation/iconInformation.svg';
import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';

const Info = ({ openAlert, onClose, onAccept }) => 
(
    <> 
      <Modal 
        classNames={{
          overlay: 'alert-overlay',
          modal: 'alert-modal',
        }}
        open={openAlert} 
        onClose={onClose} 
        center
        >
        <div className="bxModalInfo d-flex flex-column justify-content-center align-items-center">
            <img className="llamada" src={iconInformation} alt="" />
            <span id="lbl-title-modalinfo" className="title">Ten en cuenta</span>
            <p id="lbl-detail-modalinfo" className="detalle">Después de corregir tu dirección,<br/>esta se actualizará en 24 horas</p>
            
            <div className="bxButtons"> 
                 <button id="btn-cancel-modalinfo" className="buttonCancel" onClick={onClose} >Cancelar</button>
                 <button id="btn-accept-modalinfo" className="buttonAccept" onClick={onAccept} >Aceptar</button>
            </div>
        </div>
      </Modal>  
    </>  
)

export default Info;