import React, { Component } from 'react';
import { connect } from 'react-redux'; // Asegúrate de importar connect
import './DeliveryDetail.scss';
import { CONSTANTS } from '../../utils/Constants';
import Footer from '../../components/common/elements/Footer';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import iconBack2 from '../../resources/svg/home/iconArrowBack.svg';
import HeaderDelivery from '../headerDelivery/HeaderDelivery';
import iconChipDispositivo from '../../resources/svg/detallePedido/iconChipDispositivo.svg';
import iconChangePlan from '../../resources/svg/detallePedido/iconChangePlan.svg';
import iconMovil from '../../resources/svg/detallePedido/iconMovil.svg';
import iconChip from '../../resources/svg/detallePedido/iconChip.svg';
import iconUbicacion from '../../resources/svg/detallePedido/iconUbicacion.svg';
import iconDNI from '../../resources/svg/detallePedido/iconDNI.svg';
import iconAdvertencia from '../../resources/svg/detallePedido/iconAdvertencia.svg';
import iconAlerta from '../../resources/svg/detallePedido/iconAlerta.svg';
import iconCancel from '../../resources/svg/detallePedido/iconCancel.svg';
import Alert from '../modals/Alert';

class DeliveryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openAlert: false,
      firstAddress: '',
      secondAddress: '',
      deliveryOrder: [this.props.deliveryOrder],
      deliveryOrderDetail: [this.props.orderDeliveryDetail],
      totalMount:
        this.props.orderDeliveryDetail.deliveryOrder.appliedAmount
          .amountToCollect,
      error: false, // controla si hay el chekpoint cancelado
      isCancelled: false,
      cancelledCheckpoint: [],
      shippingTypeTienda: false,
      shipmentItemsSim: {},
      shipmentItemsEquip: {},
      shipmentItemsMessage: '',
      tipodeCompra: '',
    };
  }

  componentDidMount() {
    console.log(
      'tipo de COMPRA:',
      this.props.orderDeliveryDetail.deliveryOrder.productOrder.orderActionType
    );
    this.splitAddress();
    this.checkCancelledStatus();
    this.getShipmentTypeMessage();
    this.shippingTypeStatus();
    this.getTipodeCompra();
  }

  getTipodeCompra = () => {
    const orderDetail = this.props.orderDeliveryDetail;
    if (
      !orderDetail ||
      !orderDetail.deliveryOrder ||
      !orderDetail.deliveryOrder.productOrder ||
      !orderDetail.deliveryOrder.productOrder.orderActionType
    ) {
      console.error('Error: orderActionType no está definido');
      this.setState({ tipodeCompra: 'Desconocido' }); // O cualquier valor predeterminado
      return;
    }
    const tipodeCompra = this.capitalizeWords(
      orderDetail.deliveryOrder.productOrder.orderActionType
    );
    this.setState({ tipodeCompra: tipodeCompra });
  };

  capitalizeWords(str) {
    if (!str) {
      return '';
    }
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  splitAddress = () => {
    let address = this.state.deliveryOrderDetail[0].deliveryOrder.address.name;
    const parts = address.split('-');
    const firstPart = this.capitalizeWords(
      parts.slice(0, -3).join(' - ').trim()
    );
    const lastPart = this.capitalizeWords(parts.slice(-3).join(' - ').trim());

    this.setState({
      firstAddress: firstPart,
      secondAddress: lastPart,
    });
  };

  checkCancelledStatus = () => {
    const orderDetail = this.props.orderDeliveryDetail;
    if (
      !orderDetail ||
      !orderDetail.deliveryOrder ||
      !orderDetail.deliveryOrder.checkpoints
    ) {
      return;
    }

    const checkpoints = orderDetail.deliveryOrder.checkpoints;

    // Contar cuántos checkpoints tienen el estado 'EN CAMINO'
    let existeDosEnCamino = false;
    const enCaminoCount = checkpoints.filter(
      (cp) => cp.status === 'EN CAMINO'
    ).length;
    if (enCaminoCount > 1) {
      existeDosEnCamino = true;
    }
    // Eliminar el primer duplicado porque puede existir 2 status de 'EN CAMINO'
    // Usar un objeto para rastrear el último estado
    const lastSeenStatuses = {};
    checkpoints.forEach((cp) => {
      lastSeenStatuses[cp.status] = cp; // Guardar el último checkpoint de cada estado
    });
    // Crear un nuevo array con los últimos duplicados para mantener el ultimo 'EN CAMINO'
    const updatedCheckpoints = Object.values(lastSeenStatuses);

    let cancelledCheckpoint;
    if (existeDosEnCamino === true) {
      cancelledCheckpoint = updatedCheckpoints.find(
        (checkpoint) =>
          checkpoint.status === 'CANCELADO' ||
          checkpoint.status === 'RECHAZADO' ||
          checkpoint.status === 'NO ENTREGADO'
      );
    } else {
      cancelledCheckpoint = updatedCheckpoints.find(
        (checkpoint) =>
          checkpoint.status === 'CANCELADO' || 
          checkpoint.status === 'RECHAZADO' ||
          checkpoint.status === 'NO ENTREGADO'
      );
    }
    if (cancelledCheckpoint) {
      this.setState({
        isCancelled: true,
        error: true,
        cancelledCheckpoint: cancelledCheckpoint,
      }, () => {
        console.log('checkpoints cancelled', this.state.cancelledCheckpoint.statusChangeReason)
        console.log('checkpoints cancelled', this.state.cancelledCheckpoint.statusChangeSubReason)
        
        console.log('checkpoints cancelled', !!this.state.cancelledCheckpoint.statusChangeSubReason)
      });

      console.log('cancelledCheckpoint true', cancelledCheckpoint);
    } else {
      this.setState({
        isCancelled: false,
        cancelledCheckpoint: [],
      }, () => {        
        console.log('checkpoints cancelled', this.state.cancelledCheckpoint.statusChangeReason)
        console.log('checkpoints cancelled', this.state.cancelledCheckpoint.statusChangeSubReason)
      });
      console.log('cancelledCheckpoint false', cancelledCheckpoint);

    }
  };

  getShipmentTypeMessage = () => {
    const shipmentItems =
      this.props.orderDeliveryDetail.deliveryOrder.shipmentItems;
    let mensaje;
    const hasIMEI = shipmentItems.find((item) => item.resourceType === 'IMEI');
    const hasICCID = shipmentItems.find(
      (item) => item.resourceType === 'ICCID'
    );

    if (hasIMEI && hasICCID) {
      mensaje = 'Equipo celular y chip';
    } else if (hasIMEI) {
      mensaje = 'solo equipo celular';
    } else if (hasICCID) {
      mensaje = 'solo chip';
    } else {
      mensaje = 'Tipo de envío desconocido';
    }

    this.setState({
      shipmentItemsSim: hasICCID,
      shipmentItemsEquip: hasIMEI,
      shipmentItemsMessage: mensaje,
    });
  };

  shippingTypeStatus = () => {
    const orderDetail = this.props.orderDeliveryDetail;
    if (
      !orderDetail ||
      !orderDetail.deliveryOrder ||
      !orderDetail.deliveryOrder.shippingInstruction ||
      !orderDetail.deliveryOrder.shippingInstruction.shippingType
    ) {
      return;
    }
    const shippingTypeTienda =
      orderDetail.deliveryOrder.shippingInstruction.shippingType;
    if (shippingTypeTienda === 'TIENDA' || shippingTypeTienda === 'PICKUP') {
      this.setState({
        shippingTypeTienda: true,
      });
    } else {
      this.setState({
        shippingTypeTienda: false,
      });
    }
  };

  onAlert = () => {
    this.setState({
      openAlert: true,
    });

    if (this.state.attemptsPerDay) {
      this.props.history.push({
        pathname: CONSTANTS.PATH.LOGIN,
      });
    }
  };

  onCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  back = () => {
    this.props.history.goBack();
  };

  formatMount(mount) {
    if (mount == null) {
      return '00.00';
    }

    const val = parseFloat(mount);
    const valFinal = val.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return valFinal;
    //return parseFloat(mount).toFixed(2);
  }

  render() {
    return (
      <section id="deliveryDetail">
        <HeaderDelivery />
        <div className="container-backButton">
          <div className="content-backButton">
            <Button
              disableRipple
              onClick={this.back}
              className="back-button"
              startIcon={<Avatar src={iconBack2} />}
            >
              Volver
            </Button>
          </div>
        </div>
        <div className="container">
          <div
            className="content-error "
            style={this.state.error === false ? { display: 'none' } : {}}
          >
            
            <div 
                  className="content-recomendaciones error"  
                  style={!!this.state.cancelledCheckpoint.statusChangeReason || !!this.state.cancelledCheckpoint.statusChangeSubReason ? {}: { display: 'none' } }
            >
              <div>
                <img src={iconCancel} alt="iconCancel" />
              </div>
              <div className="text-alert">
                <h3 className="title-alert-card">

                  {this.state.cancelledCheckpoint
                    ? this.capitalizeWords(
                        this.state.cancelledCheckpoint.statusChangeReason
                      )
                    : ''}
                </h3>
                <p>
                  {this.state.cancelledCheckpoint &&
                    this.state.cancelledCheckpoint.statusChangeSubReason}
                </p>
              </div>
            </div>

            <div className="content-recomendaciones alerta">
              <div>
                <img src={iconAlerta} alt="iconAlerta" />
              </div>
              <div className="text-alert">
                <h3 className="title-alert-card">
                  Si tienes alguna duda sobre tu pedido
                </h3>
                <p>
                  Llámanos al <b>080011800</b> o <b>104</b> todos los días de 8
                  a.m. a 10 p.m. O visítanos en nuestras tiendas, {''}
                  <a href="https://www.movistar.com.pe/atencion-al-cliente/centros-de-atencion">
                    <span>encuéntralas aquí.</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <h1 id="title-deliveryDetail">Detalles de mi pedido</h1>
          <div className="container-detail">
            <section
              className="column-detail"
              style={
                this.state.error === true
                  ? {
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      width: '100%',
                      gap: '32px',
                      justifyContent: 'center',
                    }
                  : {}
              }
            >
              <div
                className="content-sections"
                style={this.state.error === true ? { width: '382px' } : {}}
              >
                <h2 className="title-information">Descripción</h2>
                <div className="content-description">
                  <div className="description-information">
                    <div className="content-icon">
                      <img
                        src={
                          this.state.shipmentItemsMessage ==
                          'Equipo celular y chip'
                            ? iconChipDispositivo
                            : iconChangePlan
                        }
                        alt="iconChipDispositivo"
                      />
                    </div>
                    <div className="text-information">
                      <h3 className="subtitle-information">Tipo de compra</h3>
                      <p className="detail-information">
                        {this.state.tipodeCompra} - {'  '}
                        {this.state.shipmentItemsMessage}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div
                    style={
                      this.state.shipmentItemsEquip != undefined
                        ? {}
                        : { display: 'none' }
                    }
                  >
                    <div className="description-information center">
                      <div className="content-icon">
                        <img src={iconMovil} alt="iconMovil" />
                      </div>
                      <div className="text-information">
                        <h3 className="subtitle-information2">
                          {this.state.shipmentItemsEquip &&
                            this.state.shipmentItemsEquip.shipmentItemName}
                        </h3>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div
                    style={
                      this.state.shipmentItemsSim != undefined
                        ? {}
                        : { display: 'none' }
                    }
                  >
                    <div className="description-information center">
                      <div className="content-icon">
                        <img src={iconChip} alt="iconChip" />
                      </div>
                      <div className="text-information">
                        <h3 className="subtitle-information2">
                          {this.state.shipmentItemsSim &&
                            this.state.shipmentItemsSim.shipmentItemName}
                        </h3>
                      </div>
                    </div>
                    <hr />
                  </div>

                  <div className="description-total">
                    <p className="text-total">Total</p>
                    <p className="text-monto">
                      S/ {this.formatMount(this.state.totalMount)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="content-sections"
                style={this.state.error === true ? { width: '382px' } : {}}
              >
                <h2 className="title-information">Dirección de entrega</h2>
                <div className="content-address">
                  <div className="content-icon">
                    <img src={iconUbicacion} alt="iconUbicacion" />
                  </div>
                  <div className="content-information-address">
                    {this.state.shippingTypeTienda ? (
                      <>
                        <p className="first-information">
                          En cualquier Tambo de Lima
                        </p>
                        <p className="tambo-information" onClick={this.onAlert}>
                          Excepto estos locales
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="first-information">
                          {this.state.firstAddress}
                        </p>
                        <p className="second-information">
                          {this.state.secondAddress}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section
              className="column-detail"
              style={this.state.error === true ? { display: 'none' } : {}}
            >
              <h2 className="title-information">Recomendaciones</h2>
              <div className="container-recomendaciones">
                <div className="content-recomendaciones">
                  <div>
                    <img src={iconDNI} alt="iconDNI" />
                  </div>
                  <div className="content-text">
                    <h3 className="title-recomendacion">
                      La entrega es personal
                    </h3>
                    <p>
                      Requieres portar tu DNI, así como la <br /> verificación
                      de huella dactilar.
                    </p>
                  </div>
                </div>
                <div className="content-recomendaciones">
                  <div>
                    <img src={iconChipDispositivo} alt="iconChipDispositivo" />
                  </div>
                  <div className="content-text">
                    <h3 className="title-recomendacion">
                      Token de portabilidad
                    </h3>
                    <p>
                      Debes de contar con la línea a portar a la mano y activa
                      para realizar validaciones.
                      {/* <a href="https://www.movistar.com.pe/atencion-al-cliente/crea-tu-contrase%C3%B1a">
                        <span>Contraseña única</span>
                      </a> */}
                    </p>
                  </div>
                </div>
                <div className="content-recomendaciones advertencia">
                  <div>
                    <img src={iconAdvertencia} alt="iconAdvertencia" />
                  </div>
                  <p>
                    No olvides revisar que el producto sea el que solicitaste.
                  </p>
                </div>
                <div className="content-recomendaciones alerta">
                  <div>
                    <img src={iconAlerta} alt="iconAlerta" />
                  </div>
                  <div className="text-alert">
                    <h3 className="title-alert-card">
                      Si tienes alguna duda sobre tu pedido
                    </h3>
                    <p>
                      Llámanos al <b>080011800</b> o <b>104</b> todos los días
                      de 8 a.m. a 10 p.m. O visítanos en nuestras tiendas, {''}
                      <a href="https://www.movistar.com.pe/atencion-al-cliente/centros-de-atencion">
                        <span>encuéntralas aquí.</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Alert
          id="mdl-error-token"
          openAlert={this.state.openAlert}
          // errorMsj={this.state.errorMsj}
          onClose={this.onCloseAlert}
          localesTambo={true}
        />
        <Footer />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  // tu mapStateToProps...
  deliveryOrder: state.security.deliveryOrder,
  orderDeliveryDetail: state.security.orderDeliveryDetail,
});

const mapDispatchToProps = (dispatch) => ({
  // tu mapDispatchToProps...
});

// export default DeliveryDetail
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetail);
// export default connect(DeliveryDetail);
