import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingHogar from '../../resources/img/login/loader.gif';
import IconError from '../../resources/img/login/iconError.svg';

import {CONSTANTS} from '../../utils/Constants';
import './External.scss';

import * as securityActions from '../../store/actions/security.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';

class External extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showError: false,
            errorMessage: ''
        }
    }

    componentDidMount() {
        //DELETE STORE
        this.props.resetUser();
        this.props.resetProvision();
        this.props.resetSchedule();
    }

    componentWillMount() {
        this.apiGetLogin();
    }

    apiGetLogin = () =>{
        this.setState({ showLoading: true });
        let urlParams = window.location.search;
        let token = urlParams.substr(6);

        if (token) {
            
            let request = {
                documentType: '',
                documentNumber: '',
                source: 'MOBILE',
                token: token
            }

            this.props.securityLoginToken(request).then(resp => {
                console.log("=============== securityLoginToken ===============");
                console.log(resp)
                if (resp.status === 'OK') {
                    this.apiGetProvision();
                } else {
                    this.setState({ showLoading: false })
                    this.errorRender({ errorMessage: CONSTANTS.MESSAGE.ERROR.ERROR_400 })
                }

            });

        } else {
            this.errorRender({ errorMessage: 'Página no encontrada.' });
            this.setState({ showLoading: false });
        }
    }

    apiGetProvision = () => {

        this.props.getProvision().then(resp => {
            console.log("=============== getProvision ===============");
            console.log(resp)
            if (resp.status === 'OK') {
                
                if(resp.data.length === 1 ){
                    this.props.setTypeScheduleSource('provision');
                    this.apiGetShedule();
                } else {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LIST
                    });
                }
  
            } else {
                this.setState({ showLoading: false })
                this.errorRender({ errorMessage: CONSTANTS.MESSAGE.ERROR.ERROR_400 })
            }
        })
    }

    apiGetShedule = () => {
        this.props.getSchedule().then(resp => {
            console.log("=============== getSchedule ===============");
            console.log(resp)
            if (resp.status === 'OK') {
                this.props.history.push({
                    pathname: CONSTANTS.PATH.HOME
                });
            } else {
                this.setState({ showLoading: false })
                this.errorRender({ errorMessage: CONSTANTS.MESSAGE.ERROR.ERROR_400 })
            }
        });
    }

    errorRender = (obj) => {
        this.setState({ ...obj, showError: true })
    }

    render() {
        return (

            <section id="external">

                {this.state.showLoading && <img src={LoadingHogar} className="loadingHogar" style={{ backgroundColor: 'black' }} />}
                {this.state.showError &&
                    <div className="wrp-lt" >
                        <div className="box">
                            <div className="wrp-img">
                                <img src={IconError} alt=""/>
                            </div>
                            
                            <h1 className="title">Ups, lo sentimos</h1>
                            <h2 className="titleMessage">{this.state.errorMessage}</h2>

                        </div>
                    </div>
                }

            </section>
        )
    }
}

const mapStateToProps = state =>( {
    user: state.security.userData,
    
})

const mapDispatchToProps = dispatch => ({
    securityLoginToken: (request) => dispatch(securityActions.securityLoginToken(request)),
    getProvision: () => dispatch(provisionActions.getProvision()),
    getSchedule: () => dispatch(scheduleActions.getSchedule()),
    
    setTypeScheduleSource: (typeScheduleSource) => dispatch(scheduleActions.setTypeScheduleSource(typeScheduleSource)),

    resetUser: () => dispatch(securityActions.securityReset()),
    resetProvision: () => dispatch(provisionActions.provisionReset()),
    resetSchedule: () => dispatch(scheduleActions.scheduleReset())
})



export default connect(mapStateToProps, mapDispatchToProps)(External);