export const  CONSTANTS = {

    PATH : {
        BASE: '/',
        BASENAME: '',
        MAINTENANCE: '/maintenance',
        ONBOARDING: '/onboarding',
        EXTERAL_ACCESS: '/externalAccess',

        LOGIN: '/login',
        LOGIN_ADMIN: '/telefonicadelperu/trazabilidad/admin',
        LOGIN_TOKEN: '/token',
        LOGIN_COMPONENT: 'login',

        TOKEN: '/token',
        LIST: '/list',

        HOME: '/home',
        CONTACTS: '/contacts',
        
        PROVISION_DETAIL: '/provision/detail',
        FAULT_DETAIL: '/fault/detail',
        FAULT: '/fault',

        SCHEDULE: '/schedule',
        SCHEDULE_CONFIRM: '/schedule/confirm',

        ORDER_CANCEL: '/order/cancel',
        ORDER_LOGOUT: '/order/logout',
        ORDER_CONTACT: '/order/contact',
        ORDER_DIRECTION: '/order/direction',

        ADDRESS: '/address',
        CANCEL: '/cancel',
        CONFIRMATION_CANCEL : '/cancel/confirmation',
        MAP: '/map',
        DEVOLUTION: '/devolution',
        ABSENT: '/absent'
    },

    PRODUCT : {
        PROVISION_TYPE: 'provision',
        FAULT_TYPE: 'fault'
    },

    STATUS : {
        PENDIENTE: 'pendiente',
        INGRESADO: 'ingresado',
        CAIDA: 'caida',
        ACTIVE: 'active',
        ADDRESS_CHANGED: 'addressChanged',
        CANCEL: 'cancelled',
        WO_NOT_DONE: "notDone",
        COMPLETE: "completed",
        WO_PRESTART: 'scheduleInProgress',
        WO_INIT: 'init',
        PENDIENTE_PAGO: 'pendiente-pago',
        PAGADO: 'pagado',
        CANCELADA_ATIS: 'cancelada_atis',
        FINALIZADO: 'finalizado',
        TERMINADA: 'terminada',

        TRIAJE_PENDIENTE: 'pendiente',
        TRIAJE_LIQUIDADO: 'liquidado',
        TRIAJE_MASIVA: 'masiva',

        WO_PRENOTDONE: 'preNotDone',
        WO_NOTDONE_TRAZA: 'notDoneTraza',
        WO_PRENOTDONE_TRAZA: 'preNotDoneTraza'

    },

    KEY : {
        COMPLETE: "RATING_COMPLETED",
        WO_NOT_DONE: "RATING_NOTDONE",
    },

    PARAM : {
        PARAM_MAINTANCE: 'PARAM_MAINTANCE_ENABLE_SERVICE',
        PARAM_RESCHEDULE: 'DIFF_RESCHEDULE_DAYS',
        DEFAULT_TIMER: '300000' // unidades en milisegundos equivale a 5 minutos
    },

    MESSAGE : {
        SECURITY : {
            MAX_ATTEMPTS : 'Lo sentimos, pero has superado la </br> cantidad máxima de intentos.</br>Vuelve a intentarlo mañana.',
            INVALID_DOC_WEB : 'El número de documento ingresado no es válido.',
            INVALID_DOC_APP : `Lo sentimos, pero no </br> encontramos un pedido registrado </br> con este documento`,
            INVALID_DOC_APP_LOGIN : `El documento ingresado no tiene un pedido o reporte de avería registrado`, //Lo sentimos, no encontramos un pedido registrado para este documento.
            INVALID_TOKEN: 'La clave es incorrecta.',
            SENT_TOKEN : 'Clave de seguridad enviada.',
            ERROR : 'Lo sentimos, el sistema no puede acceder a tus datos en este momento. Por favor vuelve a intentarlo más tarde',
            ERROR_405 : 'Estamos trabajando para brindarte un mejor servicio',
            ERROR_411 : 'Lo sentimos, no tenemos registrado un número celular para enviarte un SMS con la clave de seguridad.',
            ERROR_TOKEN: 'Lo sentimos, el sistema no puede acceder a tu pedido en este momento. Por favor vuelve a intentarlo más tarde',
            ERROR_429: 'Por favor, inténtelo Denuevo en 2 minutos'
        },

        SCHEDULE : {
            NO_CAPACITY : 'No tenemos cupos disponibles, por favor intente el día de mañana.',
            GET_CAPACITY_ERROR_400: 'Ha ocurrido un error, el sistema no puede acceder al agendamiento.',
            GET_CAPACITY_ERROR_400_FIRST: 'El sistema no puede acceder al agendamiento. Un asesor te llamará en las próximas horas, si no logramos contactarte un técnico te visitará  en un lapso de 7 días hábiles.',
            GET_CAPACITY_ERROR_401: 'El sistema no puede acceder al agendamiento. Por favor, vuelve a intentarlo más tarde.',
            GET_CAPACITY_ERROR_403: 'En este momento no tenenemos disponibilidad de agendamiento. Por favor vuelve a intentarlo mañana.',
            GET_CAPACITY_ERROR_404: 'El sistema no puede acceder al agendamiento. Por favor, vuelve a intentarlo más tarde.',
            GET_CAPACITY_ERROR_409: 'El agendamiento no procede porque tu solicitud ya fue atendida por el servicio técnico.',
            GET_CAPACITY_ERROR_500: 'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
            
            SET_SCHEDULE_ERROR_400: 'Ha ocurrido un error, el sistema no puede guardar la fecha y turno elegido.',
            SET_SCHEDULE_ERROR_400_FIRST: 'El sistema no pudo guardar la fecha y turno elegido. Un agente te llamará en las próximas horas, si no logramos contactarte un técnico te visitará en un lapso de 7 días hábiles.',
            SET_SCHEDULE_ERROR_401: 'El sistema no puede guardar la fecha y turno elegido. Por favor vuelve a intentarlo más tarde.',
            SET_SCHEDULE_ERROR_403: 'En este momento no tenenemos disponibilidad de agendamiento. Por favor, vuelve a intentarlo mañana.',
            SET_SCHEDULE_ERROR_404: 'El sistema no puede guardar la fecha y turno elegido. Por favor, vuelve a intentarlo más tarde.',
            SET_SCHEDULE_ERROR_409: 'Lo sentimos el agendamiento no procede porque tu solicitud ya fue atendida por el servicio técnico.',
            SET_SCHEDULE_ERROR_500: 'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
    
            SCHEDULE_CANCEL_ERROR_400: 'Ha ocurrido un error, el sistema no puede cancelar tu pedido en este momento.',
            SCHEDULE_CANCEL_ERROR_401: 'No se ha podido establecer conexión con el servidor.',
            SCHEDULE_CANCEL_ERROR_404: 'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
            SCHEDULE_CANCEL_ERROR_409: 'La cancelación no procede porque tu solicitud ya fue atendida por el servicio técnico.',
            SCHEDULE_CANCEL_ERROR_500: 'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
    
            UPDATE_DATA_CONTACT : 'No pudimos guardar tus datos de contacto porque tu solicitud ya fue atendida por el servicio técnico.'
        },
    
        CONTACT : {
            SET_CONTACT_ERROR_400: 'Ha ocurrido un error, el sistema no puede guardar los datos de tu contacto en este momento.',
            SET_CONTACT_ERROR_401: 'Ha ocurrido un error, el sistema no puede guardar tus datos de contacto. Por favor, vuelve a intentarlo más tarde.',
            SET_CONTACT_ERROR_404: 'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
            SET_CONTACT_ERROR_409: 'La actualización de datos de contacto no procede porque tu solicitud ya fue atendida por el servicio técnico.',
            SET_CONTACT_ERROR_500: 'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
    
        },

        CANCEL : {
            TITLE_UNCHECKED: 'Por favor, selecciona el motivo por </br> el cuál cancelas tu pedido.',
            TITLE_CHECKED: 'Por favor, cuéntanos el motivo por el </br> cual cancelas tu pedido.'
        },

        CONFIRMATION_CANCEL : {
            MOBILE:'Tu pedido ha sido cancelado',
            WEB:'Recuerda que si deseas adquirir un nuevo producto,</br>puedes LLAMAR GRATIS al 104.'
        },

        ERROR : {
            ERROR_400 : 'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
            ERROR_500 : 'No se ha podido establecer conexión con el servidor. Por favor, vuelva a intentarlo más tarde.',
        },

        CLIENT_ABSENT : {
            INACTIVITY : 'El plazo de espera del técnico ha terminado. Nos comunicaremos contigo para agendar una nueva fecha de instalación.',
        }
    }

}