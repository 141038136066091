import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../../utils/Constants';


class IsAuthUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevPath: null,
            currentPath: null,
        }
    }
    
    componentWillMount() {
        console.log('fromIS: ' + this.props.location.pathname);
        console.log('toIS: ' + this.props.history.location.pathname);
        
        if(this.props.history.location.pathname === CONSTANTS.PATH.TOKEN){
            console.log("A1")
            if(this.props.provisionAll == null){                    
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }

        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.LIST){
            console.log("A2")
            if(this.props.provisionAll == null){
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }   
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.HOME){
            console.log("Aas1")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length>1){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.FAULT){
            console.log("A3")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length>1 && this.props.orderDetail == null){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.SCHEDULE){
            console.log("A4")
            if(this.props.provisionAll != null && this.props.user!= null){

                if(this.props.cupos === null){
                    if(this.props.provisionAll.length>1){
                        window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                    }else if(this.props.orderType.toLowerCase() == "provision"){
                        window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;
    
                    }else{
                        window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.FAULT;
                    }
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.SCHEDULE_CONFIRM){
            console.log("Asd11")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length>1){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.CANCEL){
            console.log("A1s")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length > 1){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;

                }else if(this.props.orderType.toLowerCase() == "provision"){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;

                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.FAULT;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.CONFIRMATION_CANCEL){
            console.log("As1")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length > 1){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.CONTACTS){
            console.log("A21")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length > 1){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;

                }else if(this.props.orderType.toLowerCase() == "provision"){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;

                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.FAULT;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.ADDRESS){
            console.log("A21")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length > 1){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;

                }else if(this.props.orderType.toLowerCase() == "provision"){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;

                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.FAULT;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

        if(this.props.history.location.pathname === CONSTANTS.PATH.MAP){
            console.log("A1sdfs")
            if(this.props.provisionAll != null && this.props.user != null){

                if(this.props.provisionAll.length > 1){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                }else if(this.props.orderType.toLowerCase() == "provision"){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;

                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.FAULT;
                }

            }else{
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
            }
        }

    }   

    render() {

        return this.props.children;
            
    }
}
const mapStateToProps = (state, ownProps) => ({
    user: state.security.userData,
    //provisionAll: state.provision.provisionData,
    //provisionSelected: state.provision.provisionIdSelected,

    orderDetail: state.security.orderDetail,
    provisionAll: state.security.orderList, 
    provisionSelected: state.security.ordersIdSelected,
    orderType: state.security.orderType,    

    cupos: state.schedule.scheduleCupos,
    schedule: state.schedule.scheduleData,
});


export default withRouter(connect(mapStateToProps, null)(IsAuthUser));
