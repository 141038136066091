import React, { Component } from 'react';
import './AddressWeb.scss';
import iconLocation from '../../../resources/svg/address/iconLocation.svg';
import iconInformation from '../../../resources/svg/address/iconInformation.svg';

import { CONSTANTS } from '../../../utils/Constants';

class AddressWeb extends Component {
   
    render() {
        const {onBack, onClick, user, provision} = this.props; 
        console.log("prov" ,provision);
        return (
            <div id="addressWeb">

                <div className="wrp-address">
                    <p id="lbl-title-address" className="address-title">Tu dirección registrada es:</p>
                    <div className="box-detail d-flex">
                        <div className="box-address">
                            <div className="address-info d-flex">
                                <div className="info d-flex">
                                    <div className="icon">
                                        <img src={iconLocation} alt="" />
                                    </div>
                                    <div className="text">
                                        {provision.customer.address} <br/>
                                        <p id="lbl-reference-address" className="text-reference">{provision.customer.reference}</p>
                                        <span><p id="lbl-district-address" className="text-district">{provision.customer.district}, {provision.customer.province}</p></span>
     
                                        { (() => {
                                            if(provision.scheduler === null || provision.scheduler === 'PSI'){
                                                return   <div className="d-flex align-items-center">
                                                            <div className="icon-information">
                                                                <img src={iconInformation} alt="" />
                                                            </div>
                                                            <div className="text-information">
                                                            Si tu dirección de instalación es incorrecta haz click para corregir y te llamaremos en breve
                                                            </div> 
                                                            <div className="update">
                                                            { 
                                                                provision.activeStatus != CONSTANTS.STATUS.ACTIVE ? 
                                                                <button id="btn-update-address" className="btn-tr btn-update-off" disabled>Corregir</button>
                                                                :
                                                                <button id="btn-update-address" onClick={onClick} className="btn-tr btn-update">Corregir</button>
                                                            }
                                                        
                                                            </div>
                                                        </div>
                                            }
                                            else{
                                                return null;
                                            }
                                            })()
                                        }
  
                                    </div>
                                
                                
                                </div>
                            </div>
                        </div>   
                    </div>

      
                </div>

            
            </div>
        )
    }
}

export default AddressWeb;