import * as averiaActions from '../actions/averia.actions';

const INIT_STATE = {
    faultTimer: null,
    faultPhone: null
}

export default function averiaReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case averiaActions.SET_FAULT_TIMER:
            return { ...state, faultTimer: action.payload }
        case averiaActions.SET_FAULT_PHONE:
            return { ...state, faultPhone: action.payload }
        case averiaActions.SET_FAULT_RESET:
            return { ...state, faultTimer: null, faultPhone: null }
        default:
            return state;
    }
}