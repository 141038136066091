import React, { Component } from 'react';

import './BoxProduct.scss';
import { CONSTANTS } from '../../../utils/Constants';

import iconTrioMov from '../../../resources/svg/list/icon-trio-empty.svg';
import iconTools from '../../../resources/svg/list/iconFault.svg';
import iconArrow from '../../../resources/svg/list/icon-arrow.svg';


export default class BoxProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProvision: this.props.order.activityType.toLowerCase() == CONSTANTS.PRODUCT.PROVISION_TYPE ? true : false,
            isCanceled: this.props.order.activeStatus == CONSTANTS.STATUS.CANCEL || this.props.order.activeStatus == CONSTANTS.STATUS.CAIDA || this.props.order.activeStatus == CONSTANTS.STATUS.CANCELADA_ATIS,
            isAttended: this.props.order.activeStatus == CONSTANTS.STATUS.COMPLETE || this.props.order.activeStatus == CONSTANTS.STATUS.TRIAJE_LIQUIDADO,
            status: this.props.order.activeStatus == CONSTANTS.STATUS.COMPLETE ? 
                'Atendido' : this.props.order.activeStatus == CONSTANTS.STATUS.TRIAJE_LIQUIDADO ?
                'Atendido' : this.props.order.activeStatus == CONSTANTS.STATUS.CANCEL ?
                'Cancelado' : this.props.order.activeStatus == CONSTANTS.STATUS.CAIDA ? 
                'Cancelado' : this.props.order.activeStatus == CONSTANTS.STATUS.CANCELADA_ATIS ? 
                'Cancelado' : 'En proceso'
        }
    }

    getSchedule = (order) =>{
        this.props.eventGetSchedule(order);
    }

    getShortName = () => {
        let productName = this.props.order.productName;
        if(this.props.order.activityType.toLowerCase() === CONSTANTS.PRODUCT.PROVISION_TYPE){
            if(this.props.order.productName.length > 19){
                productName = `${this.props.order.productName.substring(0,20)}****`;
            }
        }
        return productName;
    }

    render() {
        const { order } = this.props
        return (
            
            <div className="box-product">
                <div className="margin-product">
                    <div className = "item-product">
                        
                        <p className= {`text-head ${this.state.isProvision ? '' : 'fault'} `}>
                            {this.state.isProvision ? 'NUEVO PEDIDO' : 'AVERÍA REGISTRADA'}
                        </p>
                            
                        <div className="info d-flex">
                            <div className="icon">
                                {(() => {
                                    if(this.state.isProvision){    
                                        return <img id="img-icon-boxproduct" src={iconTrioMov} alt="" /> 
                                    }else{
                                        return <img id="img-icon-boxproduct" src={iconTools} alt="" /> 
                                    }

                                })()}
                            </div>
                            <div className="product">
                                <p id="lbl-name-boxproduct" className="name">{this.getShortName()}</p>
                                <div className="d-flex">
                                    <p className="subtitle">Estado: </p>
                                    <p className={`status ${this.state.isAttended ? 'attended' : this.state.isCanceled ? 'canceled' : ''} `}>{this.state.status}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fun">
                        <div className="d-flex press">
                            <p id="lbl-getschedule-boxproduct" onClick={()=>this.getSchedule(order)} className="btn-tz btn-follow pointer"> {this.state.isProvision ? 'VER PEDIDO' : 'VER AVERÍA'} </p>
                            <img id="img-icon-arrow" src={iconArrow} alt="" />            
                        </div>
                    </div>

                </div>

            </div>

        )

    }

}

