import React, { Component } from 'react';
import iconInternet from './../../../resources/svg/home/iconInternet.svg';
import iconTV from './../../../resources/svg/home/iconTV.svg';
import iconFijo from './../../../resources/svg/home/iconFijo.svg';
import { connect } from 'react-redux';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import './DetailOrder.scss';

class DetailOrder extends Component{

    constructor(props){
        super(props);
        this.state = {

        }
    }
    render(){
        
        return (
            <div id="detail-order">
                <div className="wrp-detail-order">
                    <p id="lbl-title-detail" className="title">Tu pedido incluye:</p>
                    <div className="box-detail d-flex">

                        {
                            this.props.orderDetail.components.map((element, key) => {
                                //console.log("elementProducto",element);
                                if(element.name == "TV"){
                                    return (                    
                                        <div key={key} className="item-product d-flex">
                                            <div className="icon">
                                                <img id="img-tv-detail" src={iconTV} alt=""/>
                                            </div>
                                            <div className="info">
                                                <p id="lbl-tvname-detail" className="name">{element.title}</p>
                                                <p id="lbl-tvdesc-detail" className="desc">{element.description}</p>
                                            </div>
                                        </div>
                                    )
                                }
                                else if(element.name == "INTERNET") {
                                    return (
                                        <div key={key} className="item-product d-flex">
                                            <div className="icon">
                                                <img id="img-internet-detail" src={iconInternet} alt=""/>
                                            </div>
                                            <div className="info">
                                                <p id="lbl-internetname-detail" className="name">{element.title}</p>
                                                <p id="lbl-internetdesc-detail" className="desc">{element.description}</p>
                                            </div>
                                        </div>    
                                    )
                                }
                                else {
                                    return(
                                        <div key={key} className="item-product d-flex">
                                            <div className="icon">
                                                <img id="img-phone-detail" src={iconFijo} alt=""/>
                                            </div>
                                            <div className="info">
                                                <p id="lbl-phonename-detail" className="name">{element.title}</p>
                                                <p id="lbl-phonedesc-detail" className="desc">{element.description}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }

                      
                        

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderDetail: state.security.orderDetail, 
        
    }
}


const mapDispatchToProps = dispatch => {
    return {
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DetailOrder);