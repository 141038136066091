import React, { useState } from 'react';
import Iframe from 'react-iframe'

import Button from '@material-ui/core/Button';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import IconButton from '@material-ui/core/IconButton';

import './Frame.scss';
import Loading from '../common/elements/Loading';

export default function Frame({url, onFullMap, isFullMap, openTooltip}) {
        const [loading, setLoading] = useState(true);
        const hideSpinner = () => {
           setLoading(false)
        };
        return (
        
            <div id="frame-map">
               
                <div className="wrp-frame-map">
                <Loading  id="loading-token" showLoading={loading} />
                    {
                        (() => {
                            if(isFullMap){
                                return <div className="box-margin"/>
                            } else{
                                return null;
                            }
                        })()
                    }
                    <div className="box-button">
                    
                        <IconButton 
                            aria-label="delete" 
                            className="button-full"
                            position="absolute"
                            onClick={onFullMap}
                            >
                            {
                                isFullMap ?
                                <FullscreenExitIcon fontSize="large" />
                                :
                                <FullscreenIcon fontSize="large" />
                            }                        
                        </IconButton>
                        
                        <span class={`tooltiptext ${openTooltip ? 'tooltip-visible':'tooltip-hidden'} `}>
                            {isFullMap ? 'Reducir' : 'Expandir'}
                        </span>
                        
                    </div>
                        
                    <Iframe 
                        url={url}
                        width="max-width"
                        height="max-height"
                        id="myId"
                        className= {`${isFullMap ? 'frame-url-all':'frame-url'}`}  
                        onLoad = {hideSpinner}
                        display="initial"
                        position="relative"
                    />
    
                </div>
    
            </div>
    
        )
    
    
}