import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Tracking.scss';
import * as provisionActions from '../../../store/actions/provision.actions';
import * as commonSelectors from '../../../store/selectors/common.selectors';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import { CONSTANTS } from '../../../utils/Constants';
import iconIntoa from '../../../resources/svg/home/iconIntoa.svg';
import iconIntoaSchedule from '../../../resources/svg/home/iconIntoaSchedule.svg';
import iconTechnician from '../../../resources/svg/home/iconTechnician.svg';
import iconWoCompleted from '../../../resources/svg/home/iconWoCompleted.svg';
import iconWoPrestar from '../../../resources/svg/home/iconWoprestar.svg';
import iconWoInit from '../../../resources/svg/home/tracking/iconWoInit.svg';
import iconCancel from '../../../resources/svg/home/iconCancel.svg';
import iconPendingPayment from '../../../resources/svg/home/tracking/iconPendingPayment.svg'
import iconPaidOut from '../../../resources/svg/home/tracking/iconPaidOut.svg'
import iconPending from '../../../resources/svg/home/iconPending.svg';
import iconPreNotDone from '../../../resources/svg/home/tracking/iconPreNotDone.svg';
import iconPreNotDoneT from '../../../resources/svg/home/tracking/iconPreNotDoneTraza.svg';
import iconNotDoneReeschedule from '../../../resources/svg/home/iconNotDoneReeschedule.svg';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

class Tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusText:'',
            statusIcon:'',
            statusBorder: '',
            triangleBorder: ''
        }

    }

    componentDidMount = () => {

        if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART) {
            if(this.props.orderDetail.woPreStart.availableTracking == true){
                window.dataLayer.push({
                    event: 'atm.event',
                    eventCategory: 'provision detalle',
                    eventAction: 'view',
                    eventLabel: 'ver recorrido del tecnico',
                    valorElemento: this.props.orderDetail.activeStatus,
                    correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
                    nonInteraction: '1',
                    //datos genéricos
                    userId: this.props.user.documentNumber,
                    tipoCliente: this.props.user.documentType.toLowerCase(),
                    numero: this.props.orderDetail.customer.phoneNumber,
                    productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : '',
                    hasTrackingUrl: this.props.orderDetail.woPreStart.availableTracking
                });
            }
        }
        
        if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ACTIVE) {
            if(this.props.technician == null && this.props.schedule == null){
                this.setState({
                    statusIcon: iconPending,
                })
            }else if(this.props.technician == null && this.props.schedule != null){
                this.setState({
                    statusIcon: iconIntoaSchedule,
                })
            }
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE){
            this.setState({
                statusIcon: iconIntoaSchedule,
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.INGRESADO){
            this.setState({
                statusIcon: iconIntoaSchedule,
            })
        }else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CANCEL){
            this.setState({
                statusIcon: iconCancel,
                statusBorder: 'border-red',
                triangleBorder: 'red-side'
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOT_DONE
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CAIDA){
            this.setState({
                statusIcon: iconCancel,
                statusBorder: 'border-red',
                triangleBorder: 'red-side'
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ADDRESS_CHANGED){
            this.setState({
                statusIcon: iconIntoaSchedule,
                statusBorder: 'opacity-box',
                triangleBorder: 'opacity-side'
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART){
            this.setState({
                statusIcon: iconWoPrestar,
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_INIT){
            this.setState({
                statusIcon: iconWoInit,
            })
        }else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.COMPLETE) {
            this.setState({
                statusIcon: iconWoCompleted,
                statusBorder: 'border-green',
                triangleBorder: 'green-side'
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE_PAGO) {
            this.setState({
                statusIcon: iconIntoaSchedule
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PAGADO) {
            this.setState({
                statusIcon: iconPendingPayment,
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.FINALIZADO){
            this.setState({
                statusIcon: iconWoCompleted,
                statusBorder: 'border-green',
                triangleBorder: 'green-side'
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TERMINADA){
            this.setState({
                statusIcon: iconWoCompleted,
                statusBorder: 'border-green',
                triangleBorder: 'green-side'
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE){
            this.setState({
                statusIcon: iconPreNotDone,
                statusBorder: 'border-red',
                triangleBorder: 'red-side'
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA){
            this.setState({
                statusIcon: iconPreNotDoneT
            })
        }else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOTDONE_TRAZA){
            this.setState({
                statusIcon: iconNotDoneReeschedule,
                statusBorder: 'border-red',
                triangleBorder: 'red-side'
            })
        }else {
            this.setState({
                statusIcon: iconCancel,
            })
        }
        
    }

    clickTracker = () => {

        this.props.setUpdateShowLocation().then(resp => {
            console.log("resp UpdateShowLocation", resp);
        });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: 'ver ubicación',
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.props.onOpenMapView()

    }

    render() {
        return (
            <div id="tracking">
                <div className={`triangle ${this.state.triangleBorder}`}></div>
                <div className={`box-tracking ${this.state.statusBorder}`}>

                        <div className="info d-flex">
                            
                            <img id="img-status-tracking" className="icon" src={this.state.statusIcon} alt="" />

                            <p id="lbl-status-tracking" className="text">
                                {
                                    this.props.orderDetail.frontSpeech
                                }
                            </p>

                        </div>
                    
                </div>

            </div>

        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
        schedule: state.schedule.scheduleData,
        technician: state.schedule.scheduleTechnician
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUpdateShowLocation: () => dispatch(provisionActions.setUpdateShowLocation())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tracking);