import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Loading from '../../common/elements/Loading'
import Alert from '../../modals/Alert'
import './Add.scss';
import iconAdd from './../../../resources/svg/contacts/iconAdd.svg';
import iconRemove from './../../../resources/svg/contacts/iconRemove.svg';

import * as securityActions from '../../../store/actions/security.actions';
import * as scheduleActions from '../../../store/actions/schedule.actions';
import * as provisionActios from '../../../store/actions/provision.actions';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import * as commonSelectors from '../../../store/selectors/common.selectors';

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validate: false,
            contacts: [],
            showLoading: false,
            openAlert: false,
            errorMsj: '',
            nro_maximo_contactos: (this.props.orderDetail.scheduler == 'PSI' || this.props.orderDetail.scheduler == null) ? 4 : 2
        }
    }
    onCloseAlert = () => {
        this.setState({
            openAlert: false
        })
    }
    componentDidMount =()=>{
        console.log("Fasd");
        console.log(this.state.nro_maximo_contactos)
        if(this.props.orderDetail.contacts !== null){
            if(this.props.orderDetail.contacts.length > 0){
                let contacts = this.props.orderDetail.contacts.map(item => {
                    return {
                        fullName: item.fullName,
                        phoneNumber: item.phoneNumber, 
                        nameMsg: null, 
                        phoneMsg: null
                    }
                })
                this.setState({contacts: contacts})
                this.validateRequered();
            } else {
                this.setState({contacts: [{fullName: '', phoneNumber: '', nameMsg: null, phoneMsg: null }]})
            }
            
        } else {
            this.setState({contacts: [{fullName: '', phoneNumber: '', nameMsg: null, phoneMsg: null }]})
        }
      
    }
    validateRequered = () =>{
        let validate = this.state.contacts.filter(item => item.fullName.length == 0 || item.phoneNumber.length == 0 || item.phoneMsg !== null);
        
        this.setState({
            validate: validate.length > 0 ? false: true
        });
    }
    handleChange = (event) => {
       // alert(event.target.name);
        let contacts = this.state.contacts;
        let getName = event.target.name.split('_');
        if(getName[0] == 'name'){
          
            if(event.target.value.match("^[a-zA-Z ñÑ zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]*$") != null){
                console.log('entro');
                contacts[Number(getName[1])].nameMsg = null;
                contacts[Number(getName[1])].fullName = event.target.value.replace(/\s\s+/g, ' ')
            }

           
            
        } else {
            
            if(/^([0-9])*$/.test(event.target.value)){
                if(Number(event.target.value.slice(0, 1)) == 0){
                    contacts[Number(getName[1])].phoneMsg = 'Ingrese un número valido';
                }else if(event.target.value.length < 9){
                    contacts[Number(getName[1])].phoneMsg = 'Teléfono debe tener 9 digitos';
                } else {
                    contacts[Number(getName[1])].phoneMsg = null;
                }
                
                contacts[Number(getName[1])].phoneNumber = event.target.value.slice(0, 9)
            }
            
        }
        
        this.setState({contacts: contacts} )
        this.validateRequered();
    }
    onBlur = (event) =>{
        let contacts = this.state.contacts;
        let getName = event.target.name.split('_');
        if(getName[0] == 'name'){
            
            let name = event.target.value.trim().replace(/\s\s+/g, '');
            if(name.length === 0){
                contacts[Number(getName[1])].nameMsg = 'Nombre obligatorio';
            }
            contacts[Number(getName[1])].fullName = name;
           
        } else {
            if(event.target.value.length === 0){
                contacts[Number(getName[1])].phoneMsg = 'Teléfono obligatorio';
            } else if(event.target.value.length < 9){
                contacts[Number(getName[1])].phoneMsg = 'Teléfono debe tener 9 digitos';
            }
            
            
        }
        
        this.setState({contacts: contacts});
        this.validateRequered();

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'datos de contacto',
            eventAction: 'click',
            eventLabel:  getName[0] == 'name' ? 'nombres y apellidos': 'celular',
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.orderDetail.customer.phoneNumber,
             productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
        
    }

    addContact = () =>{
        let contacts = this.state.contacts;
        contacts.push({fullName: '', phoneNumber: '', nameMsg: null, phoneMsg: null })
        this.setState({contacts: contacts} )

        this.validateRequered();

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'datos de contacto',
            eventAction: 'click',
            eventLabel:  'añadir contacto',
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.orderDetail.customer.phoneNumber,
             productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });


        this.props.onAddClick();
    }

    removeContact = (index) =>{
        let contacts = this.state.contacts;
       // alert(event);
       this.setState({contacts: contacts.filter((item, key) => key !== index)}, () =>{
        this.validateRequered();
       } )
       
    }

    saveContacts = () =>{
        
        let contacts = this.state.contacts.map(item =>{
            return {
                fullName: item.fullName,
                phoneNumber: item.phoneNumber
            }
        });

        console.log(contacts);
        let request = {
            contacts: contacts,
            email: this.props.orderDetail.customer.mail,
            psiCode: this.props.orderDetail.xaIdSt,
            holderWillReceive: false
        }
        this.setState({showLoading: true});
        
        this.props.setContactInfoUpdateFaultOrProvision(request).then(resp =>{
            this.setState({showLoading: false});
            if(resp.status == 'OK'){
                
                //this.props.provisionUpdate(resp.data);
                this.props.setScheduleContactEdit(false);

            } else {
                this.setState({openAlert: true,errorMsj: resp.message})
                
            }
            
        })

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'datos de contacto',
            eventAction: 'click',
            eventLabel:  'guardar',
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.orderDetail.customer.phoneNumber,
             productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
    }

    render() {
        return (
           
            <div id="contacts-add">
                <Loading showLoading={this.state.showLoading}/>
                <div className="wrp-add">
                    
                    <h4 id="lbl-title-contactsadd" className="title-add">Ingresa los datos del contacto</h4>
                    
                   { this.state.contacts.map((item, key) =>{

                        return (
                        <div className="box-contacts" key={key}>
                            <div className="wrp-head d-flex">
                                <div id="lbl-subtitle-contactsadd" className="sub-title-add">Contacto { key + 1 }</div>

                               { this.state.contacts.length > 1 && <div className="act-remove" onClick={() => this.removeContact(key)} index={key}>
                                    <img id="img-icon-contactsadd" src={iconRemove} alt=""  />
                                </div>}
                            
                            </div>
                            <div className="wrp-inputs">
                                <div className="wrp-name">
                                    <FormControl className="test-error">
                                        <InputLabel className={ `label-tr ${item.nameMsg !== null ? 'Mui-error': ''} `}  htmlFor="component-error">Nombres y Apellidos</InputLabel>
                                        <Input
                                            id={'input-contact-name-' + key}
                                            type={'text'}
                                            inputProps={{maxLength:40}}
                                            value={item.fullName}
                                            name={'name_'+key}    
                                            className={item.nameMsg !== null ? 'Mui-error': ''}
                                            onChange={this.handleChange}
                                            onBlur ={this.onBlur}
                                            aria-describedby="component-error-text"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {/* <img src={iconPen} alt=""/> */}
                                                    <EditIcon />
                                                </InputAdornment>
                                            }
                                        />
                                        {item.nameMsg !== null && <FormHelperText className="Mui-error" id="component-error-text">{item.nameMsg}</FormHelperText>}
                                    </FormControl>
                                </div>
                                <div className="wrp-phone">
                                    <FormControl className="">
                                        <InputLabel className={`label-tr ${item.phoneMsg !== null ? 'Mui-error': ''}`}  htmlFor="standard-adornment-password">Celular</InputLabel>
                                        <Input
                                            id={'input-contact-phone-' + key}
                                            type={'text'}
                                            name={'phone_'+key}
                                            value={item.phoneNumber}
                                            className={item.phoneMsg !== null ? 'Mui-error': ''}
                                            onBlur ={this.onBlur}
                                            onChange={this.handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <EditIcon />
                                                </InputAdornment>
                                            }
                                        />
                                        {item.phoneMsg !== null && <FormHelperText className="Mui-error" id="component-error-text">{item.phoneMsg}</FormHelperText>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>)
                        })
                    }
                    
                   
                    <div className="act-add ">

                        {this.state.contacts.length < this.state.nro_maximo_contactos && <span className="pointer d-flex-inline" onClick={this.addContact}>
                            <img id="img-add-contactsadd" src={iconAdd} alt=""/>
                            <div id="lbl-add-contactsadd" className="name">Añadir contacto</div>
                        </span>}
                        
                        
                    </div>
                    <div className="act">
                            
                            { !this.state.validate && <Button id="btn-save-contactsadd" variant="contained" color="secondary" className="btn-tz btn-save-contact"  disabled>
                                Guardar
                            </Button>  }

                            {this.state.validate  && <Button id="btn-save-contactsadd" variant="contained" color="secondary" className="btn-tz btn-save-contact" 
                                
                              onClick = { this.saveContacts }
                            >
                                Guardar
                            </Button>  }

                    </div>

                </div>
                <Alert openAlert={this.state.openAlert} errorMsj={this.state.errorMsj} onClose={this.onCloseAlert} onAccept={this.onCloseAlert} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail, 
    }
}


const mapDispatchToProps = dispatch => {
    return {
        //provisionUpdate: (data) =>dispatch(provisionActios.provisionUpdate(data)),
        provisionUpdate: (data) =>dispatch(securityActions.provisionUpdate(data)),
        setScheduleContactEdit: (flag) => dispatch(scheduleActions.setScheduleContactEdit(flag)),
        setContactInfoUpdateFaultOrProvision: (request) => dispatch(securityActions.setContactInfoUpdateFaultOrProvision(request)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Add);