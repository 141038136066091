import * as orderActions from './../actions/provision.actions';

const INIT_STATE = {
    provisionData: null,
    provisionIdSelected: null,
    provisionTimer: null,
    provisionPhone: null,

}

export default function userReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case orderActions.SET_PROVISION_DATA:
            return { ...state, provisionData: action.payload }
        case orderActions.SET_PROVISION_TIMER:
            return { ...state, provisionTimer: action.payload }
        case orderActions.SET_PROVISION_PHONE:
            return { ...state, provisionPhone: action.payload }
        case orderActions.SET_PROVISION_ID_SELECTED:
            return { ...state, provisionIdSelected: action.payload }
        case orderActions.SET_PROVISION_RESET:
            return { ...state, provisionData: null, provisionIdSelected: null, provisionTimer: null, provisionPhone: null }
        default:
            return state;
    }
}