import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Triage.scss';
import iconChevronDown from '../../../resources/svg/home/detail/iconChevronDown.svg';

import * as provisionSelectors from '../../../store/selectors/provision.selectors';

class Triage extends Component {

    render() {
        
        return (<div id="triage">
                    <div className="wrp-triage">
                        <div className="container">
                            <div className="box-full-returned">
                                <div className="head-message">
                                    <p id="lbl-subtitle" className="subtitle">{this.props.orderDetail.subMotive}</p>
                                </div>                                
                                <div className="item-returned">
                                    <div className="d-flex">
                                            <img id="img-icon-returned" src={iconChevronDown} alt=""/>
                                            <p className="item-message">{this.props.orderDetail.subMotive2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail //provisionSelectors.getOrderById(state.security.ordersIdSelected, state.security.ordersData), 
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Triage);