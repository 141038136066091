import React, { Component } from 'react';
import './Onboarding.scss';
import Slider from "react-slick";

import { CONSTANTS } from '../../utils/Constants';

import iconMovistar from '../../resources/svg/onboarding/logoMovistarWhite.svg';
import iconReview from '../../resources/svg/onboarding/iconReview.svg';
import iconFollow from '../../resources/svg/onboarding/iconFollow.svg';
import iconFault from '../../resources/svg/onboarding/iconFault.svg';
import iconAgenda from '../../resources/svg/onboarding/iconAgenda.svg';
import { animateScroll as scroll } from 'react-scroll';
import { throwStatement } from '@babel/types';

class Onboarding extends Component {

    constructor(props){
        super(props);

        this.state = {
            settings : this.getSettings()
        }
    }

    componentDidMount() {
        scroll.scrollToTop();

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'onboarding',
            path: this.props.history.location.pathname,
            nonInteraction: '1',
            //datos genéricos
            userId: '',
            tipoCliente: '',
            numero: '',
            productos: '' 
        });


    }


    getSettings = () => {
        return {
            arrows:false,
            dots: true,
            dotsClass: "slick-dots slick-thumb",
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            beforeChange: (current, next) => {
                let eventLabel = '';
                if(next == 0 ){
                    eventLabel = 'revisa tu pedido';
                } else if(next == 1){
                    eventLabel = 'sigue tu pedido';
                } else if(next = 2){
                    eventLabel = 'agenda y reagenda';
                }

                window.dataLayer.push({
                    event: 'atm.event',
                    eventCategory: 'onboarding',
                    eventAction: 'click',
                    eventLabel:  eventLabel,
                    nonInteraction: '1',
                    //datos genéricos
                    userId: '',
                    tipoCliente: '',
                    numero: '',
                    productos: '' 
                }); 
            }
        }
    }

    
    goLogin = () => {
        this.props.history.push({
            pathname: CONSTANTS.PATH.LOGIN
        })
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'onboarding',
            eventAction: 'click',
            eventLabel:  'saltar',
            nonInteraction: '1',
            //datos genéricos
            userId: '',
            tipoCliente: '',
            numero: '',
            productos: '' 
        });
    }

    render() {
        return (
            <>
            <section id="onboarding">
                <div className="container">
                    <div className='boxGroup'>
                        <div>
                            <img className="logo align-self-center" src={iconMovistar} />
                            <p id="lbl-title-onboarding" className="titleSingle">Mi Pedido<br/>Movistar</p>
                        </div>
                        <Slider className="slickSlider" {...this.state.settings}>
                            <div className="boxDescription">
                                <img className="icon" src={iconAgenda}/>
                                <div>
                                    <p id="lbl-subtitle1-onboarding" className="subTitleSingle">Nuevo pedido</p>
                                    <p id="lbl-subtitle2-onboarding" className="descSingle">Agenda o reagenda la instalación de tu<br/>pedido e indícanos quién atenderá al técnico</p>
                                </div>
                            </div>
                            <div className="boxDescription">
                                <img className="icon" src={iconFault}/>
                                <div>
                                    <p id="lbl-subtitle3-onboarding" className="subTitleSingle">Averías</p>
                                    <p id="lbl-subtitle4-onboarding" className="descSingle">Conoce el estado de tu reporte de avería<br/>e indícanos quién atenderá al técnico</p>
                                </div>
                            </div>
                            <div className="boxDescription">
                                <img className="icon" src={iconFollow}/>
                                <div>
                                    <p id="lbl-subtitle5-onboarding" className="subTitleSingle">Conoce a tu técnico</p>
                                    <p id="lbl-subtitle6-onboarding" className="descSingle">Revisa los datos del técnico<br/>y sigue su ruta en tiempo real</p>
                                </div>
                            </div>
                        </Slider>
                        <p id="btn-skip-onboarding" className="buttonTitle" onClick={this.goLogin}>SALTAR</p>
                    </div>
                </div>
            </section>
            </>
        )
    }
}

export default Onboarding;