import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import iconTecnico from '../../../resources/svg/home/iconTec.svg';
import iconCalendarMini from '../../../resources/svg/home/iconCalendar.svg';
import iconTimeMini from '../../../resources/svg/home/iconClock.svg';
import iconArrowRight from '../../../resources/svg/home/iconArrowRight.svg';

import { CONSTANTS } from '../../../utils/Constants';
import * as commonSelectors from '../../../store/selectors/common.selectors';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import moment from 'moment';
import 'moment/locale/es';

class Techical extends Component{
  
    constructor(props) {
        super(props);
        this.state = {
            pantalla:{height: window.innerHeight,width: window.innerWidth}
        }

    }
    fullNameTecCapitalize = () =>{
        return this.props.orderDetail.woPreStart.fullName.split(' ').map(item =>(
             item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() + ' '
        ));

    }

    fullNameDateInstall = () =>{
        let scheduleDate = this.props.schedule.scheduleDate.split('-');

       let nameDay = moment(this.props.schedule.scheduleDate,'YYYY-MM-DD').format('dddd');
        
       let getNameMonth = commonSelectors.getMonthName(scheduleDate[1]);
        return `Hoy ${nameDay.toLowerCase()} ${scheduleDate[2]} de 
        ${getNameMonth.toLowerCase()} del ${scheduleDate[0]}
        `;
        
    }

    typeDocument = () => {
        let countDoc = this.props.orderDetail.woPreStart.documentNumber.length;
        return countDoc === 8 ? 'DNI': 'N° de Documento'

    }

    getTimeSchedule = () => {
        let turn = '';
        if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA){
            if (this.state.pantalla.width <= 540) {
                console.log("entr cel")
                turn = `Hoy en el turno ${this.props.schedule.scheduleRange.toLowerCase() === 'am' ? 
                'mañana' : 'tarde'} de `
            }else{
                console.log("entr desktop")
                turn = `Turno ${this.props.schedule.scheduleRange.toLowerCase() === 'am' ? 
                'mañana' : 'tarde'} de `
            }
          
        }else{
            // turn = 'Turno: De ' + this.props.schedule.scheduleTimeRange.replace(/:00/gi, '')
            turn = 'Turno: De '
        }
        return turn;
    }

    getTimeValue = () => {
        return this.props.schedule.scheduleTimeRange.replace(/:00/gi, '');
    }
    handleResize(){
        this.setState({
            pantalla:{
                height: window.innerHeight,
                width: window.innerWidth
            }
        })
        
    }
    componentDidMount(){
        window.addEventListener('resize',this.handleResize.bind(this))
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.handleResize.bind(this));
      }
    
    render(){
        return (
            <div className="box-border">
            <div className="box-technical">
                <div id="lbl-head-technical" className="text-head">
                    TÉCNICO ASIGNADO
                </div>
                <div className="wrp-items">
                    <div className = {`info-tec ${this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE && 'wo-line'}`}>
                        <div className="icon">
                            <img id="img-icon-technical" className="img-tec" src={iconTecnico} alt="" />
                        </div>
                        <div className="text">
                            <p id="lbl-fullname-technical" className="full-name">{this.fullNameTecCapitalize()}</p>
                            
                            <div className="doc"> <span  id="lbl-document-technical" className="doc-txt1">{ this.typeDocument()}:</span> <span id="lbl-documentnumber-technical" className="doc-txt2">{this.props.orderDetail.woPreStart.documentNumber}</span></div>
                        </div>
                    </div>
                    {
                        this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE ? 
                            <div/>
                        : this.props.schedule ?
                            <div className="date-install">
                                <p id="lbl-title-technical" className="title">Fecha de instalación:</p>
                                {/* { this.props.orderDetail.activeStatus != CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA &&
                                    <div className="wrp1 d-flex">
                                        <img id="img-calendar-technical" src={iconCalendarMini} alt="" />
                                        <p id="lbl-date-technical" className="date-txt1">{this.fullNameDateInstall()}brrrrrr</p>
                                    </div> 
                                } */}
                                <div className="wrp1-web d-flex">
                                    <img id="img-calendar-technical" src={iconCalendarMini} alt="" />
                                    <p id="lbl-date-technical" className="date-txt1">{this.fullNameDateInstall()}</p>
                                </div>  
                                <div className="d-flex">
                                    <img id="img-time-technical" src={iconTimeMini} alt="" />
                                    {/* <p id="lbl-time-technical" className="date-txt2">{this.getTimeSchedule()}</p> */}
                                    <p id="lbl-time-technical" className="date-txt2">{this.getTimeSchedule()}<span>{this.getTimeValue()}</span></p>
                                </div>
                            </div> 
                        :
                            <div className="wrp-name">
                                <img className={isMobile ? null : "icon"} src={iconArrowRight} alt="" />
                                <p className="name">
                                    {this.props.orderDetail.productName}
                                </p>
                            </div>
                    }
                </div>
            </div>

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
        schedule: state.schedule.scheduleData,
        technician: state.schedule.scheduleTechnician
    }
}


const mapDispatchToProps = dispatch => {
    return {
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Techical);