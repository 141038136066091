import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import * as scheduleActions from '../../../store/actions/schedule.actions';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import * as commonSelectors from '../../../store/selectors/common.selectors';
import { CONSTANTS } from '../../../utils/Constants';
import './Edit.scss';

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    contactsEdit = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'datos de contacto',
            eventAction: 'click',
            eventLabel:  'editar',
            valorElemento: this.props.provision.activeStatus,
            correoElectronico:  this.props.provision.customer.mail != null ? commonSelectors.sha256(this.props.provision.customer.mail) : "",
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.provision.customer.phoneNumber,
             productos: this.props.provision.productName ? this.props.provision.productName.toLowerCase().trim(): ''
        });
        // this.props.onEdit();
        this.props.setScheduleContactEdit(true);
    }
    render() {

        return (
            <div id="contacts-edit">

                <div className="wrp-edit">
                    <p id="lbl-title-contactsedit" className="title-edit">
                        Contactos que recibirán al técnico
                    </p>
                    <div className="box-contacts">
                        <div>
                            {
                                this.props.provision.contacts.map((item, key) => {
                                    return (
                                        <div className="contact-1ab" key={key}>
                                            <p id="lbl-subtitle-contactsedit" className="contact-title">Contacto {key + 1}</p>
                                            <div className="wrp-info">
                                                <div id="lbl-fullname-contactsedit" className="text name">
                                                    {item.fullName}
                                                </div>
                                                <div id="lbl-slash-contactsedit" className="text pipe">
                                                    /
                                                </div>
                                                <div id="lbl-phone-contactsedit" className="text phone">
                                                    Celular: {item.phoneNumber}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }



                        </div>
                        <div className="act">
                            {this.props.provision.activeStatus != CONSTANTS.STATUS.ACTIVE ?

                                <Button id="btn-edit-contactsedit" variant="contained" color="secondary" className="btn-tz btn-edit-contact" disabled>
                                    Editar
                                </Button> :
                                <Button id="btn-edit-contactsedit" variant="contained" color="secondary" className="btn-edit-contact pointer" onClick={this.contactsEdit}>
                                    Editar
                            </Button>
                            }

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        provision: state.security.orderDetail, //provisionSelectors.getOrderById(state.security.ordersIdSelected, state.security.ordersData), 
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setScheduleContactEdit: (flag) => dispatch(scheduleActions.setScheduleContactEdit(flag)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Edit);