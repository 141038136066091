import * as securityActions from "../actions/security.actions"

const INIT_STATE = {
    userData: null,
    userSource: '',
    userLoginToken: null,
    userValidSMS: false,
    userTimer: '',
    userWebView:'',
    userReschedulePolicy:'',

    orderType: null,
    orderList: null,
    orderToken: null,
    orderDetail: null,
    ordersIdSelected: null,

    userLoginTime: null
}

export default function SecurityReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case securityActions.SET_SECURITY_DATA:
            return { ...state, userData: action.payload }
        case securityActions.SET_SECURITY_LOGIN_TOKEN:
            return { ...state, userLoginToken: action.payload }
        case securityActions.SET_SECURITY_SOURCE:
            return { ...state, userSource: action.payload }
        case securityActions.SET_SECURITY_RESET:
            return { ...state, userData: null, userSource: '', userLoginToken: null, userValidSMS: false, userTimer: '', userWebView:'', userReschedulePolicy:'',orderList: null, ordersIdSelected: null, orderDetail: null, orderToken: null, orderType: null, userLoginTime: null }
        case securityActions.SET_SECURITY_TIMER:
            return { ...state, userTimer: action.payload}
        case securityActions.SET_SECURITY_WEBVIEW:
            return { ...state, userWebView:action.payload}
        case securityActions.SET_SECURITY_RESCHEDULE_POLICY:
            return { ...state, userReschedulePolicy: action.payload}

        case securityActions.SET_ORDER_TYPE:
            return { ...state, orderType: action.payload }
        case securityActions.SET_ORDER_LIST:
            return { ...state, orderList: action.payload }
        case securityActions.SET_ORDER_TOKEN :
            return { ...state, orderToken: action.payload }
        case securityActions.SET_ORDER_DETAIL:
            return { ...state, orderDetail: action.payload}
        case securityActions.SET_ORDER_DETAIL_RESET:
            return { ...state, orderDetail: null}

        case securityActions.SET_SECURITY_LOGIN_TIME :
            return { ...state, userLoginTime: action.payload }

        case securityActions.SET_ORDERS_ID_SELECTED:
            return { ...state, ordersIdSelected: action.payload }
        case securityActions.SET_SECURITY_RESET_SELECTED_ORDER:
            return { ...state, ordersIdSelected: null }

        // token
        case securityActions.SET_AUTH_TOKEN:
            return { ...state, authToken: action.payload };
        // orderCode
        case securityActions.SET_ORDER_CODE:
            return { ...state, orderCode: action.payload };
        // documentNumber
        case securityActions.SET_DOC_NUMBER:
            return { ...state, documentNumber: action.payload };
        
        // jwt authorization validate user
        case securityActions.SET_AUTH_JWT_LOGIN:
            return { ...state, jwtAuthUser: action.payload };
        default:
            return state;
    }
}