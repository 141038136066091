import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import Address from '../../address/Address';
import Contacts from './../../contacts/Contacts';
import DetailOrder from './../product/DetailOrder';
import * as commonSelectors from '../../../store/selectors/common.selectors';

import './TabHome.scss'




function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function TabHome({provision, onTabClick}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
        let eventLabel = '';
        if(newValue == 0){
            eventLabel = 'datos de contacto'
        } else if(newValue == 1){
            eventLabel = 'dirección de instalación'
        } else {
            eventLabel = 'detalle de pedidod'
        }
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: eventLabel,
            correoElectronico: provision.customer.mail != null ? commonSelectors.sha256(provision.customer.mail) : "",
            idPedido: provision.xaRequest,
            nombrePedido: provision.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',

            //datos genéricos
            userId: provision.customer.documentNumber,
            tipoCliente: provision.customer.documentType.toLowerCase(),
            numero: provision.customer.phoneNumber,
            productos: provision.productName.toLowerCase().trim()
        });

        onTabClick();
    };

    return (
        <div className="tab-home">

            <div className={classes.root}>
                <div className="container">
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab id="tab-contact-tab" label="DATOS DE CONTACTO" {...a11yProps(0)} />
                            <Tab id="tab-address-tab" label="DIRECCIÓN DE INSTALACIÓN" {...a11yProps(1)} />

                            { provision.components.length > 0 && 

                            <Tab id="tab-detail-tab" label="DETALLE DE PEDIDO" {...a11yProps(2)} />

                            }

                        </Tabs>
                    </AppBar>
                </div>
                <div className="body-tab-home">
                    <div className="container">
                        <TabPanel value={value} index={0}>
                            <Contacts 
                                onContactClick = {onTabClick}
                            />
                                
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Address
                                onAddressClick = {onTabClick}
                            />
                        </TabPanel>
                    
                        { provision.components.length > 0 && 

                        <TabPanel value={value} index={2}>
                            <DetailOrder
                                provision = {provision}
                            />
                        </TabPanel>

                        }

                    </div>
                </div>



            </div>
        </div>
    );
}