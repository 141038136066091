import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../../utils/Constants'
import { Link } from 'react-router-dom';
import './MenuBurger.scss';
import iconClose from '../../../resources/svg/menuBurger/iconClose.svg';
import iconUser from '../../../resources/svg/menuBurger/iconUser.svg';
import iconCloseArrow from '../../../resources/svg/menuBurger/iconCloseArrow.svg';

class MenuBurger extends Component {
    constructor(props) {
        super(props);
        this.state = {
           // isMenu: true
        }
    }
    componentDidMount = () =>{
       
        // this.setState({
        //     isMenu: this.props.isMenu
        // })
    }
    
    closeMenu = () => {
      this.props.openMenu();
       // this.setState({ isMenu: !this.state.isMenu })
    }
    fullNameCapitalize = () =>{
        return this.props.orderDetail.customer.name.trim().split(' ').map(item =>(
             item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() + ' '
        ));
  

    }
    capitalizeUserName = () => {
        let name = this.props.orderDetail.customer.name.trim().split(' ')[0];
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()

    }
    render() {
        const { isMenu } = this.props;
        //alert('reden');
        return (
            <div id="menu-burger">
                <div>
                    <Menu right
                        disableCloseOnEsc
                        isOpen={isMenu}
                        className="test-lazo"
                        disableOverlayClick={this.closeMenu}
                    >

                        <div className="wrp-menu">
                            <div className="mn-header d-flex">


                                <span id="lbl-hello-menu" className="greeting">Hola, </span> <span id="lbl-name-menu" className="name">{this.capitalizeUserName()}</span>


                                <span onClick={this.closeMenu} className="iconClose pointer"><img id="img-close-menu" src={iconClose} alt="" /></span>




                            </div>
                            <div className="mn-info d-flex">
                                <div className="icon">
                                    <img id="img-user-menu" src={iconUser} alt="" />
                                </div>
                                <div className="info">
                                    <div id="lbl-title-menu" className="title">Datos personales</div>
                                    <div id="lbl-fullname-menu" className="full-name">{this.fullNameCapitalize()}</div>
                                    <div className="document">
                                        <span id="lbl-document-menu" className="field">{this.props.user.documentType}:</span> <span id="lbl-documentnumber-menu" className="value">{this.props.user.documentNumber}</span>
                                    </div>
                                    <div className="phone">
                                        <span id="lbl-phone-menu" className="field">CELULAR:</span> <span id="lbl-phonenumber-menu" className="value">{this.props.orderDetail.customer.phoneNumber}</span>
                                    </div>

                                </div>

                            </div>
                            <div className="mn-exit d-flex">
                                <Link to={CONSTANTS.PATH.LOGIN} className="d-flex pointer">
                                    <div id="lbl-logout-menu" className="text">Cerrar sesión</div>
                                    <div className="icon-exit ">
                                        <img id="img-logout-menu" src={iconCloseArrow} alt="" />
                                    </div>
                                </Link>


                                {/* <span><span>Cerrar sesión</span><img src={iconCloseArrow} alt="" /></span> */}
                            </div>
                        </div>

                    </Menu>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        
        orderDetail: state.security.orderDetail,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuBurger);