import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import './Absent.scss';

import Header from '../header/Header';
import iconCheck from '../../resources/svg/absent/iconCheck.svg'
import * as commonSelectors from '../../store/selectors/common.selectors';
import { CONSTANTS } from '../../utils/Constants';

import Button from '@material-ui/core/Button';

class Absent extends Component {

    componentDidMount(){

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'confirmacion cliente ausente',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

    }

    onContinue = () => {

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'continuar cliente ausente',
            eventAction: 'click',
            valorElemento: this.props.orderDetail.activeStatus,
            idPedido: this.props.orderDetail.xaRequest,
            eventLabel: 'confirmar',
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            //fechaAgendamiento: this.props.schedule.scheduleDate ? moment(this.props.schedule.scheduleDate).format('DD/MM/YYYY') : '',
            nonInteraction: '1',
            
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : '' 
        });


        if(this.props.orderType === CONSTANTS.PRODUCT.PROVISION_TYPE){
            this.props.history.push({
                pathname: CONSTANTS.PATH.HOME
            });
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.FAULT
            });
        } 


    }

    onTabClick = () => {
        if(!commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }
    onGetPrivatePhone = () => {

        let phone = this.props.orderDetail.customer.phoneNumber;        
        if(phone != null){
            if(phone.toString().length == 9){
                phone = `${phone.toString().substring(0,1)}** *** ${phone.toString().substring(6,9)}`;
            }
        }
        return phone;

    }
    render() {

        return (
            <div>
                <Header onHeaderClick = {this.onTabClick}/>
                <div id="absent">
                    <div className="container">
                        <div className={this.props.validHeader === 1 && isMobile ? "content-body-wo-header":"content-body"}>
                        
                        <div className="wrapper-absent">
                            <img id="img-title-confirm-absent" className="icon" src={iconCheck} alt="" />
                            <p id="lbl-title-confirm-absent" className="title">¡Perfecto!</p>
                            {
                                this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA ?
                                    this.props.orderDetail.activityType == CONSTANTS.PRODUCT.PROVISION_TYPE ?
                                        <p id="lbl-subtitle-confirm-absent" className="detail-provision">
                                            Recuerda que el técnico está esperando para realizar la instalación.
                                        </p>
                                    :
                                        <p id="lbl-subtitle-confirm-absent" className="detail">
                                            Recuerda que el técnico está esperando para revisar la avería de tu servicio
                                        </p>
                                :
                                    // provision - notdonetraza
                                    this.props.orderDetail.activityType == CONSTANTS.PRODUCT.PROVISION_TYPE ?
                                        <p id="lbl-subtitle-confirm-absent" className="detail-second-provision">
                                            Te enviaremos un mensaje de texto al número {this.onGetPrivatePhone()} para que puedas agendar la nueva fecha para la visita del técnico.
                                        </p>
                                    :
                                        <p id="lbl-subtitle-confirm-absent" className="detail-second">
                                            Nos estaremos contactando contigo para coordinar la nueva fecha de la visita del técnico.
                                        </p>
                                    // averia - notdonetraza
                            }
                            <Button id="btn-accept-confirmabsent" className="wrapper-button" variant="contained" color="primary" onClick={this.onContinue} >
                                OK, ENTENDIDO
                            </Button>                                
                        </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
        validHeader: state.security.userWebView,
        schedule: state.schedule.scheduleData,
        orderType: state.security.orderType,
    }
}

export default connect(mapStateToProps, null)(Absent);