import React, { Component } from 'react';
import './Address.scss';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";
import Loading from '../../components/common/elements/Loading';
import Alert from '../modals/Alert';
import Info from '../modals/Info';
import * as securityActions from '../../store/actions/security.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as provisionSelectors from '../../store/selectors/provision.selectors';
import { CONSTANTS } from '../../utils/Constants';


import AddressWeb from './web/AddressWeb';
import AddressMobile from './mobile/AddressMobile';


class Address extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showLoading: false,
            direccion: '',
            referencia: '',
            distrito: '',
            errorMsj : '',
            openAlertError: false,
            openAlertInformation: false
        }
    }

    onBack = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'dirección de instalación',
            eventAction: 'click',
            eventLabel:  'volver',
            valorElemento: this.props.orderDetail.activeStatus,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });
        this.props.history.goBack();
    }

    onCloseError = () => {
        this.setState({
            openAlertError: false
        })

    }

    onCloseInformation = () => {
        this.setState({
            openAlertInformation: false
        })

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'dirección de instalación',
            eventAction: 'click',
            eventLabel:  'cancelar',
            valorElemento: this.props.orderDetail.activeStatus,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

    }

    getBaseUrl() {
        var re = new RegExp(/^.*\//);
        return re.exec(window.location.href);
    }

    onAcceptInformation = () => {
        
        //Enviando servicio para notificar cambio de direccion
        this.setState({ showLoading: true });
        this.props.setProvisionUpdateAddress().then(resp => {
            this.setState({ showLoading: false, 
                            openAlertInformation: false
            });
            if (resp.status === 'OK') {

                //this.props.provisionUpdate(resp.data)
                if(isMobile){
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.HOME
                    });
                }
               
            } else {
                this.errorMensajeModal(resp.message, true);
            }

        });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'dirección de instalación',
            eventAction: 'click',
            eventLabel:  'aceptar',
            valorElemento: this.props.orderDetail.activeStatus,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

    }
    componentDidMount = () =>{
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'dirección de instalación',
            valorElemento: this.props.orderDetail.activeStatus,
            path: '/address',
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });
    }

    errorMensajeModal = (mensaje,estado) => {
        this.setState({
            errorMsj : mensaje,
            openAlertError : estado,
            openAlertInformation: false
        })
    }

    onClick = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'dirección de instalación',
            eventAction: 'click',
            eventLabel:  'corregir dirección',
            valorElemento: this.props.orderDetail.activeStatus,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });
        this.setState({
            openAlertError : false,
            openAlertInformation : true
        })
        this.props.location ?  this.props.location.onAddressClick() :  this.props.onAddressClick();

    }


    render() {
        return (
            <section id="address">
                <Loading id="loading-address" showLoading={this.state.showLoading} />
                <div>
                    {
                        isMobile ?
                        <AddressMobile 
                            onBack={this.onBack}
                            onClick={this.onClick} 
                            user={this.props.user}
                            provision={this.props.orderDetail}
                            validHeader={this.props.validHeader}
                           
                        />

                        :

                        <AddressWeb 
                            onBack={this.onBack}
                            onClick={this.onClick} 
                            user={this.props.user}
                            provision={this.props.orderDetail}
                           
                        />

                    }  
                </div>
                <Alert id="mdl-error-address" openAlert={this.state.openAlertError} errorMsj={this.state.errorMsj} onClose={this.onCloseError} onAccept={this.onCloseError} />
                <Info id="mdl-info-address" openAlert={this.state.openAlertInformation} onClose={this.onCloseInformation} onAccept={this.onAcceptInformation} />    
            </section>
        )

    }



}

const mapStateToProps = state => {    
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
        validHeader: state.security.userWebView
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setProvisionUpdateAddress: () =>  dispatch(securityActions.setProvisionUpdateAddress()),//dispatch(provisionActions.setProvisionUpdateAddress()),
        //provisionUpdate:(data) =>dispatch(provisionActions.provisionUpdate(data))
        provisionUpdate:(data) =>dispatch(securityActions.provisionUpdate(data))
    }
}
// export default Address;

export default connect(mapStateToProps, mapDispatchToProps)(Address);