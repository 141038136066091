import React, { Component } from 'react';
import './TokenExternal.scss';
import Button from '@material-ui/core/Button';
import iconVolverAzul from '../../../resources/svg/login/iconVolverAzul.svg';
import imgsms from '../../../resources/img/login_validacion/icon-sms.svg';
import Timer from 'react-compound-timer';

import iconMovistar from '../../../resources/svg/onboarding/iconMovistarBlue.svg';
import { animateScroll as scroll } from 'react-scroll';

class TokenExternal extends Component {

    componentDidMount = () => {
       scroll.scrollToTop();
    }

    render() {
       const {completeTime, completeTwoDigits, onChange, onKeyUp, validainput, onClick, state, nextpage, repass, phoneNumber, timerLimit, back,  handleChange} = this.props; 
       return (
           <>
           <section id="tokenExternal">
                <div className="container">
                    <div className='boxGroup'>
                        <img className="logo align-self-center" src={iconMovistar} />
                        
                        <p id="lbl-subtitle-token" className="descSingle">Te enviamos un sms con la clave de <br/>seguridad al <span><b className="phonenumber">{phoneNumber}.</b></span> Ingrésala aquí.</p>

                        <div className="boxToken">
                           
                        <div id="form" className="d-flex justify-content-center">
                            <input value={state.value1} className={state.showError ? "input-error" : state.resendToken ? "input-resend" : null} id="item1"  onChange={(e) => onChange(e)} onKeyUp={(e) => onKeyUp(e)} onKeyDown={(e) => validainput(e)} onInput={handleChange.bind(this)} type="text" autoComplete="off" maxLength="1" size="1" min="0" max="9" pattern="[0-9]*" inputmode="numeric" onClick={(e) => onClick(e)} />
                            <input value={state.value2} className={state.showError ? "input-error" : state.resendToken ? "input-resend" : null} id="item2"  onChange={(e) => onChange(e)} onKeyUp={(e) => onKeyUp(e)} onKeyDown={(e) => validainput(e)} onInput={handleChange.bind(this)} type="text" autoComplete="off" maxLength="1" size="1" min="0" max="9" pattern="[0-9]*" inputmode="numeric" onClick={(e) => onClick(e)} />
                            <input value={state.value3} className={state.showError ? "input-error" : state.resendToken ? "input-resend" : null} id="item3"  onChange={(e) => onChange(e)} onKeyUp={(e) => onKeyUp(e)} onKeyDown={(e) => validainput(e)} onInput={handleChange.bind(this)} type="text" autoComplete="off" maxLength="1" size="1" min="0" max="9" pattern="[0-9]*" inputmode="numeric" onClick={(e) => onClick(e)} />
                            <input value={state.value4} className={state.showError ? "input-error" : state.resendToken ? "input-resend" : null} id="item4"  onChange={(e) => onChange(e)} onKeyUp={(e) => onKeyUp(e)} onKeyDown={(e) => validainput(e)} onInput={handleChange.bind(this)} type="text" autoComplete="off" maxLength="1" size="1" min="0" max="9" pattern="[0-9]*" inputmode="numeric" onClick={(e) => onClick(e)} />
                        </div>

                        <label id="lbl-invalidpass-token" className="errorlabel full">{state.showError ? "La clave es incorrecta" : ""}</label>
                        
                        <label id="lbl-sentpass-token" className="resendlabel full">{state.resendToken ? "Clave de seguridad enviada" : ""}</label>
                        
                        
                        
                       

                        <div className="bxReenviar full d-flex justify-content-center align-items-center">
                                <img className="imgxr" src={imgsms} alt="sms" style={state.showCheck ? {opacity:'50%'} : null} />
                                <a id="lnk-resend-token" onClick={state.showCheck ? null : repass} className={state.showCheck ? "disabledTxr" : "txr"} >Reenviar clave </a>
                                
                                {state.showTimer && 
                                    <Timer
                                        initialTime={timerLimit !== null ? Number(timerLimit) : 300000 }
                                        direction="backward"
                                        startImmediately={true}
                                        >
                                        {() => (
                                            <div className='timerlabel'> 
                                                <React.Fragment>
                                                <Timer.Minutes formatValue={valueX => `(0${completeTime(valueX)}:`} />
                                                <Timer.Seconds formatValue={value => `${completeTwoDigits(value)})`} /> 
                                                </React.Fragment>                                              
                                            </div>
                                        )}
                                    </Timer>
                                }
                                
                            </div>


                            <Button onClick={nextpage} 
                                        id="btn-enter-token"
                                        disabled={state.validacion ? "" : "disabled"} 
                                        style={{ opacity: state.validacion ? 1 : 0.4}}
                                        className="primaryButton" 
                                        variant="contained" 
                                        color="primary">
                                    INGRESAR
                            </Button>  

                            <div id="div-return-token" onClick={back} className="boxRegresar d-flex-inline pointer justify-content-center align-items-center">
                                <img src={iconVolverAzul} alt="" />
                                <p id="lbl-return-token">Regresar</p>
                            </div>

                        </div>
                    </div>
                </div>        
           </section>
           </>
       ) 


    }


}

export default TokenExternal;