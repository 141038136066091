import React, { Component } from 'react';
import { connect } from 'react-redux';
import iconHome from '../../../resources/svg/home/fault/iconToolGrey.svg';
import iconClockGrey from '../../../resources/svg/home/iconClockGrey.svg';

import * as commonSelectors from '../../../store/selectors/common.selectors';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { CONSTANTS } from '../../../utils/Constants';

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ADDRESS_CHANGED 
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE_PAGO
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.INGRESADO
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CANCEL
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CAIDA
            || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOT_DONE 
        }
    }

    textFullMonth = () => {
        let scheduleDate = this.props.schedule.scheduleDate.split('-');
        let getNameMonth = commonSelectors.getMonthName(scheduleDate[1]);
        if (this.dayInstall() == 'Hoy') {
            return `${scheduleDate[2]} de ${getNameMonth.toLowerCase()} del ${scheduleDate[0]}`;

        } else {
            return `de ${getNameMonth.toLowerCase()} del ${scheduleDate[0]}`;

        }
    }

    dayInstall = () => {
        
        let scheduleDate = moment(this.props.schedule.scheduleDate).format('YYYY-MM-DD');
        //let scheduleDate = moment('2019-12-12').format('YYYY-MM-DD');
        let nowDate = moment().format('YYYY-MM-DD');
        if (moment(scheduleDate).isSame(nowDate)) {
            return 'Hoy'
        } else {
            return this.props.schedule.scheduleDate.split('-')[2]
        }
    }

    isApplyingPolicy = () => {
        let scheduleDate = moment(moment(this.props.schedule.scheduleDate).format('YYYY-MM-DD'));
        let systemDate = moment(moment(new Date).format('YYYY-MM-DD'));
        let diffDays = scheduleDate.diff(systemDate, 'days');

        let boolDiffDays = (diffDays >= Number(this.props.minAvailableDays));
        let boolLeftTimes = (Number(this.props.schedule.scheduleLeftTimes) > 0);

        return boolLeftTimes && boolDiffDays;
    }

    detalleDeAveria = () => {
        let detalle_arr = this.props.orderDetail.productName.split(' ') ;
        const res = detalle_arr.reduce((acc, val) => val.toLowerCase() == 'averia'  ? acc += '': acc+=val+" ", '')
        return res;
    }

    render() {
        const {onReschedule} = this.props;
        return (
            <div className={`box-border ${
                            (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TRIAJE_LIQUIDADO
                                || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.COMPLETE ) 
                                ? 'box-border-green'
                                : (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOTDONE_TRAZA )
                                    ?'box-border-greey'
                                    :(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CANCEL 
                                        || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOT_DONE ) 
                                        ? 'box-border-red'
                                        :''}`}>
                <div className="box-schedule">
                    <div id="lbl-head-schedulehome" className={`text-head ${
                            (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TRIAJE_LIQUIDADO
                                || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.COMPLETE ) 
                                ? 'text-head-green'
                                    :(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CANCEL 
                                        || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOT_DONE ) 
                                        ? 'text-head-red'
                                        :''}`}>
                        FECHA DE VISITA TÉCNICA
                                    </div>
                    <div className="wrp-info">
                        <div className="wrp-date-mob">
                            <div className="wrp-schedule">
                                <div className="date-schedule">
                                    <p id="lbl-date-schedulehome" className={`day ${this.dayInstall() == 'Hoy' ? 'today' : ''}`}>{this.dayInstall()}</p>
                                    
                                </div>
                                <div className="turno">
                                    <p id="lbl-range-schedulehome" className="txt1">Turno:</p>
                                    <p id="lbl-time-schedulehome" className="txt2">de {this.props.schedule.scheduleTimeRange.replace(/:00/gi, '')}</p>

                                </div>
                            </div>
                            <div>
                            <p id="lbl-month-schedulehome" className="full-name-schedule">{this.textFullMonth()}</p>
                            </div>

                        </div>

                        <div className="wrp-date-web">
                            <div id="lbl-date-schedulehome" className={`day ${this.dayInstall() == 'Hoy' ? 'today' : ''}`}>{this.dayInstall()}</div>
                            <div className="dates">
                                <p id="lbl-month-schedulehome" className="txt1">{this.textFullMonth()}</p>  
                                <div className="wrp-turn">
                                    <img id="img-icon-schedulehome" className="icon" src={iconClockGrey} alt="" />
                                    <p id="lbl-time-schedulehome" className="txt2">Turno: de {this.props.schedule.scheduleTimeRange.replace(/:00/gi, '')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="line"></div>
                        <div className={`wrp-name ${this.dayInstall() == 'Hoy' ? 'wrp-name-2' : ''}`}>
                            <img id="img-icon-schedulehome" className="icon" src={iconHome} alt="" />
                            {/* <img id="img-icon-schedulehome" className={isMobile ? null : "icon"} src={iconArrowRight} alt="" /> */}
                            <p id="lbl-name-schedulehome" className="name">
                                <span  className="text">Avería en</span> {this.detalleDeAveria()}
                            </p>

                        </div>
                    </div>
         
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
        
        schedule: state.schedule.scheduleData,
        technician: state.schedule.scheduleTechnician,

        minAvailableDays: state.security.userReschedulePolicy
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Schedule);