import React from 'react';
import './Conditions.scss';
import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';

import iconClose from '../../resources/svg/login/iconClose.svg';

const Conditions = ({openModal, onAccept, onClose, onGTM}) => 
(
    <div id="condition"> 

    <Modal 

        open={openModal} 
        onClose={onClose} 
        center 
        showCloseIcon={null}>

        <div className="bxModalTerminos">

            <div className="close-wrapper">
                <img id="img-close-conditions" 
                    className="icon-close"
                    src={iconClose} 
                    onClick={onClose} 
                    alt="" />
            </div>
            
            <div class="scroll-box">

                <h2 id="lbl-title-conditions" className="title">Términos y condiciones de Movistar, web de Mi Movistar o aplicativos móviles</h2>
                {/* <p id="lbl-detail-conditions"
                    >Al ingresar al portal web “Mi Movistar”, a la página web 
                    <a 
                        id="lnk-movistar-conditions" 
                        onClick={onGTM} 
                        className="link no-underline"> www.movistar.com.pe</a>,  al aplicativo móvil “Mi Movistar” o “Movistar Hogar” (en adelante, cualquiera de ellos se entenderá como “Canal Virtual de Movistar”) el cliente reconoce haber leído, comprendido y aceptado los términos y condiciones que a continuación se listan.</p> */}
                <p id="lbl-terms-conditions">
                    Al ingresar al portal web “Mi Movistar”, a la página web www.movistar.com.pe o al aplicativo móvil (en adelante, cualquiera de ellos se entenderá como “Canal Virtual de Movistar”) el Cliente reconoce haber leído, comprendido y aceptado los Términos y Condiciones que a continuación se listan.
                </p>
                <p id="lbl-terms2-conditions">
                    Los Términos y Condiciones se aplican para acceder en línea desde la web o aplicativo a: (i) La información relacionada a servicios contratados: (ii) Visualizar o realizar transacciones relacionadas a sus servicios contratados; y/o, (iii) comprar productos o contratar servicios (los “servicios”).
                </p>
                <p id="lbl-terms3-conditions">
                    Los Términos y Condiciones aplican para Telefónica del Perú S.A.A., Telefónica Multimedia S.A.C., y a cualquier empresa que este directa o indirectamente controlada por, sea controlante de, o se encuentre bajo control común con cualquiera de ellas (en adelante, “Movistar”).
                </p>
                <p id="lbl-terms4-conditions">
                    I. Condiciones Generales de Uso para Clientes <br/>
                    - La afiliación otorgará una contraseña única e intransferible, la misma que será utilizada por el Cliente para realizar cualquiera de los Servicios desde cualquier dispositivo. La contraseña será requerida cada vez que el Cliente desee realizar una compra desde el Canal Virtual de Movistar.
                    - El acceso al Canal Virtual de Movistar desde cualquier dispositivo implica el consumo de datos, los mismos que serán consumidos de tu plan de datos contratado, salvo que te encuentres en una conexión WIFI.
                    - Si te encuentras en el extranjero y utilizas el Canal Virtual de Movistar desde una aplicación o desde la web mobile, estarás realizando consumo de datos de tu plan de datos contratado por lo que se te aplicarán costos de transmisión de datos en Roaming de acuerdo a las tarifas vigentes, te recomendamos utilizar una conexión WIFI para evitar costos adicionales.
                    - El Cliente tiene la posibilidad de solicitar la eliminación de su cuenta en el portal “Mi Movistar”, para ello deberá asistir a un centro de atención presencial mostrando su documento de identidad y firmando el “Formato Único de Transacciones”.
                </p>
                <p id="lbl-terms5-conditions">
                    II. Consideraciones según dispositivo <br/>
                    Los Clientes registrados con su DNI en los sistemas comerciales de Movistar podrán acceder a los Servicios a través de su PC o dispositivo móvil (web mobile o aplicación) y los clientes registrados con RUC solo podrán acceder a los Servicios a través de su PC.
                </p>
                <p id="lbl-terms5-conditions">
                    III. Términos y Condiciones Legales <br/>
                    - Movistar garantiza la seguridad y confidencialidad en el tratamiento de los datos de carácter personal facilitados por sus Clientes, de conformidad con la legislación peruana. En ningún caso Movistar proporciona información que identifique a sus clientes, sin previa autorización de éstos, salvo para el estricto y único fin de atenderlos de la mejor forma.
                    - Movistar garantiza que los datos ingresados y las transacciones realizadas son seguras y permanecen confidenciales e inalterables gracias al Protocolo de Seguridad SSL.
                    - El correo electrónico registrado debe ser válido y es de exclusiva responsabilidad del Cliente. Movistar se reserva los derechos de eliminación de cuentas en caso identifique que el correo electrónico registrado es inválido.
                </p>
                <p id="lbl-terms6-conditions">
                    Términos y Condiciones Legales <br/>
                    1 Cliente: persona natural o jurídica que cuente con un servicio o producto de Movistar y que decide realizar su compra o consultar información relacionada a sus servicios a través de su usuario y la contraseña en el portal web “Mi Movistar”.
                    - Las transacciones que realicen los Clientes implican la aceptación plena y sin reservas de éste de todos los términos y condiciones incluidos en el Aviso Legal de Movistar según se encuentren vigentes en el momento mismo en que el cliente acceda al portal web de “Mi Movistar”.
                    - La solicitud y uso de la contraseña para ingresar al portal web de “Mi Movistar” es exclusiva responsabilidad del Cliente. Movistar queda excluida de cualquier responsabilidad por el uso indebido o cualquier acto que pueda perjudicar al cliente o a un tercero que se vean afectados directa o indirectamente.
                    - El Cliente acepta que las notificaciones sobre la contratación, cambios y/o terminación de producto(s) y/o servicio(s) contratados sean a través del correo electrónico registrado en el portal web de “Mi Movistar”.
                    </p>
                <p id="lbl-terms6-conditions">
                    IV. Información relacionada a servicios contratados por el Cliente a los que podrá acceder a través del Canal Virtual de Movistar: <br/>
                    1. Zona consulta de Saldo: Prepago y Postpago: <br/>
                    - El uso de la aplicación está disponible solo para Clientes de celulares Postpago/Prepago Movistar debidamente registrados con su DNI en los sistemas comerciales de Movistar. <br/>
                    - A través de esta aplicación el Cliente podrá acceder a consultas de su línea Postpago y Prepago. - La aplicación cuenta con un sistema de contraseña enviado vía sms a su línea celular, para brindarle mayor seguridad y de esta manera su información estará protegida. <br/>
                    - La contraseña requiere actualización cada treinta (30) días, para lo cual el cliente deberá ingresar nuevamente su número de teléfono en el aplicativo y así recibirá vía sms la nueva contraseña.
                    </p>
                <p id="lbl-terms6-conditions">
                    2. Compra de productos y/o contratación de servicios de Movistar <br/>
                    2.1. Precios <br/>
                    - Los precios corresponden a los productos/servicios descritos. Movistar se reserva el derecho de efectuar, en cualquier momento y sin previo aviso, las modificaciones que considere oportunas, pudiendo actualizar, incluso diariamente los precios de los productos y/o servicios en función de las novedades del sector al que pertenecen. <br/>
                    - Movistar se reserva el derecho de comercializar ofertas exclusivas para los Clientes que adquieran los productos/servicios a través del Canal Virtual de Movistar. <br/>
                    - Movistar mostrará una confirmación del pedido seleccionado con el precio regular y el precio de la promoción en los casos que aplique.
                    </p>
                <p id="lbl-terms6-conditions">
                    2.2. Forma de pago <br/>
                    a) Para productos Móviles: <br/>
                    - El pago por la compra de productos móviles deberá ser contra-entrega con tarjetas de crédito Visa o Mastercard emitidas en el país o internacionales, es decir, el pago se realizará en el momento en la que Movistar realice la entrega del producto al cliente. <br/>
                    - No se aceptan como formas de pago los cheques ni el pago en efectivo. <br/>
                    - El cliente recibirá un correo electrónico por parte de Movistar de recepción del pedido.
                    </p>
                <p id="lbl-terms6-conditions">
                    b) Para servicios Fijos y/o Móviles: <br/>
                    - El pago por la contratación de servicios fijos o móviles será a través de la facturación en el recibo, salvo alguna especificación expresa de pago adelantado por correo electrónico o telefónicamente por alguno de los asesores de Movistar. <br/>
                    - De acuerdo con su evaluación crediticia usted deberá pagar el costo de instalación de su servicio por adelantado o financiado (que se reflejara en su recibo), en caso el pago sea por adelantado le llegará un mail confirmando el código de pago y los lugares donde puede efectuarlo, de no realizar el pago no se efectuará la instalación. De no llegarle ninguna información llamar al 104. <br/>
                    - Se entregará la guía de remisión y un pre comprobante de pago emitido por Movistar, el comprobante de pago final se envía electrónicamente al correo registrado por el Cliente. Este documento es indispensable para hacer efectiva la garantía del equipo. <br/>
                    - El Cliente que contrate un servicio móvil recibirá un correo electrónico por parte de Movistar de recepción del pedido.
                    </p>
                <p id="lbl-terms6-conditions">
                    2.3. Entrega y/o Instalación <br/>
                    - La entrega se realizará en la dirección indicada por el cliente al realizar la compra en el Canal Virtual de Movistar. <br/>
                    - Las zonas de delivery en Lima Metropolitana son las detalladas por Telefónica en el Canal Virtual de Movistar. Los plazos máximos para la entrega del producto y/o instalación del servicio, una vez confirmado el pedido con el cliente son:
                    </p>
                <p id="lbl-terms6-conditions">
                    a) Productos Móviles: <br/>
                    • Lima Metropolitana (de acuerdo al punto anterior): 48 horas (días hábiles) <br/>
                    • Otros destinos: 3 días hábiles
                    </p>
                <p id="lbl-terms6-conditions">                    
                    b) Servicios Fijos y/o Móviles: <br/>
                    • Lima Metropolitana y otros destinos: 7 días hábiles el cual está sujeto a facilidades técnicas y de cobertura. <br/>
                    Nota: no son días hábiles, los sábados, domingos ni feriados de cualquier tipo.
                    </p>
                <p id="lbl-terms6-conditions">
                    - Movistar no se responsabilizará por la demora o atrasos en las entregas cuando se deban a causas no imputables. <br/>
                    - La transacción que el cliente realice por este medio es personal e intransferible y está sujeta a las condiciones aquí descritas y en el documento de entrega que recibirá el cliente. <br/>
                    - Al momento de la entrega el cliente deberá entregarle al courrier:
                    </p>
                <p id="lbl-terms6-conditions">
                    a) Productos móviles con planes postpago: <br/>
                    1. Copia del documento de identidad (DNI, Carné de Extranjería o pasaporte) <br/>
                    2. Copia del recibo de luz, agua o telefonía fija <br/>
                    3. Sustento de ingresos en caso alguno de los asesores de Movistar, previa a la confirmación de la compra, lo haya solicitado por correo electrónico o vía telefónica. <br/>
                    4. Devolución del contrato de servicios, acuerdo de adquisición de equipos y otros anexos debidamente firmados y con la huella digital del titular del servicio. <br/>
                    b) Productos móviles con planes prepago: <br/>
                    1. Copia del documento de identidad (DNI, Carné de Extranjería o pasaporte). <br/>
                    c) Servicios móviles y/o productos fijos: <br/>
                    1. Especificar el contacto para la coordinación de la instalación y/o entrega de los productos o servicios solicitados. <br/>
                    - El cliente no deberá realizar ningún pago adicional por la entrega del producto adicional al de su comprobante de pago, salvo que por motivos ajenos a Movistar se tenga que realizar nuevas visitas a efectos de la entrega, de ocurrir, el cliente será notificado antes de la confirmación de compra por uno de los asesores de Movistar.
                    </p>
                <p id="lbl-terms6-conditions">
                    2.4. Cancelaciones o devoluciones <br/>
                    - El cliente podrá devolver su pedido en cualquiera de los siguientes casos: <br/>
                    • Alteración comprobada del sello de seguridad. El cliente deberá anotar una observación en el registro de entrega del Courier, señalando que el sello se encontraba alterado y definiendo el tipo de alteración (inexistente, roto, etc). <br/>
                    • Defecto del producto (daño cosmético externo, falta de funcionamiento y operación o desperfecto). <br/>
                    - La devolución de equipos (móviles y fijos), cualquiera sea su causa, deberá remitirse en su embalaje original y con todos los accesorios y manuales incluidos.
                    </p>
                <p id="lbl-terms6-conditions">
                    2.5. Garantía <br/>
                    - Todos los equipos (móviles y fijos) entregados por Movistar, se encuentran amparados bajo las políticas de garantía que se detallan a continuación: <br/>
                    • Garantía limitada de 1 año, contada a partir de la fecha que consta en la factura. La garantía es solo para el comprador original y en condiciones normales de uso. <br/>
                    • La garantía cubre defectos de componentes del equipo y mano de obra. <br/>
                    • La garantía no cubre fallas o mal funcionamiento por: golpes, humedad, maltrato, mal uso, rotura o adulteración de sellos de garantía, alteración o imposibilidad de lectura de números de serie, en caso de que aplique. <br/>
                    • La garantía se hará efectiva en cualquier servicio técnico autorizado de Movistar ubicados únicamente en centros de atención, ventas y servicios del Perú. <br/>
                    • La evaluación de mal funcionamiento o defectos de equipo dará lugar a la garantía únicamente si es determinada por el personal de cualquier servicio técnico autorizado de Movistar del Perú. <br/>
                    • Durante el periodo de garantía, Movistar puede a su criterio reparar el equipo o reemplazarlo por un equipo equivalente, sin costo para el comprador original. <br/>
                    • Para ser sujeto de la garantía es necesario la presentación de la boleta y/o factura original o electrónica de la compra, la cual deberá incluir el número de serie del equipo, en caso aplique.
                </p>
            </div>
            
            <div className="button-wrapper">
                <Button 
                    id="btn-accept-conditions" 
                    className="buttonAcept" 
                    variant="contained" 
                    color="primary" 
                    onClick={onAccept}>
                    ACEPTAR
                </Button>
            </div>
        </div>      
    </Modal>
    </div>
)


export default Conditions;