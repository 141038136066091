import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../../utils/Constants';

import moment from 'moment';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';

class HistoryValid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevPath: null,
            currentPath: null,
        }
    }

    historyValidPathV2 = () => {
        console.log('from: ' + this.props.location.pathname);
        console.log('to: ' + this.props.history.location.pathname);
        
        //Login => Token 
        if (this.props.location.pathname === CONSTANTS.PATH.LOGIN 
            && this.props.history.location.pathname === CONSTANTS.PATH.TOKEN) {
                console.log("1")
                if (this.props.provisionAll === null) {
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
                    });
                }
        }

        //Login => Home 
        if (this.props.location.pathname === CONSTANTS.PATH.LOGIN 
            && this.props.history.location.pathname === CONSTANTS.PATH.HOME) {
                console.log("2")
                if (this.props.user === null) {
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
                    });
                }
        }

        //Login => List 
        if (this.props.location.pathname === CONSTANTS.PATH.LOGIN 
            && this.props.history.location.pathname === CONSTANTS.PATH.LIST) {
                console.log("3")
                if (this.props.provisionAll === null) {
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
                    });
                }
        }

        //Login => Fault 
        if (this.props.location.pathname === CONSTANTS.PATH.LOGIN 
            && this.props.history.location.pathname === CONSTANTS.PATH.FAULT) {
                console.log("4")
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
                });
        }

        //Token => Login
        if (this.props.location.pathname === CONSTANTS.PATH.TOKEN 
            && this.props.history.location.pathname === CONSTANTS.PATH.LOGIN) {
                console.log("5")
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
                });
        }

        //Home => Token
        if (this.props.location.pathname === CONSTANTS.PATH.HOME 
            && this.props.history.location.pathname === CONSTANTS.PATH.TOKEN) {
                console.log("6")
                if(this.props.provisionAll.length === 1){
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME
                    });
                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                    });
                }
         }

         //Fault => Token
        if (this.props.location.pathname === CONSTANTS.PATH.FAULT 
            && this.props.history.location.pathname === CONSTANTS.PATH.TOKEN) {
                console.log("7")
                if(this.props.provisionAll.length === 1){
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.FAULT
                    });
                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                    });
                }
        }

        //List => Login
        if (this.props.location.pathname === CONSTANTS.PATH.LIST 
            && this.props.history.location.pathname === CONSTANTS.PATH.LOGIN) {
                console.log("8")
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                });    
            
        }

        //List => Token
        if (this.props.location.pathname === CONSTANTS.PATH.LIST 
            && this.props.history.location.pathname === CONSTANTS.PATH.TOKEN) {
                console.log("9")
                if(this.props.orderManagement == null){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                    });    
                }
        }

        //List => Confirm
        if (this.props.location.pathname === CONSTANTS.PATH.LIST 
            && this.props.history.location.pathname === CONSTANTS.PATH.SCHEDULE_CONFIRM) {
                console.log("10")
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                });
        }

        //Home => Confirm
        if (this.props.location.pathname === CONSTANTS.PATH.HOME 
            && this.props.history.location.pathname === CONSTANTS.PATH.SCHEDULE_CONFIRM) {
                console.log("11")
                if(this.props.provisionAll.length === 1){
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME
                    });
                }else{
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                    });
                }
        }

         //Home => Confirm
         if (this.props.location.pathname === CONSTANTS.PATH.SCHEDULE_CONFIRM 
            && this.props.history.location.pathname === CONSTANTS.PATH.SCHEDULE) {
                console.log("12")
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.SCHEDULE_CONFIRM
                });
        }

        //List => Cancel
        if (this.props.location.pathname === CONSTANTS.PATH.LIST 
            && this.props.history.location.pathname === CONSTANTS.PATH.CONFIRMATION_CANCEL) {
                console.log("13")
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                });
        }

         //Home => Cancel
         if (this.props.location.pathname === CONSTANTS.PATH.HOME
            && this.props.history.location.pathname === CONSTANTS.PATH.CONFIRMATION_CANCEL) {
                console.log("14")
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME
                });
        }

        //List => Home
        if (this.props.location.pathname === CONSTANTS.PATH.LIST 
            && this.props.history.location.pathname === CONSTANTS.PATH.HOME) {
                console.log("15")
                if(this.props.provisionSelected == null){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                    });
                }
        }

        //List => Fault
        if (this.props.location.pathname === CONSTANTS.PATH.LIST 
            && this.props.history.location.pathname === CONSTANTS.PATH.FAULT) {
                console.log("16")
                if(this.props.provisionSelected == null){
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                    });
                }
        }

        //Home => LoginAdmin
        if (this.props.location.pathname === CONSTANTS.PATH.LOGIN_ADMIN 
            && this.props.history.location.pathname === CONSTANTS.PATH.HOME) {
                console.log("17")
                if (this.props.user === null) {
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN_ADMIN;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN_ADMIN
                    });
                }
        }

        //List => LoginAdmin
        if (this.props.location.pathname === CONSTANTS.PATH.LOGIN_ADMIN 
            && this.props.history.location.pathname === CONSTANTS.PATH.LIST) {
                console.log("18")
                if (this.props.user === null) {
                    window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN_ADMIN;
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN_ADMIN
                    });
                }
         }

         //List => LoginAdmin
         if (this.props.location.pathname === CONSTANTS.PATH.LIST 
            && this.props.history.location.pathname === CONSTANTS.PATH.EXTERAL_ACCESS) {
                console.log("19")
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST;
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LIST
                });
         }

         if (this.props.location.pathname === CONSTANTS.PATH.HOME 
            && this.props.history.location.pathname === CONSTANTS.PATH.EXTERAL_ACCESS) {
                console.log("20")
                window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME;
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.HOME
                });
         }

        console.log('from: ' + this.props.location.pathname);
        console.log('to: ' + this.props.history.location.pathname);

    }
   
    componentWillReceiveProps(nextProps) {
        const nowTime = moment();
        const loginTime = moment(this.props.userLoginTime);

        if(this.props.location.pathname != CONSTANTS.PATH.LOGIN && nowTime.diff(loginTime, 'minutes') > 30){
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
            window.location = CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN;
        }else{
            this.historyValidPathV2();
        }
        
    } 

    render() {
        // return this.props.children
        return (
            <div></div>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    user: state.security.userData,
    provisionAll: state.security.orderList,
    provisionSelected: state.security.ordersIdSelected,
    userLoginTime: state.security.userLoginTime,

    orderManagement: state.security.orderDetail,

});

export default withRouter(connect(mapStateToProps, null)(HistoryValid));
