import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Devolution.scss';
import moment from 'moment';

import * as provisionSelectors from '../../store/selectors/provision.selectors';

import iconWarning from '../../resources/svg/home/detail/iconWarning.svg';
import iconCalendar from '../../resources/svg/home/detail/iconCalendar.svg';
import iconUbication from '../../resources/svg/home/detail/iconUbication.svg';
import BotonVolver from '../common/elements/ReturnButton';
import Footer from '../common/elements/Footer';
import Header from '../header/Header';

class Devolution extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isPeruvian: this.props.orderDetail.customer.documentType == "DNI" ? true : false,
        }
    }

    onClick = () => {

    }

    onBack = () => {
        this.props.history.goBack();
    }

    capitalizeUserName = () => {
        let name = this.props.user.name.split(' ')[0];
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()

    }

    fullNameDate = () =>{
        let arrayDate = (moment(this.props.orderDetail.upFront.paymentDate).format('YYYY-MM-DD')).split('-');
        

        return ` ${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
    }

    render() {

        return (
            <div>
                <Header />  
                 <section id="devolution">
                    <div className="container">
                        <div className={this.props.validHeader===1?"content-body-wo-header":"content-body"}>
                            <div className="box-back">
                                <BotonVolver onClick={this.onBack} />
                            </div>  
                            <div>
                                 <span><p id="lbl-title-devolution" className="texto">Devolución de pago adelantado</p></span>
                            </div> 
                            <div className="subtitle">
                                 {this.capitalizeUserName() + ", sigue las instrucciones para la devolución de tu pago."}
                            </div> 
                            <div className="box-date d-flex">
                                <div className="icon">
                                    <img id="img-calendar-devolution" src={iconCalendar} alt="" />
                                </div>
                                <div className="info">
                                    <p id="lbl-calendar-devolution" className="text"> {"Fecha de devolución:"} </p>
                                    <p id="lbl-date-devolution" className="value">{"A partir de " + this.fullNameDate()}</p>
                                    <p id="lbl-validity-devolution" className="add"> {"Vigencia 5 meses"} </p>
                                </div>
                            </div >
                                
                            <div className="box-place d-flex">
                                <div className="icon">
                                    <img src={iconUbication} alt="" />
                                </div>
                                <div className="info">
                                    <p id="lbl-place-devolution" className="text"> {"Lugares de pago:"} </p>
                                    <p id="lbl-ubication-devolution" className="value">{this.state.isPeruvian ? 'Ventanilla de agencias BCP' : 'Centros de atención Movistar'}</p>
                                    {/* <p id="lbl-link-devolution" className="link">Conoce la agencia más cercana <a href="javascript:;"  id="link-view-payment" style={{textDecoration:'underline'}}>aquí</a></p> */}
                                </div>
                            </div >
                            <div className="wrap-warning">
                                <img id="img-warning-devolution" className="icon" src={iconWarning} alt=""/>
                                <div className="info">
                                    <p id="lbl-notice-devolution" className="text">{this.state.isPeruvian ? 'Recuerda acercarte con tu DNI.' : 'Acércate con tu documento de identidad y los siguientes datos:'}</p> 
                                    {(() => {
                                        return !this.state.isPeruvian && <p id="lbl-ccte-devolution" className="count">{" C. Cte. " + this.props.orderDetail.codClienteAtis}</p> 
                                    })()}
                                    
                                    {(() => {
                                        return !this.state.isPeruvian && <p id="lbl-ccta-devolution" className="count">{" C. Cta. " + this.props.orderDetail.codCuentaAtis}</p> 
                                    })()}

                                </div>
                            </div>
                             

                        </div>
                    </div>    
                </section>
                <Footer/>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        validHeader: state.security.userWebView,
        orderDetail: state.security.orderDetail, 
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Devolution);