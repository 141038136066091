import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './Payment.scss';
import { isMobile } from "react-device-detect";

import iconPayment from '../../../resources/svg/home/detail/iconPayment.svg';
import iconAlert from '../../../resources/svg/home/detail/iconAlert.svg';

import * as commonSelectors from '../../../store/selectors/common.selectors';
import * as provisionSelectors from '../../../store/selectors/provision.selectors';

class Payment extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            collapse: false,
            collapseAddress: false,
            iconService: ''
        }
    }

    onChangeCollapse = (e) => {
        this.setState({ collapse: !this.state.collapse });
    }

    onChangeCollapseAddress = (e) => {
        this.setState({ collapseAddress: !this.state.collapseAddress });
    }

    fullNameDate = () =>{
        let arrayDate = (moment(this.props.orderDetail.registerDate).format('YYYY-MM-DD')).split('-');
        let getNameMonth = commonSelectors.getMonthName(arrayDate[1]);

       return `${arrayDate[2]} de ${getNameMonth.charAt(0).toUpperCase() + getNameMonth.slice(1).toLowerCase()} del ${arrayDate[0]}`;
    }

    fullReferencesAddress = () => {
        let ref = this.props.orderDetail.customer.reference;
        ref = ref != null ? `Ref: ${ref}, ` : "";
        return ref;
    }

    fullReferencesDistrict = () => {
        let ref = this.props.orderDetail.customer.district;
        ref = ref != null ? ` ${ref}, ` : "";
        return ref;
    }

    fullReferencesProvince = () => {
        let ref = this.props.orderDetail.customer.province;
        ref = ref != null ? ` ${ref} ` : "";
        return ref;
    }

    clickPlacesOfPayment = () => {

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: 'ver lugares de pago',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

        this.props.onOpenPlaces()

    }

    render() {
        
        return (
            (() => {
                if(this.props.orderDetail.upFront !=null){

                    return (<div id="payment">
                    <div className="wrp-payment">
                        <div className="container">
                            <div className="box-full-payment">
                                <div className="d-flex">
                                    <p id="lbl-subtitle-payment" className="subtitle">A nombre de: </p>
                                    <p id="lbl-type-payment" className="type"> PAGO EFECTIVO</p>
                                </div>
                                
                                <div className="item-payment">
                                    <div className="d-flex">
                                        <div className="icon">
                                            <img id="img-icon-payment" src={iconPayment} alt=""/>
                                        </div>
                                        <div className="info">
                                            <div className="wrap-cip-monto">
                                                <p id="lbl-code-payment" className="code">{"Código CIP: " + this.props.orderDetail.upFront.cip}</p>
                                                <p id="lbl-amount-payment" className="text">{"Monto de pago: "}<strong className="text-bold" >{`S/${this.props.orderDetail.upFront.amount}`}</strong></p>
                                            </div>
                                            <p id="lbl-places-payment" className="text">{"Lugares de pago: "}<strong className="text-bold" >{`BCP, BBVA, SCOTIABANK ${ !isMobile? ', agencias, agentes o bodegas más cercanas a nivel nacional.' : ''}`}</strong></p>
                                            <a href="javascript:;" id="link-view-payment"  className="link"  onClick={this.clickPlacesOfPayment}>Para más lugares de pago, click aquí</a>
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <img id="img-alert-payment" src={iconAlert} alt=""/>
                                                    <p id="lbl-alert-payment" className="alert">{"Haz click para ver más lugares de pago"}</p>
                                                </div>
                                                <div className="act">
                                                    <button id="btn-view-payment" className="btn-tz btn-schedule" onClick={this.clickPlacesOfPayment} >Ver lugares de pago</button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
                }else{
                    return '';
                }

            }
        
        ))
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Payment);