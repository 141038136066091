import React from 'react';
import './Rating.scss';
import iconRating from '../../resources/svg/rating/heart.svg';
import firstState from '../../resources/svg/rating/angry_face.svg';
import secondState from '../../resources/svg/rating/sad_face.svg';
import thirdState from '../../resources/svg/rating/normal_face.svg';
import fourthState from '../../resources/svg/rating/smile_face.svg';
import fifthState from '../../resources/svg/rating/funny_face.svg';
import starEmpty from '../../resources/svg/rating/star_empty.svg';
import starFill from '../../resources/svg/rating/star_fill.svg';

import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Rating from 'react-rating';

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#B6B7B7',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#B6B7B7',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#B6B7B7',
        },
        '&:hover fieldset': {
          borderColor: '#B6B7B7',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00A9E0',
        },
      },
    },
  })(TextField);

  function changeImage (value, ratingSent) {
    let imgState = '';
    if(ratingSent){
      imgState = fifthState;
    }else{
      switch(value) {
        case 1: imgState = firstState; break;
        case 2: imgState = secondState; break;
        case 3: imgState = thirdState; break;
        case 4: imgState = fourthState; break;
        case 5: imgState = fifthState; break;
        default: imgState = iconRating;
      }
    }
    return imgState;
  }

  function changeText (value, questionList) {
    let getQuestion = questionList.filter( item => item.rating == value)
    return getQuestion[0].question;
  }

  function changeTitle (value, questionList) {
    let getTitle = questionList.filter( item => item.rating == value);
    let textState = '';

    if(value === 0) {
      textState = '¡Ayúdanos a seguir mejorando!'; 
    }else{
      textState = getTitle[0].title;
    }
    return textState;
  }

  // function validar(e,obj) {

  //   let tecla = (document.all) ? e.keyCode : e.which;
    
  //   if (tecla != 13) return;
  //   let filas = 4;
  //   let txt = obj.value.split('\n');
    
  //   return (txt.length < filas);
  // }


  export default function CustomizedRatings({openRating, ratingSent, selectedStar, questionList, onCloseRating, onAcceptRating, onChangeValue, starCount, onChangeText, onBlur }) {
    
    return (
    <>    
      <Modal 
        classNames={{
          overlay: 'alert-overlay',
          modal: 'alert-modal',
        }}
        open={openRating} 
        onClose={onCloseRating} 
        center
        >
          <div className="bxModalRating d-flex flex-column justify-content-center align-items-center">
              <img className="rating" src={changeImage(starCount, ratingSent)} alt="" />
              
              {!ratingSent?
                <div>
                  <h2 id="lbl-title-modalRating" className="title">{changeTitle(starCount, questionList)}</h2>
                </div>
              :
                <div>
                  <h2 id="lbl-title-modalRating" className="title">{"¡Gracias por su tiempo!"}</h2>
                </div>
              }

              {!ratingSent?
                <div className="bxRating">
                  <Rating
                    emptySymbol={<img src={starEmpty} className="icon" />}
                    fullSymbol={<img src={starFill} className="icon" />}
                    onChange={(value) => {
                      onChangeValue(value);
                    }}
                    initialRating={starCount}
                  />
                </div> 
                :null
              }

              {((starCount === 1) || (starCount === 2) || (starCount === 3)) && !ratingSent ?
                <div className="box-text-area">
                  
                    <CssTextField
                      id="txt-reason-rating"
                      className="text-area"
                      placeholder={changeText(starCount, questionList)}
                      onChange={onChangeText}
                      onBlur = {onBlur}
                      inputProps={{maxlength:"140"}}
                      multiline
                      rows="4" 
                      max-rows="4"
                      variant="outlined"
                      //onkeypress = {(event) => {validar(event,this)}}
                    />  
                </div>
                :
                (starCount === 4) || (starCount === 5) && !ratingSent ?
                  <p id="lbl-detail-modalerror" className="detalle">{"Tu valoración es muy importante para nosotros"}</p>
                : 
                ratingSent?
                  <p id="lbl-detail-modalerror" className="detalle">{"Seguiremos mejorando."}</p>
                :
                null
              }

              {!ratingSent?
                <Button 
                    id="btn-accept-modalRating" 
                    className="buttonSent" 
                    variant="contained" 
                    color="primary" 
                    onClick={onAcceptRating}
                    style={{ opacity: !selectedStar ? 0.4 : 1}}
                    disabled={!selectedStar}
                    >
                  {((starCount === 4) || (starCount === 5))? 'CALIFICAR' : 'ENVIAR'}
                </Button>
              :
                <Button 
                    id="btn-accept-modalRating" 
                    className="buttonClose" 
                    variant="contained" 
                    color="primary" 
                    onClick={onCloseRating}
                    >
                  CERRAR
                </Button>
              }
          </div>

      </Modal>
    </>
    )
  
}
