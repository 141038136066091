import axios from 'axios';

import { CONSTANTS } from '../../utils/Constants';
import { ENDPOINTS } from '../../utils/Endpoints';
import { REQUESTS } from '../../utils/Requests';

export const SET_RATING_DATA = 'SET_RATING_DATA';

export const getRating = (request) => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.SECURITY()}/rating/getRating`;

    let data = {
        header : {
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_RATING
        },
        body: { keyName: request.key }
    }

    return axios.post(API_URL, data, {
        headers: REQUESTS.HEADERS.SECURITY()
    }).then(resp => {
        console.log(resp)
        return { status: 'OK', data: resp.data.body }

    }).catch(error => {
        console.log(error)
        return { status: 'ERROR' }
    });

}

export const setRating = (request) => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.PROVISION()}/rating/setRating`;

    let data = {
        header : {
            appName: REQUESTS.DATA.APP_NAME,
            user: request.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SET_RATING,
            messageId: "",
            timestamp: ""
        },
        body: { 
            provisionId: request.provisionId,
            keyName: request.key,
            title: request.title,
            rating: request.rating,
            question: request.question,
            answer: request.answer 
        }
    }
    
    return axios.post(API_URL, data, {
        headers: REQUESTS.HEADERS.PROVISION()
    }).then(resp => {
        console.log(resp)
        return { status: 'OK', data: resp.data.body }

    }).catch(error => {
        console.log(error)
        return { status: 'ERROR' }
    });

}