import React, { Component } from 'react';
import './LoginAdmin.scss';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../utils/Constants';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';

import Alert from '../modals/Alert';
import Validations from '../../utils/Validations';
import Conditions from '../../components/modals/Conditions';
import Loading from '../../components/common/elements/Loading';

import iconMovistar from '../../resources/svg/onboarding/iconMovistarBlue.svg';

import * as securityActions from '../../store/actions/security.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';

class LoginAdmin extends Component {

    constructor(props){
        super(props);
        this.state = {
            showLoading: false,
            openAlert: false,
            openModal:false,
            validateNumberDoc:false,
            disabledContinue:true,
            isSchedule: false,
            valueDocType:1, //DNI por defecto
            valueDocNumber:'',
            maxLengthDoc:8, //DNI por defecto
            errorMsj: '',
            validateRuc: false,
            showError: false,
            patternText: "[0-9]",
            inputType:'number',

            usuario:'',
            password:''
        }
    }

    componentDidMount = () =>{

        this.props.securityReset();
        this.props.provisionReset();
        this.props.scheduleReset();
    }

    onContinue = () => {
        let tipoDoc = '';
        switch (this.state.valueDocType) {
            case 1: tipoDoc = 'DNI'; break;
            case 2: tipoDoc = 'CE'; break;
            case 3: tipoDoc = 'RUC'; break;
            case 4: tipoDoc = 'PASAPORTE'; break;
            case 5: tipoDoc = 'PTP'; break;
            default: break;
        }

        let request = {
            documentType: tipoDoc,
            documentNumber: this.state.valueDocNumber,
            user: this.state.usuario,
            password: this.state.password
        }

        this.setState({ showLoading: true });
        this.props.securityLoginAdmin(request).then(resp => {

            if (resp.status === 'OK') {
                this.apiGetOrders();
            } else {  
                this.setState({
                    showLoading: false, 
                    errorMsj: resp.message,
                    openAlert : true
                })
            }
        });

    }

    apiGetOrders = () =>{
        
        this.props.getOrders().then(response => {

            if (response.status === 'OK') {
                if(response.data.length === 1 ){
                    this.props.setTypeScheduleSource(response.data[0].activityType);
                    if(response.data[0].activityType === "provision"){
                        this.apiGetSheduleProvision();
                    }else {
                        this.apiGetSheduleFault(response.data);
                    }
                } else {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LIST
                    });
                }
  
            } else {
                this.setState({
                     showLoading: false, 
                     errorMsj: response.message,
                     openAlert : true
                })
            }
        })
    }

    apiGetSheduleProvision = () => {

        this.props.getScheduledOrder().then(resp => {
            if (resp.status === 'OK') {

                /* ===== Política de días de reagendamiento ===== */
                this.props.getApplicationParam(CONSTANTS.PARAM.PARAM_RESCHEDULE).then(response => {
                    if(response.status === 'OK'){
                        this.props.setSecurityReschedulePolicy(response.data.content.value);
                    }else{
                        this.props.setSecurityReschedulePolicy("1");
                    }
                });

                this.props.history.push({
                    pathname: CONSTANTS.PATH.HOME
                });

            } else {
                this.setState({
                    showLoading: false, 
                    errorMsj: resp.message,
                    openAlert : true
                })
            }
        });

    }

    apiGetSheduleFault = (order) => {

        this.props.getScheduledFault().then(resp => {

            if(resp.status === "OK"){
                this.props.history.push({
                    pathname: CONSTANTS.PATH.FAULT
                });

            }else {
                this.setState({
                     showLoading: false, 
                     errorMsj: resp.message,
                     openAlert : true
                })
            }

        });

    }



    apiGetProvision = () => {

        this.props.getProvision().then(resp => {

            if (resp.status === 'OK') {
                
                if(resp.data.length === 1 ){
                    this.apiGetShedule();
                } else {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LIST
                    });
                }
  
            } else {
                this.setState({
                    showLoading: false, 
                    errorMsj: resp.message,
                    openAlert : true
                })
            }
        })
    }

    apiGetShedule = () => {
        this.props.getSchedule().then(resp => {

            if (resp.status === 'OK') {
                this.props.history.push({
                        pathname: CONSTANTS.PATH.HOME
                });
            } else {
                this.setState({
                     showLoading: false, 
                     errorMsj: resp.message,
                     openAlert : true
                })
            }
        });
    }

    onChangeDocType = event => {
        let regExp=/^[0-9]+$/;
        let  inputType='number';

        switch(event.target.value)
        {
            case 2:
                regExp = /^[A-Za-z0-9]+$/; 
                inputType = 'text';
                break;
            case 4:
                regExp = /^[aA-Z-z0-9]+$/; 
                inputType = 'text';
                break;
            default:
                regExp = /^[0-9]+$/; 
                inputType= 'number';
                break;       
        }


        this.setState({
            validateNumberDoc:false,
            valueDocType:event.target.value,
            valueDocNumber:'',
            maxLengthDoc: this.setLengthInput(event.target.value),
            disabledContinue:true,
            showError:false,
            errorMsj:'',
            patternText:regExp,
            inputType: inputType
        })

    };

    setLengthInput = (tipodocumento) => {
        switch (tipodocumento) {
            case 1: return 8; //DNI
            case 2: return 12; //CE
            case 3: return 11; //RUC
            case 4: return 12; //PASAPORTE
            case 5: return 9; //PTP
            default: return 8;
        }
    }

    onChangeDocNumber = event => {

        switch(this.state.valueDocType)
        {
            case 1:
                if(event.target.value.length > 8) {
                    var valor = event.target.value;
                    valor = valor.substring(0,valor.length -1);  
                    this.setState({
                        valueDocNumber: valor
                    })
                    return false;
                }
                break; 
            case 3: //RUC
                if(event.target.value.length > 11) {
                        var valor = event.target.value;
                        valor = valor.substring(0,valor.length -1);  
                        this.setState({
                            valueDocNumber: valor
                        })
                        return false;
                }
                break;
            case 2:      
            case 4:  
                var regex = new RegExp("^[a-zA-Z0-9]*$");
                if (!regex.test(event.target.value)) {  
                    var valor = event.target.value;
                    valor = valor.substring(0,valor.length -1);  
                    this.setState({
                        valueDocNumber: valor
                    })
                    event.preventDefault();
                    return false;
                  }
                
                 if(event.target.value.length > 12) {
                    var valor = event.target.value;
                    valor = valor.substring(0,valor.length -1);  
                    this.setState({
                        valueDocNumber: valor
                    })
                    return false;
                }
                break;
             case 5:
                    if(event.target.value.length > 9) {
                        var valor = event.target.value;
                        valor = valor.substring(0,valor.length -1);  
                        this.setState({
                            valueDocNumber: valor
                        })
                        return false;
                    }
                    break;        
        }

        let validaRuc = !Validations.validarRUC(event.target.value, this.state.valueDocType);

        this.setState({
            validateRuc: validaRuc
        })

        this.setState({
            valueDocNumber: event.target.value,
            validateNumberDoc: !Validations.validarDocumento(event.target.value, this.state.valueDocType),
            disabledContinue: !Validations.validateContinue(event.target.value, this.state.valueDocType),
            validateRuc: !Validations.validarRUC(event.target.value, this.state.valueDocType)
        })
        

        if(validaRuc == true)
        {
            this.setState({
                showError: true,
                errorMsj: 'El número RUC debe empezar con 10'
            })
        }
        else {
            this.setState({
                showError: false,
                errorMsj: ''
            })
        }
        
        if(this.state.valueDocNumber=''){
             this.setState({
                 validateNumberDoc:false,
                 validateRuc:false,
                 showError:false,
                 errorMsj:''
             })
        }
    };

    onChangeUser = event => {
        this.setState({
            usuario : event.target.value
        }) 
    }

    onChangePass = event => {
        this.setState({
            password : event.target.value
        }) 
    }
    
    validateDocNumber = () => {
     
        if(!this.state.showError){
            this.setState({
                showError: this.state.disabledContinue,
                errorMsj:'El número de documento ingresado no es válido'
            })
        }
    };

    onlyNumbers = (e) => {
        
        switch(this.state.valueDocType)
        {
            case 2:
            case 4:
                
                var regex = new RegExp("^[a-zA-Z0-9]*$");
                var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (!regex.test(key)) {   
                     e.preventDefault();
                     return false;
                  }
                 break; 
                      
             default:
                 var key = window.event ? e.which : e.keyCode;
                 if (key < 48 || key > 57) {
                     e.preventDefault();
                     return false;
                 } 
                 break;            
        }
    }

    onConditions = () =>{
        this.setState({
            openModal: true
        })
    }

    onAccept = () => {
        this.setState({
            openModal: false
        })
    }

    onClose = () => {
        this.setState({
            openModal: false
        })
    }

    onCloseAlert = () => {
        this.setState({
            openAlert: false
        })
    }

    render() {
        return (
            <section id="loginAdmin"> 
                <Loading showLoading={this.state.showLoading} />
                <div className="row-flex bxgroup">
                    <div className="column-flex bxgroup_cont">
                        <img className="logo align-self-center" src={iconMovistar} />
                        <p className="titleSingle">Mi Pedido<br/>Movistar</p>
                        <p className="descSingle">Sigue el estado de tu pedido y gestiona<br/>tu cita de instalación</p>
                        <div className="boxCredentials">
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Tipo de documento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.valueDocType}
                                    onChange={this.onChangeDocType}
                                    className="combo-select"
                                >
                                    <MenuItem value={1}>DNI</MenuItem>
                                    <MenuItem value={2}>Carnet de extranjería</MenuItem>
                                    <MenuItem value={3}>RUC</MenuItem>
                                    <MenuItem value={4}>Pasaporte</MenuItem>
                                    <MenuItem value={5}>PTP</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                error={this.state.showError}
                                id="standard-basic"
                                label="Número de documento"
                                onChange={this.onChangeDocNumber}
                                onBlur={this.validateDocNumber}
                                onKeyPress={this.onlyNumbers}
                                inputProps={{maxlength:this.state.maxLengthDoc, value:this.state.valueDocNumber}}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="start">
                                        <Edit  style={{width:"10px"}} /> 
                                        {/* color:"#00A9E0" */}
                                    </InputAdornment>
                                    ),
                                    
                                }}
                            />

                            <TextField
                                id="standard-basic"
                                label="Usuario"
                                value={this.state.usuario}
                                onChange={this.onChangeUser}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="start">
                                        <Edit  style={{width:"10px"}} /> 
                                    </InputAdornment>
                                    ),
                                    
                                }}
                            />

                            <TextField
                                id="standard-password-input"
                                label="Password"
                                type="password"
                                onChange={this.onChangePass}
                                value={this.state.password}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="start">
                                        <Edit  style={{width:"10px"}} /> 
                                    </InputAdornment>
                                    ),
                                    
                                }}
                            />

                            <Button className="primaryButton" variant="contained" color="primary" disabled={this.state.disabledContinue} onClick={this.onContinue}>
                                CONTINUAR
                            </Button>
                        </div>
                    </div>
                </div>   
                <Alert openAlert={this.state.openAlert} errorMsj={this.state.errorMsj} onClose={this.onCloseAlert} onAccept={this.onCloseAlert} />
                <Conditions openModal={this.state.openModal} onAccept={this.onAccept} onClose={this.onClose} ></Conditions>  
            </section>  
        )
    }

}

const mapStateToProps = state => ({
    user: state.security.userData
})

const mapDispatchToProps = dispatch => ({

    securityLoginAdmin: (request) => dispatch(securityActions.securityLoginAdmin(request)),
    securityReset: () => dispatch(securityActions.securityReset()),
    provisionReset: () => dispatch(provisionActions.provisionReset()),
    scheduleReset: () => dispatch(scheduleActions.scheduleReset()),

    getProvision: () => dispatch(provisionActions.getProvision()),
    getSchedule: () => dispatch(scheduleActions.getSchedule()),

    getOrders: () => dispatch(securityActions.getOrders()),
    getScheduledOrder : () => dispatch(scheduleActions.getScheduledOrder()),
    getScheduledFault : () => dispatch(scheduleActions.getScheduledFault()),
    getApplicationParam: (request) => dispatch(securityActions.getApplicationParam(request)),
    setSecurityReschedulePolicy: (value) => dispatch(securityActions.setSecurityReschedulePolicy(value)),
    setTypeScheduleSource: (typeScheduleSource) => dispatch(scheduleActions.setTypeScheduleSource(typeScheduleSource)),

})

export default connect(mapStateToProps, mapDispatchToProps)(LoginAdmin);