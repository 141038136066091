import * as scheduleActions from './../actions/schedule.actions';

const INIT_STATE = {
    scheduleData: null,
    scheduleSource: '',
    scheduleCupos: null,
    scheduleTechnician: null,
    scheduleSelectedDate: '',
    scheduleDisabledDate: [],
    scheduleContactEdit: false
}

export default function userReducer(state = INIT_STATE, action) {

    switch (action.type) {
        case scheduleActions.SET_SCHEDULE_DATA:
            return { ...state, scheduleData: action.payload }
        case scheduleActions.SET_SCHEDULE_CUPOS:
            return { ...state, scheduleCupos: action.payload }
        case scheduleActions.SET_SCHEDULE_RESET:
            return { ...state, scheduleData: null, scheduleSource: '', scheduleCupos: null, scheduleTechnician: null, scheduleSelectedDate: '', scheduleDisabledDate: [], scheduleContactEdit: false }
        case scheduleActions.SET_SCHEDULE_SOURCE:
            return { ...state, scheduleSource: action.payload }
        case scheduleActions.SET_SCHEDULE_TECHNICIAN:
            return { ...state, scheduleTechnician: action.payload }
        case scheduleActions.SET_SCHEDULE_SELECTED_DATE:
            return { ...state, scheduleSelectedDate: action.payload }
        case scheduleActions.SET_SCHEDULE_DISABLED_DATE:
            return { ...state, scheduleDisabledDate: action.payload }
        case scheduleActions.SET_SCHEDULE_CONTACT_EDIT:
            return { ...state, scheduleContactEdit: action.payload }
        default:
            return state;
    }
}