import React from 'react';
import './Loading.scss';

const style1 = {
  width: '80px',
  height: '80px'
}

const style2 = {
  width: '100%',
  height: '100%'
}; 
const Loading = ({showLoading}) =>
(
  <div className={showLoading ? 'sweet-loading' : 'hidden'}>
    <div className="lds-css ng-scope" style={style1}>
    <div style={style2} className="lds-rolling">
      <div>
      </div>
    </div>
  </div>
  </div>
  
  
)


export default Loading;