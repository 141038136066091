import { combineReducers } from 'redux';
// import loginReducer from './login';
// import agendamientoReducer from './agendamiento';
// import validacionReducer from './validacion';
// import cancelarPedido from './cancelarPedido';
// import user from './user.reducers';
import provision from './provision.reducers';
import schedule from './schedule.reducers';
import averia from './averia.reducers';
import security from './security.reducers';

const rootReducer = combineReducers({
    security,
    schedule,
    provision,
    averia,
    // user,
    // login : loginReducer,
    // validacion: validacionReducer,
    // agendamiento: agendamientoReducer,
    // cancelar: cancelarPedido
});

export default rootReducer;
