import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import { connect } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import './CancelRadio.scss';

class CancelRadio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: '',
            showReason: 'false'
        }
    }

    handleChange = (event) => {
        this.setState({
            selected: event.target.value
        });
        this.props.onChange(event.target.value);
        
    }

    render() {
        return (
            <div id="cancel-radio">
                <FormControl component="fieldset">
                    <RadioGroup
                            id="rg-reasons-cancel"
                            aria-label="position"
                            className="wrp-radio"
                            name="position"
                            value={this.state.selected}
                            onChange={this.handleChange} row
                            >

                            <FormControlLabel
                                value="NO_SOLICITE"
                                id="lbl-item1-cancel"
                                control={<Radio id="rb-item1-cancel" color="primary" />}
                                label="No solicité el pedido"
                                labelPlacement="top"
                                className={`radio-item ${this.state.selected == 'NO_SOLICITE' ? 'border-on': 'item-off'}`}
                            />



                            <FormControlLabel
                                value="NO_DESEO"
                                id="lbl-item2-cancel"
                                control={<Radio id="rb-item2-cancel" color="primary" />}
                                label="Ya no deseo el pedido"
                                className={`radio-item ${this.state.selected == 'NO_DESEO' ? 'border-on': 'item-off'}`}
                                labelPlacement="top"
                            />

                            {
                                this.props.showReason &&

                                <FormControlLabel
                                value="OTRO"
                                id="lbl-item3-cancel"
                                control={<Radio id="rb-item3-cancel" color="primary" />}
                                label="Otro motivo (Especificar)"
                                className={`radio-item3 ${this.state.selected == 'OTRO' ? 'border-on': 'item-off'}`}
                                labelPlacement="top"
                                />     

                            }

                            {
                                !this.props.showReason &&

                                <FormControlLabel
                                value="OTRO"
                                id="lbl-item3-cancel"
                                control={<Radio id="rb-item3-cancel" color="primary" />}
                                label="Otro motivo (Especificar)"
                                className={`radio-item2 ${this.state.selected == 'OTRO' ? 'border-on': 'item-off'}`}
                                labelPlacement="top"
                                />     

                            }

                          



                    </RadioGroup>
                </FormControl>
            </div>
        )


    }






}

export default CancelRadio;