import axios from 'axios';

import { CONSTANTS } from '../../utils/Constants';
import { ENDPOINTS } from '../../utils/Endpoints';
import { REQUESTS } from '../../utils/Requests';

export const SET_SCHEDULE_DATA = 'SET_SCHEDULE_DATA';
export const SET_SCHEDULE_SOURCE = 'SET_SCHEDULE_SOURCE';
export const SET_SCHEDULE_CUPOS = 'SET_SCHEDULE_CUPOS'
export const SET_SCHEDULE_RESET = 'SET_SCHEDULE_RESET';
export const SET_SCHEDULE_TECHNICIAN = 'SET_SCHEDULE_TECHNICIAN';
export const SET_SCHEDULE_SELECTED_DATE = 'SET_SCHEDULE_SELECTED_DATE';
export const SET_SCHEDULE_DISABLED_DATE = 'SET_SCHEDULE_DISABLED_DATE';
export const SET_SCHEDULE_CONTACT_EDIT = 'SET_SCHEDULE_CONTACT_EDIT';
/**
|--------------------------------------------------
| Actions
|---*/


export const scheduleReset = () => async (dispatch, getState) => {
    dispatch({ type: SET_SCHEDULE_RESET });
}

export const setScheduleContactEdit = (flag) => async (dispatch, getState) => {
    dispatch({ type: SET_SCHEDULE_CONTACT_EDIT, payload: flag });
}

export const setTypeScheduleSource = (typeSchedule) => async (dispatch, getState) => {
    dispatch({ type: SET_SCHEDULE_SOURCE, payload: typeSchedule })
}

export const setTypeScheduleSelectedDate = (typeSelectedDate) => async (dispatch, getState) => {
    dispatch({ type: SET_SCHEDULE_SELECTED_DATE, payload: typeSelectedDate })
}

export const setTypeScheduleDisabledDate = (typeDisabledDate) => async (dispatch, getState) => {
    dispatch({ type: SET_SCHEDULE_DISABLED_DATE, payload: typeDisabledDate })
}

// extarnal, admin
export const getSchedule = () => async (dispatch, getState) => {

    let idCollection = getState().provision.provisionIdSelected;
    let API_URL = `${ENDPOINTS.BASE.SCHEDULE()}/schedule/getSchedule`;
    let getProvision = getState().provision.provisionData.filter(item => (item.idProvision === getState().provision.provisionIdSelected))[0];

    let subBody = {
        requestId: idCollection,
        requestType: getProvision.activityType,
        activityType: getProvision.activityType,

        /* genericos request */
        documentNumber: getState().security.userData.documentNumber,
        documentType: getState().security.userData.documentType,
        orderCode: getProvision.xaRequest,
        bucket: ''
    };

    if (getProvision.activeStatus === CONSTANTS.STATUS.PENDIENTE
        || getProvision.activeStatus === CONSTANTS.STATUS.INGRESADO
        || getProvision.activeStatus === CONSTANTS.STATUS.CAIDA) {

        if (getProvision.dummyStPsiCode != null) {
            subBody.stPsiCode = getProvision.dummyStPsiCode;
            subBody.scheduleType = 'FICTICIOUS_SCHEDULED';

        }else {
            return { status: 'OK' }
        }
    } else {
        subBody.stPsiCode = getProvision.xaIdSt;
        subBody.scheduleType = 'SCHEDULED';
    }

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_SCHEDULE
        },
        body: subBody
    }
    console.log('==================== request getSchedule ==============');
    console.log(request);

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.SCHEDULE()
    }).then(response => {
        console.log("===================response getSchedule ======");
        console.log(response);

        if (response.data.body != null) {

            //Traer datos del técnico
            if (response.data.body.scheduleStatus === 'in-progress') {

                let API_URL_TEC = `${ENDPOINTS.BASE.SCHEDULE()}/technician/getTechnicianInfo`;

                let requestTec = {
                    header: {
                        appName: REQUESTS.DATA.APP_NAME,
                        user: getState().security.userData.documentNumber,
                        operation: REQUESTS.DATA.OPERATION_GET_TECHNICIAN_INFO
                    },
                    body: {
                        requestType: getProvision.activityType,
                        xaRequest: getProvision.xaRequest,
                        xaIdSt: getProvision.xaIdSt,

                        /* genericos request */
                        documentNumber: getState().security.userData.documentNumber,
                        documentType: getState().security.userData.documentType,
                        orderCode: getState().provision.provisionData.filter(item => (item.idProvision === getState().provision.provisionIdSelected))[0].xaRequest,
                        bucket: ''
                    }
                }

                return axios.post(API_URL_TEC, requestTec, {
                    headers: REQUESTS.HEADERS.SCHEDULE()
                }).then(resp => {
                    //Resumen agendamiento
                    dispatch({ type: SET_SCHEDULE_DATA, payload: response.data.body });
                    dispatch({ type: SET_SCHEDULE_TECHNICIAN, payload: resp.data.body.technician })

                    return { status: 'OK', data: response.data.body }
                }).catch(error => {
                    console.log('getTechnicianInfo shedule: ' + error);
                    return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
                });

            } else {
                //Ok sin agenda
                dispatch({ type: SET_SCHEDULE_DATA, payload: response.data.body });
                return { status: 'OK', data: response.data.body }
            }
        } else {

            //OK sin agenda
            return { status: 'OK' }
        }

    }).catch(error => {
        console.log('getSchedule shedule: ' + error);
        return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }
    });

}

export const setContactInfoUpdateFaultOrProvision = (request) => async (dispatch, getState) => {

    const API_URL = `${ENDPOINTS.BASE.PROVISION()}/provision/setContactInfoUpdateWeb`; //`${ENDPOINTS.BASE.PROVISION()}/provision/setContactInfoUpdate`;
    const HEADER_URL = REQUESTS.HEADERS.PROVISION();

    request.activityType = getState().security.orderType;
    request.documentNumber = getState().security.userData.documentNumber;
    request.documentType = getState().security.userData.documentType;
    request.orderCode = getState().security.orderDetail.xaRequest;
    request.bucket = '';
    request.scheduler = getState().security.orderDetail.scheduler != null ? getState().security.orderDetail.scheduler : 'PSI';

    let requestContactInfo = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SET_CONTACT_INFO_UPDATE
        },
        body: request
    }
    
    console.log("============= request setContactInfoUpdate =============");
    console.log(requestContactInfo)

    return axios.post(API_URL, requestContactInfo, {
        headers: HEADER_URL
    }).then(response => {
        console.log("============= setContactInfoUpdate =============")
        console.log(response)

        if (response.data.body != null) {
            return { status: 'OK', data: response.data.body }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_500 }
        }

    }).catch(error => {
        console.log("============= ERROR setContactInfoUpdate =============")
        console.log(error)

        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_500 }
        } else if (codigoHTTP === 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_400 }
        } else if (codigoHTTP === 401) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_401 }
        } else if (codigoHTTP === 404) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_404 }
        } else if (codigoHTTP === 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.CONTACT.SET_CONTACT_ERROR_409 }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.ERROR.ERROR_400 }
        }

    });
}

export const getScheduleAvailability = () => async (dispatch, getState) => {

    let API_URL = ENDPOINTS.BASE.SCHEDULE() + '/schedule/aftersales/availability-technical-appointment-web'; //'/schedule/aftersales/availability-technical-appointment'

    // let request = {
    //     header: {
    //         appName: REQUESTS.DATA.APP_NAME,
    //         user: getState().security.userData.documentNumber,
    //         operation: REQUESTS.DATA.OPERATION_GET_SCHEDULE_AVAILABILITY
    //     },
    //     body: {
    //         stpsiCode: getState().security.orderDetail.xaIdSt,
    //         channel: "TZ",
            
    //         activityType: getState().security.orderType,
    //         documentNumber : getState().security.userData.documentNumber,
    //         documentType : getState().security.userData.documentType,
    //         orderCode : getState().security.orderDetail.xaRequest,
    //         bucket : ''
    //     }
    // }

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_SCHEDULE_AVAILABILITY
        },
        body: {
            stpsiCode: getState().security.orderDetail.xaIdSt != null ? getState().security.orderDetail.xaIdSt : '',
            channel: "TZ",
            activityType: getState().security.orderType != null ? getState().security.orderType : '',
            documentNumber: getState().security.userData.documentNumber != null ? getState().security.userData.documentNumber : '',
            documentType: getState().security.userData.documentType != null ? getState().security.userData.documentType : '',
            orderCode: getState().security.orderDetail.xaRequest != null ? getState().security.orderDetail.xaRequest : '',
            bucket: "",
            scheduler: getState().security.orderDetail.scheduler != null ? getState().security.orderDetail.scheduler : 'PSI',
            priority: getState().security.orderDetail.priority != null ? getState().security.orderDetail.priority : '',
            customerTtype: getState().security.orderDetail.customerType != null ? getState().security.orderDetail.customerType : '',
            customerSubType: getState().security.orderDetail.customerSubType != null ? getState().security.orderDetail.customerSubType : '',
            phoneNetworkTechnology: getState().security.orderDetail.telephoneNetworkTechnology != null ? getState().security.orderDetail.telephoneNetworkTechnology : '',
            phoneTechnology: getState().security.orderDetail.telephoneTechnology != null ? getState().security.orderDetail.telephoneTechnology : '',
            broadbandNetworkTechnology: getState().security.orderDetail.broadbandNetworkTechnology != null ? getState().security.orderDetail.broadbandNetworkTechnology : '',
            broadbandTechnology: getState().security.orderDetail.broadbandTechnology != null ? getState().security.orderDetail.broadbandTechnology : '',
            tvNetworkTechnology: getState().security.orderDetail.tvNetworkTechnology != null ? getState().security.orderDetail.tvNetworkTechnology : '',
            tvTechnology: getState().security.orderDetail.tvTechnology != null ? getState().security.orderDetail.tvTechnology : '',
            latitude: getState().security.orderDetail.customer.latitude != null ? getState().security.orderDetail.customer.latitude : '',
            longitude: getState().security.orderDetail.customer.longitude != null ? getState().security.orderDetail.customer.longitude : ''
        }
    }

    console.log("============= request availability-technical-appointment =============")
    console.log(request)

    /* let data  = {
        "location": "BK_FS_AGENDADOR01",
        "date": [
            {
                "day": "2023-01-24",
                "slot": [
                    {
                        "name": "AM",
                        "quantity": "1"
                    }
                ]
            },
        ]
    }; */
    

    return axios.post(API_URL, request, {
        headers: REQUESTS.HEADERS.SCHEDULE()
    }).then(response => {
        console.log("============= availability-technical-appointment =============")
        console.log('capacity')
        console.log(response.data.body.capacity)
        
        ///
        if (response.data.body.capacity !== null) {
            dispatch({ type: SET_SCHEDULE_CUPOS, payload: response.data.body.capacity });
            return { status: 'OK' }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.NO_CAPACITY }
        }

    }).catch(error => {
        console.log("============= ERROR availability-technical-appointment =============")
        console.log(error.response)
        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.GET_CAPACITY_ERROR_500 }
        } else if (codigoHTTP === 400) {
            let msj = getState().schedule.scheduleData === null ? CONSTANTS.MESSAGE.SCHEDULE.GET_CAPACITY_ERROR_400_FIRST : CONSTANTS.MESSAGE.SCHEDULE.GET_CAPACITY_ERROR_400;
            return { status: 'ERROR', message: msj }
        } else if (codigoHTTP === 401) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.GET_CAPACITY_ERROR_401 }
        } else if (codigoHTTP === 403) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.GET_CAPACITY_ERROR_403 }
        } else if (codigoHTTP === 404) {
            return { status: 'ERROR', message: error.response.data.header.message }
        } else if (codigoHTTP === 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.GET_CAPACITY_ERROR_409 }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MENSAJES.ERROR.ERROR_400 }
        }
    });
}

export const setSchedule = (request) => async (dispatch, getState) => {

    const API_URL = ENDPOINTS.BASE.SCHEDULE() + '/schedule/aftersales/add-schedule'; //'/schedule/aftersales/schedule-technical-appointments';
    let getProvision = getState().security.orderDetail;
    // let requestSchedule = {
    //     header: {
    //         appName: REQUESTS.DATA.APP_NAME,
    //         user: getState().security.userData.documentNumber,
    //         operation: REQUESTS.DATA.OPERATION_SET_SCHEDULE
    //     },
    //     body: {
    //         requestId: getProvision.idProvision,
    //         requestType: 'provision',//getState().schedule.scheduleSource,
    //         requestName: getProvision.productName,
    //         selectedDate: request.selectedDate,
    //         selectedRange: request.selectedRange,
    //         isPilot: false,
    //         xaOrderCode: getProvision.xaRequest,
    //         stpsiCode: getProvision.xaIdSt,
    //         workZone: getState().schedule.scheduleCupos.location,
    //         channel: "TZ",
    //         customer: {
    //             documentType: getState().security.orderDetail.customer.documentType,
    //             documentNumber: getState().security.orderDetail.customer.documentNumber,
    //             email: getState().security.orderDetail.customer.mail,
    //             cellphone: getState().security.orderDetail.customer.phoneNumber,
    //             contactCellphone: getState().security.orderDetail.customer.contactPhoneNumber,
    //             cellphoneIsMovistar: getState().security.orderDetail.customer.carrier,
    //             contactCellphoneIsMovistar: getState().security.orderDetail.customer.contactCarrier,
    //             fullName: getState().security.orderDetail.customer.name,
    //             contactFullName: getState().security.orderDetail.customer.contactName,
    //             address: getState().security.orderDetail.customer.address,
    //             productName: getState().security.orderDetail.customer.productName
    //         },
    //         activityType: getState().security.orderType,
    //         saleCode: getState().security.orderDetail.saleCode
    //     }
    // }

    let requestSchedule = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_SET_SCHEDULE
        },
        body: {
            requestId: getProvision.idProvision,
            requestType:"provision",
            requestName: getProvision.productName != null ? getProvision.productName : '',
            selectedDate: request.selectedDate,
            selectedRange: request.selectedRange,
            isPilot: false,
            xaOrderCode: getProvision.xaRequest != null ? getProvision.xaRequest : '',
            stpsiCode: getProvision.xaIdSt != null ? getProvision.xaIdSt : '',
            workZone: getState().schedule.scheduleCupos.location != null ? getState().schedule.scheduleCupos.location : '',
            channel: "TZ",
            customer:{ //antes: envio desde decurity.userDatas
                documentType: getState().security.orderDetail.customer.documentType,
                documentNumber: getState().security.orderDetail.customer.documentNumber,
                email: getState().security.orderDetail.customer.mail,
                cellphone: getState().security.orderDetail.customer.phoneNumber,
                contactCellphone: getState().security.orderDetail.customer.contactPhoneNumber,
                cellphoneIsMovistar: getState().security.orderDetail.customer.carrier,
                contactCellphoneIsMovistar: getState().security.orderDetail.customer.contactCarrier,
                fullName: getState().security.orderDetail.customer.name,
                contactFullName: getState().security.orderDetail.customer.contactName,
                address: getState().security.orderDetail.customer.address,
                productName: getState().security.orderDetail.customer.productName
            },
            scheduler: getProvision.scheduler != null ? getProvision.scheduler : 'PSI', //Validar si siempre va PSI
            priority: getProvision.priority != null ? getProvision.priority : '',
            customerTtype: getProvision.customerType != null ? getProvision.customerType : '',
            customerSubType: getProvision.customerSubType != null ? getProvision.customerSubType : '',
            phoneNetworkTechnology: getProvision.telephoneNetworkTechnology != null ? getProvision.telephoneNetworkTechnology : '',
            phoneTechnology: getProvision.telephoneTechnology != null ? getProvision.telephoneTechnology : '',
            broadbandNetworkTechnology: getProvision.broadbandNetworkTechnology != null ? getProvision.broadbandNetworkTechnology : '',
            broadbandTechnology: getProvision.broadbandTechnology != null ? getProvision.broadbandTechnology : '',
            tvNetworkTechnology: getProvision.tvNetworkTechnology != null ? getProvision.tvNetworkTechnology : '',
            tvTechnology: getProvision.tvTechnology != null ? getProvision.tvTechnology : '',
            latitude: getProvision.customer.latitude != null ? getProvision.customer.latitude : '',
            longitude: getProvision.customer.longitude != null ? getProvision.customer.longitude : ''
        }
    }

    return axios.post(API_URL, requestSchedule, {
        headers: REQUESTS.HEADERS.SCHEDULE()
    }).then(response => {
        console.log("=================== response setschedule ==============");
        console.log(response);

        if (response.data.body.schedule !== null) {
            dispatch({ type: SET_SCHEDULE_DATA, payload: response.data.body.schedule })
            return ({ status: 'OK' })
        } else {
            return { status: 'ERROR', message: CONSTANTS.MENSAJES.ERROR.ERROR_400 }
        }

    }).catch(error => {

        //console.log('Error setSchedule> ' + JSON.stringify(error));
        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_500 }
        } else if (codigoHTTP === 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_400 }
        } else if (codigoHTTP === 401) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_401 }
        } else if (codigoHTTP === 403) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_403 }
        } else if (codigoHTTP === 404) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_404 }
        } else if (codigoHTTP === 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_409 }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_500 }
        }

    });

}

export const getScheduledOrder = () => async (dispatch, getState) => {

    let API_URL = `${ENDPOINTS.BASE.SCHEDULE()}/schedule/getSchedule`;
    let orderObject = getState().security.orderDetail;

    let subBody = {
        //requestId: orderObject.idProvision,
        //activityType: getState().security.orderType,
        requestType: getState().security.orderType,
        /* genericos request */
        documentNumber: getState().security.userData.documentNumber,
        documentType: getState().security.userData.documentType,
        orderCode: orderObject.xaIdSt, // cambio
        bucket: '',
        scheduleType: 'SCHEDULED'
    };


    // antigüo
    /* if(orderObject.xaIdSt != null ){
        subBody.stPsiCode = orderObject.xaIdSt;
        subBody.scheduleType = 'SCHEDULED';
    }else if (orderObject.dummyStPsiCode != null) {
        subBody.stPsiCode = orderObject.dummyStPsiCode;
        subBody.scheduleType = 'FICTICIOUS_SCHEDULED';
    }else{
        subBody.stPsiCode = '';
        subBody.scheduleType = 'FICTICIOUS_SCHEDULED';
    } */

    // if (orderObject.activeStatus === CONSTANTS.STATUS.PENDIENTE
    //     || orderObject.activeStatus === CONSTANTS.STATUS.INGRESADO
    //     || orderObject.activeStatus === CONSTANTS.STATUS.CAIDA) {

    //     if (orderObject.dummyStPsiCode != null) {
    //         subBody.stPsiCode = orderObject.dummyStPsiCode;
    //         subBody.scheduleType = 'FICTICIOUS_SCHEDULED';
    //     }

    // } else {
    //     subBody.stPsiCode = orderObject.xaIdSt;
    //     subBody.scheduleType = 'SCHEDULED';
    // }

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_SCHEDULE
        },
        body: subBody
    }

    let headerDefault = REQUESTS.HEADERS.SCHEDULE();
    let headerToken   = { 'token': getState().security.authToken };
    const headerTotal = { ...headerDefault, ...headerToken };

    console.log('==================== request getSchedule ==============');
    console.log(request);

    return axios.post(API_URL, request, {
        headers: headerTotal
    }).then(response => {
        console.log("===================response getSchedule ======");
        console.log(response);

        if (response.data.body != null) {

            dispatch({ type: SET_SCHEDULE_DATA, payload: response.data.body });
            return { status: 'OK', data: response.data.body }

        } else {

            //OK sin agenda
            return { status: 'OK' }
        }

    }).catch(error => {
        console.log("=================== ERROR getSchedule ======");
        console.log(error);

        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_500 }
        } else if (codigoHTTP === 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_400 }
        } else if (codigoHTTP === 401) {
            return { status: 'ERROR_SECURITY', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_401 }
        } else if (codigoHTTP === 403) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_403 }
        } else if (codigoHTTP === 404) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_404 }
        } else if (codigoHTTP === 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_409 }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_500 }
        }
    });

}

export const getScheduledFault = () => async (dispatch, getState) => {

    let API_URL      = `${ENDPOINTS.BASE.SCHEDULE()}/schedule/getSchedule`;
    let orderObject  = getState().security.orderDetail;

    let request = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_SCHEDULE
        },
        body: {
            /* requestId: getState().security.orderDetail.idFault,
            requestType: getState().security.orderType,
            activityType: getState().security.orderType,
            stPsiCode: getState().security.orderDetail.xaIdSt,
            scheduleType: 'SCHEDULED',
            /*documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.orderDetail.xaRequest,
            bucket: '' */
                        
            requestType: getState().security.orderType,
            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: orderObject.xaIdSt,
            bucket: '',
            scheduleType: 'SCHEDULED'
        }
    };

    console.log('==================== request getSchedule FAULT ==============');

    let headerDefault = REQUESTS.HEADERS.SCHEDULE();    
    let headerToken   = { 'token': getState().security.authToken };
    const headerTotal = { ...headerDefault, ...headerToken };


    return axios.post(API_URL, request, {
        headers: headerTotal
    }).then(response => {

        if (response.data.body != null) {

            dispatch({ type: SET_SCHEDULE_DATA, payload: response.data.body });
            return { status: 'OK', data: response.data.body }

        } else {
            //OK sin agenda
            return { status: 'OK' }
        }
        
    }).catch(error => {

        console.log("===================error getSchedule FAULT ======");
        console.log(error);
        
        let codigoHTTP = error.response ? error.response.status : 500;

        if (codigoHTTP >= 500) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_500 }
        } else if (codigoHTTP === 400) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_400 }
        } else if (codigoHTTP === 401) {
            return { status: 'ERROR_SECURITY', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_401 }
        } else if (codigoHTTP === 403) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_403 }
        } else if (codigoHTTP === 404) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_404 }
        } else if (codigoHTTP === 409) {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_409 }
        } else {
            return { status: 'ERROR', message: CONSTANTS.MESSAGE.SCHEDULE.SET_SCHEDULE_ERROR_500 }
        }

    });
}

export const getTechnicianInfo = () => async(dispatch, getState) =>{

    let API_URL_TEC = `${ENDPOINTS.BASE.SCHEDULE()}/technician/getTechnicianInfo`;
    
    let orderId = getState().security.ordersIdSelected.orderId;
    let orderObject = (getState().security.ordersData.filter(item => (item.idFault === orderId)))[0];

    let requestTec = {
        header: {
            appName: REQUESTS.DATA.APP_NAME,
            user: getState().security.userData.documentNumber,
            operation: REQUESTS.DATA.OPERATION_GET_TECHNICIAN_INFO
        },
        body: {
            requestType: orderObject.activityType,
            xaRequest: orderObject.xaRequest,
            xaIdSt: orderObject.xaIdSt,
            activityType: getState().security.ordersIdSelected.orderType,

            /* genericos request */
            documentNumber: getState().security.userData.documentNumber,
            documentType: getState().security.userData.documentType,
            orderCode: getState().security.ordersData.filter(item => (item.idFault === orderId))[0].xaRequest,
            bucket: ''
        }
    }
    console.log(requestTec)
    return axios.post(API_URL_TEC, requestTec, {
        headers: REQUESTS.HEADERS.SCHEDULE()
    }).then(resp => {
        console.log("============= getTechnicianInfo =============")
        console.log(resp)

        dispatch({ type: SET_SCHEDULE_TECHNICIAN, payload: resp.data.body.technician })
        return { status: 'OK' }

    }).catch(error => {
        console.log("============= ERROR getTechnicianInfo =============")
        console.log(error);
        return { status: 'ERROR', message: CONSTANTS.MESSAGE.SECURITY.ERROR_TOKEN }

    });


}

