import React from 'react'
import './AlertMessage.scss'
import iconAlert from '../../../resources/svg/home/iconAlertInfo.svg'
export default function AlertMessage() {
    return (
        <div id = "box-alert-message">
             <div className="info d-flex">
                        <img id="img-status-msg" className="icon" src={iconAlert} alt="" />
                        <div id="lbl-status-msg" className="text">
                            Recuerda que el técnico esperará un máximo de 15 minutos.
                        </div>
                    </div>
           
        </div>
    )
}
