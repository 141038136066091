import React, { Component } from 'react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
import './Map.scss';

import Header from '../header/Header';
import BotonVolver from '../common/elements/ReturnButton';

import * as commonSelectors from '../../store/selectors/common.selectors';
import * as provisionSelectors from '../../store/selectors/provision.selectors';

class Map extends Component {

    componentDidMount(){

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'mapa técnico',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.user.phoneNumber,
            productos: this.props.user.productName ? this.props.user.productName.toLowerCase().trim() : '' 
        });
        
    }

    getMapFromOrder = () => {
        return this.props.orderDetail.woPreStart.trackingUrl;
    }

    goBack = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'mapa técnico',
            eventAction: 'click',
            eventLabel: 'volver',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.props.history.goBack();
    }

    render() {

        return (
            <div>
                <Header />
                

                <div id="viewer-map">
                    <div className="box-return">
                        <div className="box-return-fit">
                            <BotonVolver onClick={this.goBack} isAbsolute={false}/> 
                        </div>
                        
                    </div>
                    
                    <div className="box-map">
                        <Iframe 
                            url={this.getMapFromOrder()}
                            className="frame-url"
                            // display="initial"
                            // position="relative"
                            width="-webkit-fill-available"
                            height="-webkit-fill-available"
                            id="frame-map-home"
                        />
                    </div>
                </div>
            </div>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        schedule: state.schedule.scheduleData,
        technician: state.schedule.scheduleTechnician,
        orderDetail: state.security.orderDetail, 
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Map);