import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Add from './add/Add';
import Edit from './edit/Edit';
import ContactsRadio from './radio/ContactsRadio';
import Loading from '../common/elements/Loading';
import Alert from '../modals/Alert'

import * as securityActions from '../../store/actions/security.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';
import * as provisionActios from '../../store/actions/provision.actions';
import * as provisionSelectors from '../../store/selectors/provision.selectors';
import * as commonSelectors from '../../store/selectors/common.selectors';

import './Contacts.scss';
import { CONSTANTS } from '../../utils/Constants';

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRadio: '',
            contactsEdit: false,
            validate: true, 
            showLoading: false,
            openAlert: false,
            errorMsj: '',
            holderWillReceive: false,

            isAbilited : this.props.orderDetail.contacts !== null 
                && this.props.orderDetail.contacts.length > 0 
                && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ACTIVE

        }
    }
    onCloseAlert = () => {
        this.setState({
            openAlert: false
        })
    }
    selectRadio = (value) => {
        
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'datos de contacto',
            eventAction: 'click',
            eventLabel: value == 'on' ? 'yo recibiré al técnico': 'Otra persona recibirá al técnico',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            estadoElemento: value == 'on' ? 'yo recibiré al técnico': 'Otra persona recibirá al técnico',
            nonInteraction: '1',

            
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });


        if (value == 'off') {

            
            this.props.setScheduleContactEdit(false);

        } 

        this.setState({ selectedRadio: value });

        this.props.onContactClick();

    }

    saveContacts = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'datos de contacto',
            eventAction: 'click',
            eventLabel:  'guardar',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nonInteraction: '1',

             //datos genéricos
             userId: this.props.user.documentNumber,
             tipoCliente: this.props.user.documentType.toLowerCase(),
             numero: this.props.orderDetail.customer.phoneNumber,
             productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        let request = {
            contacts: [
                // {
                //     fullName: this.props.provision[0].customer.name,
                //     phoneNumber: this.props.provision[0].customer.phoneNumber
                // }
            ],
           // email: this.props.user.mail,
            psiCode: this.props.orderDetail.xaIdSt,
            holderWillReceive: true
        }
        
        this.setState({showLoading: true});
        this.props.setContactInfoUpdateFaultOrProvision(request).then(resp =>{
            this.setState({showLoading: false});
            if(resp.status == 'OK'){
               
                //this.props.provisionUpdate(resp.data);
                //this.props.setScheduleContactEdit(false);

            } else {
                this.setState({openAlert: true,errorMsj: resp.message})
            } 
        })
    }
    
    componentDidMount = () => {
        
        if (this.props.orderDetail.contacts !== null) {
            if (this.props.orderDetail.contacts.length > 0) {
                this.setState({ selectedRadio: 'off' });
                //this.props.setScheduleContactEdit(false);
            }

        } else {
            this.setState({ holderWillReceive: true});
        }
    }

    changeAbailability=()=>{
        this.setState({
            isAbilited : this.props.orderDetail.contacts !== null 
                && this.props.orderDetail.contacts.length > 0 
                && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ACTIVE
        })
    }
    render() {
        return (
            <div id="contacts">
                <Loading showLoading={this.state.showLoading}/>
                <h2 id="lbl-title-contacts" className="title">¿Quién recibirá al técnico?</h2>
                <div className="opcion">
                    <ContactsRadio onChange={this.selectRadio} />
                </div>
                {
                    (() => {
                        if (this.state.selectedRadio == 'off') {
                            if (this.props.orderDetail.contacts !== null) {
                                if (this.props.orderDetail.contacts.length > 0) {
                                    if (this.props.contactEdit) {
                                        return <Add onAddClick = {this.props.onContactClick} abailabilityContact = {this.changeAbailability}/>
                                    } else {
                                        return <Edit />
                                    }

                                } else {
                                    return <Add onAddClick = {this.props.onContactClick} abailabilityContact = {this.changeAbailability}/>
                                }
                            } else {
                                return <Add onAddClick = {this.props.onContactClick} abailabilityContact = {this.changeAbailability}/>
                            }


                        } else {
                            return (
                                <div className="act-ct">

                                    {/* {this.props.provision[0].contacts == null && <Button id="btn-save-contacts" variant="contained" color="secondary" className="btn-tz btn-save-contact" disabled>
                                        Guardar
                                    </Button>} */}

                                    {this.state.isAbilited ? 
                                        <Button id="btn-save-contacts" variant="contained" color="secondary" className="btn-tz btn-save-contact" onClick={this.saveContacts} >
                                            Guardar
                                        </Button>
                                        :
                                        <Button id="btn-save-contacts" variant="contained" color="secondary" className="btn-tz btn-save-contact" disabled>
                                            Guardar
                                        </Button>
                                        
                                    }

                                </div>
                            )
                        }
                    })()
                }

                <Alert openAlert={this.state.openAlert} errorMsj={this.state.errorMsj} errorMsjTitle={"¡Se ha producido un error!"} onClose={this.onCloseAlert} onAccept={this.onCloseAlert} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail,
        contactEdit: state.schedule.scheduleContactEdit
    }
}


const mapDispatchToProps = dispatch => {
    return {
        //provisionUpdate: (data) =>dispatch(provisionActios.provisionUpdate(data)),
        provisionUpdate: (data) =>dispatch(securityActions.provisionUpdate(data)),
        setScheduleContactEdit: (flag) => dispatch(scheduleActions.setScheduleContactEdit(flag)),
        //setContactInfoUpdateFaultOrProvision: (request) => dispatch(scheduleActions.setContactInfoUpdateFaultOrProvision(request))
        setContactInfoUpdateFaultOrProvision: (request) => dispatch(securityActions.setContactInfoUpdateFaultOrProvision(request))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);