import React, { Component } from 'react';
import CalendarReact from 'react-calendar';
import moment from 'moment';

export default class CalendarMob extends Component {

    constructor(props) {
      super(props)
      this.state = {
        disabledDates: this.getDisabledDays(),
        enabledDates: this.getEnabledDays(),
        date: this.props.valueDate? new Date(moment(this.state.enabledDates[0])):null,
      }
    }
  
    onChange = (date) => {
      this.props.saveDate(date);
      console.log(date)
      this.setState({date:date})
    }
  
    componentDidMount(){
        this.showSelectWeek();
        console.log("in mob mount",this.props.valueDate)
    }
    componentDidUpdate =(prepProps)=>{
      console.log("in mob",prepProps.valueDate ,this.props.valueDate)
      if (prepProps.valueDate !== this.props.valueDate ) {
        console.log(prepProps.valueDate)
        if (this.props.valueDate){
          console.log("holaaa")
          
          this.setState({date:new Date(moment(this.state.enabledDates[0]))})
          this.props.saveDate(new Date(moment(this.state.enabledDates[0])));
        } else{
          this.setState({date:null})
        }
      }
      
    }
    // componentDidUpdate(prevProps, prevState) {
    //   if (prevState.pokemons !== this.state.pokemons) {
    //     console.log('pokemons state has changed.')
    //   }
    // }
    // componentWillReceiveProps = (nextProps) => {
    //   if (nextProps.valueDate == true) {
    //     console.log(nextProps.valueDate)

    //     this.setState({date: nextProps.valueDate? new Date(moment(this.state.enabledDates[0])):null})
    //   }
    // }
    showSelectWeek = () => {
      let f = new Date(moment(this.state.enabledDates[0]));

      if(f.getDay() == 0){
          f.setDate(((f.getDate() - 7) + 1));
      }else{
          f.setDate(((f.getDate() - f.getDay()) + 1));
      }

      let maxDate = new Date(moment(f).add('day',14));
      let listaCalendario = document.getElementById(this.props.id).getElementsByTagName('abbr');
      console.log("listga",listaCalendario)
      for(let i = 7; i < listaCalendario.length; i ++){
          let matrixDate = listaCalendario[i].getAttribute("aria-label"); 
          let stringDate = matrixDate.split(" ");
          let fecha = new Date(moment(stringDate[4]  + '-' + this.getMonthNumber(stringDate[2]) + '-' + stringDate[0]))

          if(fecha < f){
            listaCalendario[i].style.display = 'none';
          }
          else if(fecha >= maxDate){
            listaCalendario[i].style.display = 'none';
          }
      }
   }
  
  getMonthNumber = (monthDescription) => {
    let mm = '';
    switch (monthDescription.toUpperCase()) {
        case 'ENERO': mm = '01'; break;
        case 'FEBRERO': mm = '02'; break;
        case 'MARZO': mm = '03'; break;
        case 'ABRIL': mm = '04'; break;
        case 'MAYO': mm = '05'; break;
        case 'JUNIO': mm = '06'; break;
        case 'JULIO': mm = '07'; break;
        case 'AGOSTO': mm = '08';break;
        case 'SEPTIEMBRE': mm = '09'; break;
        case 'OCTUBRE': mm = '10'; break;
        case 'NOVIEMBRE': mm = '11'; break;
        case 'DICIEMBRE': mm = '12'; break;
    }
    return mm;
  }
  
  getEnabledDays = () => {
    let arrayDates = [];
    this.props.cupos.map(item => {
      let cupos = 0;
      item.slot.map(slotDay => {
        cupos = cupos + Number(slotDay.quantity);
      })
      if(cupos>0){
        arrayDates.push(moment(item.day).format('YYYY/MM/DD'));
      }
    })

    return arrayDates;
  }

  getDisabledDays = () => {
    let arrayDates = [];
    this.props.cupos.map(item => {

      let cupos = 0;
      item.slot.map(slotDay => {
        cupos = cupos + Number(slotDay.quantity);
      })
      if(cupos === 0){
        arrayDates.push(new Date(moment(item.day).format('YYYY/MM/DD')));
      }
    })

    return arrayDates;
  }
  ToCalendarDay = (fecha)=>{
    return  moment(new Date(fecha)).format("YYYY-MM-DD")
  }
    render() {
      const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];

      return (
        <div id={this.props.id}>
          <CalendarReact
              id = "calendar-schedule"
              className={this.props.clase}
              onChange={this.onChange}
              value = {this.state.date }
              selectRange={false}
              showNeighboringMonth={false}
              showFixedNumberOfWeeks={this.props.moreWeeks}
              navigationLabel={({ date, view, label }) => 
              <>
                <span className="date-mob">{this.state.date != null ? this.ToCalendarDay(this.state.date).split('-')[2] : 'Día' }</span> <span className="date-mob">{monthNames[date.getMonth()]}</span> <span className="date-mob">{date.getFullYear()}</span>
              </>
              }
              tileClassName={this.props.tileClassName}
              minDate={new Date(moment(this.props.cupos[0].day).format("YYYY/MM/DD"))}
              maxDate={new Date(moment(this.props.cupos[this.props.cupos.length-1].day).format("YYYY/MM/DD"))}
              tileDisabled={({date, view}) =>
                (view === 'month') && // Block day tiles only
                this.state.disabledDates.some(disabledDate =>
                  date.getFullYear() === disabledDate.getFullYear() &&
                  date.getMonth() === disabledDate.getMonth() &&
                  date.getDate() === disabledDate.getDate()
                )}
                      
          />
        </div>
      );
    }
  }
 