import React, { Component } from 'react';
import CalendarReact from 'react-calendar';
import moment from 'moment';

export default class Calendar extends Component {

    constructor(props) {
      super(props)
      this.state = {
        disabledDates: this.getDisabledDays(),
        enabledDates: this.getEnabledDays(),
        date :  this.getEnabledDays().length>0 ? new Date(moment( this.getEnabledDays()[0])):null
      }
    }

  onChange = (date) => {
    this.props.saveDate(date);
    this.setState({
      date:date
    })
  }
  componentDidMount(){
    this.props.saveDate(this.state.date);
  }
  getEnabledDays = () => {
    let arrayDates = [];
    this.props.cupos.map(item => {
      let cupos = 0;
      item.slot.map(slotDay => {
        cupos = cupos + Number(slotDay.quantity);
      })
      if(cupos>0){
        arrayDates.push(moment(item.day).format('YYYY/MM/DD'));
      }
    })
    
    return arrayDates;
  }

  getDisabledDays = () => {
    let arrayDisDates = [];
    this.props.cupos.map(item => {
      let cupos = 0;
      item.slot.map(slotDay => {
        cupos = cupos + Number(slotDay.quantity);
      })
      if(cupos === 0){
        arrayDisDates.push(new Date(moment(item.day).format('YYYY/MM/DD')));
      }
    })

    return arrayDisDates;
  }
  
    render() {

      const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];

      return (
        <div id={this.props.id}>
          {console.log("datete date",this.state.date)}
          <CalendarReact
              className={this.props.clase}
              id="calendar-schedule"
              onChange={this.onChange}
              value = {this.state.date}
              selectRange={false}
              showNeighboringMonth={false}
              showFixedNumberOfWeeks={this.props.moreWeeks}
              navigationLabel={({ date, view, label }) => 
              <>
                <span className="date-navigation">{monthNames[date.getMonth()]} </span><span className="date-navigation-year">{date.getFullYear()}</span>
              </>
              }
              //tileClassName={this.props.tileClassName}
              minDate={new Date(moment(this.props.cupos[0].day).format("YYYY/MM/DD"))}
              maxDate={new Date(moment(this.props.cupos[this.props.cupos.length-1].day).format("YYYY/MM/DD"))}
              tileDisabled={({date, view}) =>
                (view === 'month') && // Block day tiles only
                this.state.disabledDates.some(disabledDate =>
                  date.getFullYear() === disabledDate.getFullYear() &&
                  date.getMonth() === disabledDate.getMonth() &&
                  date.getDate() === disabledDate.getDate()
                  
                )}
                //date.getDate() === 26

          />
        </div>
      );
    }
  }
  