import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './TomorrowRadio.scss';

export default class TomorrowRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'NEXT'
        }
    }
    handleChange = (event) => {
        this.setState({
            selected: event.target.value
        });
        this.props.onChangeTomorrow(event.target.value);
    }

    componentDidMount =()=>{
        this.setState({selected: ''})
        if(this.props.disabledTurns){
            this.setState({
                selected: ''
            })
        }
    }

    render() {
        const { descriptionDay, valueDay } = this.props;
        return (
            <div id="tomorrow-radio">
                <FormControl component="fieldset">
                
                    <RadioGroup
                        aria-label="position"
                        className="wrp-radio"
                        name="position"
                        id="rg-days-schedule"
                        value={this.props.disabledTurns? '':this.state.selected}
                        onChange={this.handleChange} 
                        row>
                        <FormControlLabel
                            value={valueDay}
                            id="lbl-day-schedule"
                            control={<Radio id="rb-day-schedule" color="primary" />}
                            label={descriptionDay}
                            labelPlacement="top"
                            disabled = {this.props.disabledOption}
                            className={`radio-item ${!this.props.disabledOption ? (!this.props.disabledTurns && this.state.selected == valueDay && 'border-on') : 'item-off'}`}
                            //className={`radio-item ${!this.props.disabledOption ? (!this.props.disabledTurns && this.state.selected == 'AM' && 'border-on') : 'item-off'}`}
                        />
                        </RadioGroup>

                </FormControl>

            </div>

        )
    }
}