import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './Returned.scss';
import { isMobile } from "react-device-detect";
import iconChevronDown from '../../../resources/svg/home/detail/iconChevronDown.svg';
import { CONSTANTS } from '../../../utils/Constants';

class Returned extends Component {

    constructor(props) {
        super(props)

        this.state = {
            collapse: false,
            collapseAddress: false,
            iconService: ''
        }
    }
    capitalizeUserName = () => {
        let name = this.props.orderDetail.customer.name.trim().split(' ')[0];
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()

    }
    render() {
        
        return (<div id="returned">
                    <div className="wrp-returned">
                        <div className="container">
                            <div className="box-full-returned">
                                {
                                    this.props.orderDetail.activityType === CONSTANTS.PRODUCT.PROVISION_TYPE ?
                                    <div>
                                        <div className="head-message">
                                            <p id="lbl-subtitle" className="subtitle">{this.props.orderDetail.subReasonNotDone}</p>
                                        </div>                                
                                        {this.props.orderDetail.activeStatus != CONSTANTS.STATUS.WO_NOTDONE_TRAZA && 
                                            <div className="item-returned">
                                                <div className="d-flex">
                                                        <img id="img-icon-returned" src={iconChevronDown} alt=""/>
                                                        <p className="item-message">{this.props.orderDetail.actionNotDone}</p>
                                                </div>
                                            </div> 
                                        }
                                    </div>
                                    :
                                    <div>
                                         <div className="head-message">
                                            <p id="lbl-subtitle" className="subtitle">{this.capitalizeUserName()+ ", te contactaremos en las próximas horas para coordinar la visita del técnico." }</p>
                                        </div>  
                                    </div>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>)
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail, 
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Returned);