import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../../utils/Constants';

import iconTool from '../../../resources/svg/home/fault/iconFaultTools.svg';

import * as provisionSelectors from '../../../store/selectors/provision.selectors';


class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labelHeadProduct: "Avería registrada",
            colorHeadProduct: "",
            borderProduct: ""
        }
    }

    componentDidMount = () => {

        if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOT_DONE){
            this.setState({
                borderProduct: "box-border-red",
                colorHeadProduct: "head-red",
            })
        } else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.CANCEL)
            this.setState({
                borderProduct: "box-border-red",
                colorHeadProduct: "head-red",
            })
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.COMPLETE) {
            this.setState({
                labelHeadProduct: "Avería registrada",
                colorHeadProduct: "head-green",
                borderProduct: "box-border-green"
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TRIAJE_LIQUIDADO) {
            this.setState({
                labelHeadProduct: "Avería registrada",
                colorHeadProduct: "head-green",
                borderProduct: "box-border-green"
            })
        }
        else if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_NOTDONE_TRAZA) {
            this.setState({
                labelHeadProduct: "Avería registrada",
                borderProduct: "box-border-greey"
            })
        }

    }
    detalleDeAveria = () => {
        let detalle_arr = this.props.orderDetail.productName.split(' ') ;
        const res = detalle_arr.reduce((acc, val) => val.toLowerCase() == 'averia'  ? acc += '': acc+=val+" ", 'Avería en ')
        return res;
    }


    render() {
        
        return (
            <div id="product-fault">
                <div className={` box-product-info box-border ${ this.state.borderProduct}`}>
                    <div id="lbl-head-product-fault" className= {`text-head ${this.state.colorHeadProduct}`}>
                        {this.state.labelHeadProduct.toUpperCase()}
                    </div>
                    <div className="wrp-product">
                        <div className="info d-flex">
                            <img id="img-icon-product-fault" className="icon" src={iconTool} alt="" />
                            <div id="lbl-name-product-fault" className="name">
                                {this.detalleDeAveria()}
                            </div>
                        </div>
                    </div>
                    {(() => {
                        if(this.props.orderDetail.xaRequest != null){
                                                 
                            return <div className="wrp-text">
                                        <div className="info d-flex">
                                            <div id="lbl-code-product-fault" className="code"> {"Código de atención:"} </div>
                                            <p id = "lbl-code_desc-product-fault" className="code code-bold"> {this.props.orderDetail.xaRequest} </p>
                                        </div>
                                    </div>
                        }else {
                            return null;
                        }
                    })()
                    }   
                </div>

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail //provisionSelectors.getOrderById(state.security.ordersIdSelected, state.security.ordersData), 
    }
}


const mapDispatchToProps = dispatch => {
    return {

    }
}
    export default connect(mapStateToProps, mapDispatchToProps)(Product);