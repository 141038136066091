import React, { Component } from 'react';
import Header from '../../header/Header';
import './CancelMobile.scss';
import iconFeedback from '../../../resources/svg/cancel/iconFeedback.svg';
import CancelRadio from '../radio/CancelRadio';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Footer from '../../common/elements/Footer';
import BotonVolver from '../../common/elements/ReturnButton';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00A9E0'
            
        },
    },
  });


   


class CancelMobile extends Component {

    constructor(props) {
        super(props)

    }

    

    render(){
        const {selectRadio, state, onClick, onBlur, onChangeValue, onBack, validHeader, onHeaderClick} = this.props; 
        return (
            <div>
                <Header onHeaderClick = {onHeaderClick} />  
                <section id="cancelMobile">
                    <div className="container">
                        <div className={validHeader===1?"content-body-wo-header":"content-body"}>
                                <div className="box-info">
                                    <BotonVolver onClick={onBack} />
                                </div>  
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img className="llamada" src={iconFeedback} alt="" />
                                    <p id="lbl-title-cancel" className="detalle" dangerouslySetInnerHTML={{__html: state.title}} />
                                    <div className="opcion">
                                        <CancelRadio 
                                        onChange={selectRadio} 
                                        showReason={state.showReason}
                                        />
                                    </div>

                                    { state.showReason &&

                                    <MuiThemeProvider theme={theme}>

                                        <TextField
                                                id="txt-reason-cancel"
                                                className="text-area"
                                                onBlur = {onBlur}
                                                onChange={onChangeValue}
                                                inputProps={{maxlength:"140"}}
                                                multiline
                                                rows="4"
                                                variant="outlined"
                                                color = "primary"
                                            /> 
                                    </MuiThemeProvider>        
                                    }

                                    <Button id="btn-cancelorder-cancel"  onClick={onClick} className="buttonCancel" variant="contained" color="primary" disabled={state.disabledCancel} >
                                        CANCELAR PEDIDO
                                    </Button>
                                </div>
                            </div> 
                    </div>          
                </section>
                <Footer/>
            </div>
        )
    }


}


export default CancelMobile;