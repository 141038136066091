import React, { Component } from 'react';
import { connect } from 'react-redux'; // Asegúrate de importar connect
import './DeliveryOrder.scss';
import { CONSTANTS } from '../../utils/Constants';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import Footer from '../../components/common/elements/Footer';
import iconBack2 from '../../resources/svg/home/iconArrowBack.svg';
import iconArrowRigth from '../../resources/svg/home/iconArrowRight.svg';
import iconDelivery from '../../resources/svg/home/tracking/iconDelivery.svg';
import HeaderDelivery from '../headerDelivery/HeaderDelivery';
import * as securityActions from '../../store/actions/security.actions';
import Loading from '../common/elements/Loading';
import Alert from '../modals/Alert';

class DeliveryOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attemptsPerDay: true,
      showLoading: false,
      cards: [this.props.orderDeliveryList],

      openAlert: false,
      errorMsj: '',
    };
  }
  componentDidMount() {}

  onContinueDeliveryTracking = (numList) => {
    //todo: retornar aditionaldata, key: equivalence

    
    this.setState({ showLoading: true });
    this.props.setDeliveryOrder([this.state.cards[0].deliveryOrders[numList]]);
    let request = {
      documentType: this.props.docType,
      documentNumber: this.props.docNumber,
      documentNumber: this.props.docNumber,
      deliveryID: this.state.cards[0].deliveryOrders[numList].deliveryId,
    };
    this.props.getDeliveryOrderDetail(request).then((resp) => {
      // console.log('respuesta de get Delivery Order Detail', resp.data);
      // console.log('respuesta estatus', resp.status);
      if (resp && resp.status === 'OK') {
        this.props.setOrderDeliveryDetail(resp.data);
        this.setState({ showLoading: false });
        this.props.history.push({
          pathname: CONSTANTS.PATH.DELIVERY_TRACKING,
        });
      } else {
        // Manejar el caso en que el status no sea 'OK'
        this.setState({ showLoading: false });
        this.setState({
          errorMsj:
            'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
          openAlert: true,
        });
      }
    });
  };

  onCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  onAccept = () => {
    this.setState({
      openAlert: false,
    });

    if (this.state.attemptsPerDay) {
      this.props.history.push({
        pathname: CONSTANTS.PATH.LOGIN,
      });
    }
  };


  capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || string.length === 0) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  getShipmentTypeMessage = (shipmentItems) => {
    shipmentItems = Array.isArray(shipmentItems)
      ? shipmentItems
      : [shipmentItems];

    const hasIMEI = shipmentItems.some((item) => item.resourceType === 'IMEI');
    const hasICCID = shipmentItems.some(
      (item) => item.resourceType === 'ICCID'
    );

    if (hasIMEI && hasICCID) return 'Equipo celular y chip';
    if (hasICCID) return 'Solo chip';
    if (hasIMEI) return 'Solo equipo celular';
    return 'Tipo de envío desconocido';
  };

  getDeliveryDate(additionalData) {
    // Verificar si additionalData es un array
    if (!Array.isArray(additionalData)) {
      return '';
    }


    // Buscar el objeto con key 'deliveryDate'
    const deliveryDateObj = additionalData.find(
      (item) => item.key === 'deliveryDate'
    );
    // Retornar el valor si existe y no es null, de lo contrario retornar ''
    let valor = deliveryDateObj && deliveryDateObj.value !== null
                  ? deliveryDateObj.value : '';

    if(valor == '' ) return '';
    return valor.split('T')[0].split('-').reverse().join('/');     
  }

  back = () => {
    this.setState({
      openAlert: false,
    });

    if (this.state.attemptsPerDay) {
      this.props.history.push({
        pathname: CONSTANTS.PATH.LOGIN,
      });
    }
  };

  render() {
    const name = this.state.cards[0].relatedParty.name.split(' ')[0];
    return (
      <section id="deliveryOrder">
        <Loading id="loading-login" showLoading={this.state.showLoading} />
        <HeaderDelivery />
        <div className="container-backButton">
          <div className="content-backButton">
            <Button
              disableRipple
              onClick={this.back}
              className="back-button"
              startIcon={<Avatar src={iconBack2} />}
            >
              Volver
            </Button>
          </div>
        </div>
        <div className="container">
          <div className="content-text">
            <h1>¡Hola {name}!</h1>
            <p>
              Selecciona el pedido para ver los <br /> detalles
            </p>
          </div>
          <div className="content-cards">
            {this.state.cards[0].deliveryOrders.map((card, index) => (
              <div
                className="card"
                key={card.deliveryId}
                onClick={() => this.onContinueDeliveryTracking(index)}
              >
                <div className="content-icon-card">
                  <img src={iconDelivery} alt="iconDelivery" />
                </div>
                <div className="information-card">
                  <span className="container-estado">
                    <p
                      className="estado"
                      style={
                        card.checkpoints.status === 'CANCELADO'
                          ? { background: '#FF374A' }
                          : card.checkpoints.status === 'RECHAZADO'
                          ? { background: '#FF374A' }
                          : card.checkpoints.status === 'PRECANCELADO'
                          ? { background: '#FF374A' }
                          : card.checkpoints.status === 'ENTREGADO' && card.checkpoints.status !== 'NO ENTREGADO'
                          ? { background: '#5CB615' }
                          : {}
                      }
                    >
                      {
                        /* card.checkpoints.status === 'CANCELADO'
                          ? 'Pedido cancelado'
                          : card.checkpoints.status === 'RECHAZADO'
                          ? 'Pedido cancelado'
                          : card.checkpoints.status === 'PRECANCELADO'
                          ? 'Pedido cancelado'
                          : card.checkpoints.status === 'ENTREGADO' &&
                            card.checkpoints.some(
                              (checkpoint) => checkpoint.status === 'NO ENTREGADO'
                            )
                          ? 'Pedido no entregado'
                          : card.checkpoints.status === 'ENTREGADO'
                          ? 'Pedido entregado'
                          : 'Pedido confirmado' */

                          card.additionalData.map((key) => {
                            if (key.key === 'equivalence') {
                              return this.capitalizeFirstLetter(key.value);
                            }
                          })
                      }
                    </p>
                  </span>
                  <p key={index} className="tipe">
                    {this.getShipmentTypeMessage(card.shipmentItems)}
                  </p>
                  <p className="order">
                    Nro. de orden: {card.productOrder.orderId}
                  </p>
                  <p className="solicitud">
                    Solicitado el:{' '}
                    {card.startTrackingDate
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/')}
                  </p>
                  {this.getDeliveryDate(card.additionalData) !== '' && (
                    <p className="entrega">
                      Entrega prevista:{' '}
                      {this.getDeliveryDate(card.additionalData)}
                    </p>
                  )}
                </div>
                <img src={iconArrowRigth} alt="iconArrowRigth" />
              </div>
            ))}
          </div>
        </div>
        <Alert
          id="mdl-error-token"
          openAlert={this.state.openAlert}
          errorMsj={this.state.errorMsj}
          onClose={this.onCloseAlert}
          onAccept={this.onAccept}
        />
        <Footer />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  // tu mapStateToProps...
  orderDeliveryList: state.security.orderDeliveryList,
  docNumber: state.security.documentNumber,
  docType: state.security.documentType,
  deliveryOrder: state.deliveryOrder,
  orderDeliveryDetail: state.orderDeliveryDetail,
});

const mapDispatchToProps = (dispatch) => ({
  // tu mapDispatchToProps...
  setDeliveryOrder: (deliveryOrder) =>
    dispatch(securityActions.setDeliveryOrder(deliveryOrder)),

  setOrderDeliveryDetail: (orderDeliveryDetail) =>
    dispatch(securityActions.setOrderDeliveryDetail(orderDeliveryDetail)),

  getDeliveryOrderDetail: (request) =>
    dispatch(securityActions.getDeliveryOrderDetail(request)),
});

// export default DeliveryOrder
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOrder);
// export default connect(DeliveryOrder);
