import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../utils/Constants';
import { isMobile } from "react-device-detect";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import Frame from '../frame/Frame';
import Rating from './../rating/Rating';
import Header from '../header/Header';
import Footer from '../common/elements/Footer';
import Loading from '../common/elements/Loading'
import SimpleMessage from '../common/elements/SimpleMessage'
import AlertMessage from '../common/elements/AlertMessage'
import { animateScroll as scroll } from 'react-scroll';

import iconTrioMovPurple from '../../resources/svg/home/iconTrio.svg';
import iconDevolution from '../../resources/svg/home/detail/iconDevolution.svg';
import iconArrow from '../../resources/svg/home/iconArrow.svg';
import iconUser from '../../resources/svg/home/iconUser.svg';
import iconLocation from '../../resources/svg/home/iconPlaceFull.svg';
import iconWarning from '../../resources/svg/home/iconWarning.svg';
import iconAccept from '../../resources/svg/home/iconAccept.svg';
import iconArrowNMO from '../../resources/svg/login/iconArrow.svg';

import * as securityActions from '../../store/actions/security.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as ratingActions from '../../store/actions/rating.actions';
import * as faultActions from '../../store/actions/averia.actions';
import * as provisionSelectors from '../../store/selectors/provision.selectors';
import * as commonSelectors from '../../store/selectors/common.selectors';
import Alert from '../modals/Alert';
import ReturnButton from '../common/elements/ReturnButton';

//Components Components 
import Product from './product/Product';
import Schedule from './schedule/Schedule';
import Technical from './technical/Technical';
import Tracking from './tracking/Tracking';
import TabsHome from './tabHome/TabHome';
import Payment from './payment/Payment';
import Returned from './returned/Returned';

import './Home.scss';

import DevolutionWeb from './devolution/DevolutionWeb';


class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoading: false,
            collapse: false,
            openAlert: false,
            errorMsj: '',
            errorMsjTitle: '',
            openRating: false,
            starCount: 0,
            ratingSent: false,
            selectedStar: false,
            questionList: [],
            answerRating: '',
            objectRatingKey: '',
            isFullMap:false,
            openTooltip:false,
            iconSelectComp: iconArrowNMO,
            disabledContinue:true,
            valueAbsentOption: '',
            isFromUpdateActivity: false,

            isDevolution: this.props.orderDetail.activeStatus === CONSTANTS.STATUS.CANCELADA_ATIS 
                && this.props.orderDetail.isUpFront === true 
                && this.props.orderDetail.customer.documentType === 'DNI',

            isDetailBlocked: this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE_PAGO 
                || this.props.orderDetail.activeStatus === CONSTANTS.STATUS.CANCELADA_ATIS
                || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.FINALIZADO 
                || this.props.orderDetail.activeStatus == CONSTANTS.STATUS.TERMINADA, 

            isNotDoneAvalaible: this.props.orderDetail.actionNotDone != null 
                && this.props.orderDetail.actionNotDone != ''
                && this.props.orderDetail.subReasonNotDone != null 
                && this.props.orderDetail.subReasonNotDone != '',

            isNotDoneReschedule: this.props.orderDetail.subReasonNotDone != null 
                && this.props.orderDetail.subReasonNotDone != ''
        }
    }

    onClickSchedule = () => {

        //Se valida el tiempo límete de sesión
        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.getCoupos();
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: this.props.schedule != null ? 'reagendar' : 'agendar pedido',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

    }

    getCoupos = () => {

        this.setState({ showLoading: true })
        this.props.getScheduleAvailability().then(resp => {
            this.setState({ showLoading: false })

            if (resp.status === "ERROR") {
                this.setState({
                    errorMsj: resp.message,
                    openAlert: true
                })
            } else {
                this.props.history.push({
                    pathname: CONSTANTS.PATH.SCHEDULE
                });
            }
        })

    }

    componentDidMount() {
        scroll.scrollToTop();

        
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'provision detalle',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });

        if (this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART) {
            window.dataLayer.push({
                event: 'atm.event',
                eventCategory: 'provision detalle',
                eventAction: 'view',
                eventLabel: 'datos de tu técnico asignado',
                valorElemento: this.props.orderDetail.activeStatus,
                correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
                nonInteraction: '1',
                //datos genéricos
                userId: this.props.user.documentNumber,
                tipoCliente: this.props.user.documentType.toLowerCase(),
                numero: this.props.orderDetail.customer.phoneNumber,
                productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
            });

            this.setState({
                openTooltip: this.receiveDelay()
            })
        }

        //Obtener el Rating
        this.apiGetRating();

        //Actualiza cada vez que refresca la página o vuelve a entrar
        this.apiGetProvision();

    }

    apiGetProvision = () => {

        let request = {
            documentNumber: this.props.docNumber,
            orderCode: this.props.orderCode
        };
        this.props.getProvisionDetail(request).then(response => {
            if (response.status === 'OK') {
                this.apiGetOrders();
                console.log("provision actualizada");

            } else {
                if(response.status == 'ERROR_SECURITY') {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LOGIN
                    });
                }
            }
        })
        
    }

    apiGetOrders = () => {
        this.props.getScheduledOrder().then(resp => {
            if (resp.status === 'OK') {
                console.log("agenda actualizada");
            } else {
                if(resp.status == 'ERROR_SECURITY') {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LOGIN
                    });
                }
            }
        });
    }

    receiveDelay (){
        setTimeout(() => {  
            this.setState({
                openTooltip: false
            })
        }, 4000);
        
        return true;
    }  

    apiGetRating = () => {

        if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.COMPLETE
            || this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_NOT_DONE){

            let objectRating = [];
            if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.COMPLETE){
                objectRating = this.props.orderDetail.rating.filter( item => item.keyName == CONSTANTS.KEY.COMPLETE);
                this.state.objectRatingKey = CONSTANTS.KEY.COMPLETE;
            }else if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_NOT_DONE){
                objectRating = this.props.orderDetail.rating.filter( item => item.keyName == CONSTANTS.KEY.WO_NOT_DONE);
                this.state.objectRatingKey = CONSTANTS.KEY.WO_NOT_DONE;
            }
            console.log(this.state.objectRatingKey)
            if(objectRating.length == 0){
                let request = {
                    documentNumber: this.props.user.documentNumber,
                    key: this.state.objectRatingKey
                }
    
                this.props.getRating(request).then(resp => {
                    if(resp.status === "OK"){
                        this.setState({
                            questionList: resp.data.questions,
                            openRating: true
                        })
                    }
                });
            }
        }
    }

    apiSetRating = () => {
        let objectRating = this.state.questionList.filter( item => item.rating == this.state.starCount);

        let request = {
            documentNumber: this.props.user.documentNumber,
            key: this.state.objectRatingKey,
            provisionId: this.props.orderDetail.idProvision,
            title: objectRating[0].title,
            rating: this.state.starCount,
            question: objectRating[0].question,
            answer: this.state.answerRating 
        }

        this.props.setRating(request).then(resp => {
            if(resp.status == 'OK'){
                this.props.provisionUpdate(resp.data);
            }
        });
    }

    onCloseAlert = () => {
       
        if(this.state.isFromUpdateActivity == true){
            this.apiGetProvision();
            this.setState({
                isFromUpdateActivity: false
            })
        }
        this.setState({
            openAlert: false
        })
    }

    onCloseRating = () => {
        this.setState({ openRating: false })
    }

    onAcceptRating = () => {
        this.setState({ ratingSent: true }) //Muestra el otro mensaje
        if(this.state.starCount === 4 || this.state.starCount === 5){
            this.setState({ openRating: false })
        }
        this.apiSetRating();
    }

    addressCapitalize = () => {
        let width = window.innerWidth;
        
        let address = this.props.orderDetail.customer.address
        if(address != null){
            if(width <= 320){
                if(address.length > 35 ){
                    address = address.slice(0, 35) + '... ';
                }
            } else {
                if(address.length > 40 ){
                    address = address.slice(0, 40) + '... ';
                }
            }
        
            return  address.split(' ').map(item => (
                item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() + ' '
            ));
        }else{
            return '';
        }
    }

    onChangeCollapse = (e) => {
        if(this.props.orderDetail.activeStatus != CONSTANTS.STATUS.PENDIENTE_PAGO && this.props.orderDetail.activeStatus != CONSTANTS.STATUS.CANCELADA_ATIS){
            this.setState({ collapse: !this.state.collapse });
            window.dataLayer.push({
                event: 'atm.event',
                eventCategory: 'provision detalle',
                eventAction: 'click',
                eventLabel: 'detalle de mi pedido',
                valorElemento: this.props.orderDetail.activeStatus,
                correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
                idPedido: this.props.orderDetail.xaRequest,
                nombrePedido: this.props.orderDetail.productName.toLowerCase(),
                idCita: '',
                nonInteraction: '1',
                //datos genéricos
                userId: this.props.user.documentNumber,
                tipoCliente: this.props.user.documentType.toLowerCase(),
                numero: this.props.orderDetail.customer.phoneNumber,
                productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
            });
        }
    }

    onClickCancel = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: 'cancelar pedido',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.props.history.push({
            pathname: CONSTANTS.PATH.CANCEL,
            onTabClick : this.onTabClick
        });

    }

    back = () => {

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: 'volver',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.props.history.goBack();
    }

    onChangeValue = (value) => {
        this.setState({ 
            selectedStar: true,
            starCount: Number(value)
        })
    }

    onChangeText = event => {
        this.setState({ answerRating: event.target.value})
    }

    onOpenMapView = () => {

        this.props.history.push({
            pathname: CONSTANTS.PATH.MAP
        });
    }

    onOpenPlaces = () => {
        window.open(this.props.orderDetail.upFront.cipUrl);
    }

    onOpenAddress = () => {
        if(!this.state.isDetailBlocked){
            window.dataLayer.push({
                event: 'atm.event',
                eventCategory: 'provision detalle',
                eventAction: 'click',
                eventLabel: 'dirección de instalación',
                valorElemento: this.props.orderDetail.activeStatus,
                correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
                idPedido: this.props.orderDetail.xaRequest,
                nombrePedido: this.props.orderDetail.productName.toLowerCase(),
                idCita: '',
                nonInteraction: '1',
                //datos genéricos //datos genéricos
                userId: this.props.user.documentNumber,
                tipoCliente: this.props.user.documentType.toLowerCase(),
                numero: this.props.orderDetail.customer.phoneNumber,
                productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
            });

            this.props.history.push({
                pathname: CONSTANTS.PATH.ADDRESS,
                onAddressClick : this.onTabClick
            });
            // this.props.history.push({
            //     pathname: CONSTANTS.PATH.CONTACTS,
            //     onTabClick : this.onTabClick
            // });
        }
    }

    onOpenContact = () =>{
        if(!this.state.isDetailBlocked){
            window.dataLayer.push({
                event: 'atm.event',
                eventCategory: 'provision detalle',
                eventAction: 'click',
                eventLabel: '¿quién atenderá al técnico?',
                valorElemento: this.props.orderDetail.activeStatus,
                correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
                idPedido: this.props.orderDetail.xaRequest,
                nombrePedido: this.props.orderDetail.productName.toLowerCase(),
                idCita: '',
                nonInteraction: '1',
                //datos genéricos
                userId: this.props.user.documentNumber,
                tipoCliente: this.props.user.documentType.toLowerCase(),
                numero: this.props.orderDetail.customer.phoneNumber,
                productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
            });

            this.props.history.push({
                pathname: CONSTANTS.PATH.CONTACTS,
                onTabClick : this.onTabClick
            });
        }
    }

    onOpenDevolution = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: 'Devolución de pago',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.props.history.push({
            pathname: CONSTANTS.PATH.DEVOLUTION
        });
    }

    onFullMap = () => {
        if(this.state.isFullMap){
            this.setState({ isFullMap: false })
        }else{
            this.setState({ isFullMap: true })
        }

        this.props.setUpdateShowLocation().then(resp => {
            
        });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: this.state.isFullMap ? 'reducir mapa' : 'expandir mapa',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });
    }

    onTabClick = () => {
        if(!commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }

    onChangeDocType = event => {
        
        this.setState({
            disabledContinue:false,
            valueAbsentOption: event.target.value
        });

        this.inputDocTypeFull();
    }

    inputDocTypeFull = () => {
        let labelType = document.querySelector('#home .container .box-nmo .option .formControlDocument .MuiInputLabel-outlined.MuiInputLabel-shrink');
        labelType.style.color = "#50535A";

        let borderType = document.querySelector('#home .container .box-nmo .option .formControlDocument .MuiOutlinedInput-notchedOutline');
        borderType.style.border = "1px solid #50535A";
    }

    onContinue = () => {

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'provision detalle',
            eventAction: 'click',
            eventLabel: 'continuar cliente ausente',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: this.props.orderDetail.customer.mail ? commonSelectors.sha256(this.props.orderDetail.customer.mail) : '',
            idPedido: this.props.orderDetail.xaRequest,
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            idCita: '',
            nonInteraction: '1',
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim(): ''
        });

        this.setState({ showLoading: true })

        this.props.setUpdateActivity(this.state.valueAbsentOption).then(resp => {

            this.setState({ showLoading: false })
            if(resp.status === 'OK'){
                this.props.history.push({
                    pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.ABSENT
                });
            }else{
                this.setState({
                    errorMsj: resp.message,
                    openAlert: true,
                    isFromUpdateActivity: true
                })
            }

        });

    }

    render() {

        return (
            <div className='contenedor-home'>
                <Loading showLoading={this.state.showLoading} />
                <Header onHeaderClick = {this.onTabClick} />
                { 
                (() => {
                    if(this.state.isFullMap){
                        {
                            scroll.scrollToTop();
                        }
                        return <Frame url={this.props.orderDetail.woPreStart.trackingUrl} onFullMap={this.onFullMap} isFullMap = {this.state.isFullMap}/>;
                    }else{
                        return <div id="home">
                            <div className={this.props.validHeader === 1 && isMobile ? "content-body-wo-header":"content-body"}>
                                {this.props.orderDetail.activeStatus === CONSTANTS.STATUS.ADDRESS_CHANGED?
                                    <div className="addressChangeBox">
                                        <div className="container">
                                            <div className="box-white d-flex">
                                                <div className="icon">
                                                    <img id="img-warning-home" src={iconWarning} alt="" />
                                                </div>
                                                <div className="name">
                                                    <p id="lbl-warning-home">Estamos validando tu nueva dirección. Nos comunicaremos contigo en breve.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }

                                <div className="container">
                                    {this.props.orderList.length > 1 && 
                                        <div className="icon-back">
                                            <ReturnButton id="btn-return-home" onClick={this.back} /> 
                                        </div>}

                                    {(() => {
                                        if (this.props.orderDetail != null && this.props.schedule != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_INIT) {
                                            return <Technical />
                                        } else if (this.props.orderDetail != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART) {
                                            
                                            return <Technical />
                                        } else if (this.props.orderDetail != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE) {
                                            return <Technical />
                                        } else if (this.props.orderDetail != null && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE_TRAZA) {
                                            return <Technical />
                                        } else if (this.props.orderDetail != null && this.props.schedule == null) {
                                            return <Product onClickSchedule={this.onClickSchedule} />
                                        } else if (this.props.orderDetail != null && this.props.schedule != null) {
                                            if(this.props.schedule.scheduleStatus == CONSTANTS.STATUS.CANCEL && this.props.orderDetail.activeStatus == CONSTANTS.STATUS.ACTIVE){
                                                return <Product onClickSchedule={this.onClickSchedule} />
                                            }else{
                                                return <Schedule onReschedule={this.onClickSchedule} />
                                            }
                                           
                                        } else {
                                            
                                            return <Product />
                                        }
                                    })()}

                                    <Tracking onOpenMapView = {this.onOpenMapView}/>
                                    
                                    { (() => {
                                        if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRESTART && this.props.orderDetail.woPreStart.availableTracking == true){
                                            return <Frame url={this.props.orderDetail.woPreStart.trackingUrl} onFullMap={this.onFullMap} openTooltip={this.state.openTooltip}/> 
                                        }
                                        else{
                                            return null;
                                        }
                                    })()
                                    }

                                    {(() => {
                                        if( this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART && this.props.orderDetail.textReturn != null && this.props.orderDetail.textReturn != ''){
                                             return <div className="box-recycle">
                                                <div className="d-flex">
                                                    <div className="icon">
                                                        <img id="img-accept-home" src={iconAccept} alt="" />
                                                    </div>
                                                    <div className="info">
                                                        <p id="lbl-accept-home" className="text">{this.props.orderDetail.textReturn}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }else{
                                            return null;
                                        }
                                    })()
                                    }

                                    {
                                    //NMO
                                    (() => {
                                        return this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE     
                                        &&  <div>
                                                <SimpleMessage activityType={this.props.orderDetail.activityType}/>
                                                <div className="box-nmo">
                                                    <div className="option">
                                                        <FormControl 
                                                            variant="outlined" 
                                                            className = "formControlDocument"
                                                        >
                                                            <InputLabel>Selecciona una opción</InputLabel>
                                                            <Select 
                                                                labelId="lbl-doctype-login"
                                                                id="sel-doctype-login"
                                                                className="combo-select"
                                                                value={this.state.valueAbsentOption}
                                                                onChange={this.onChangeDocType}
                                                                label="Selecciona una opción"
                                                                displayEmpty 
                                                                IconComponent={
                                                                    props => (
                                                                        <img {...props} 
                                                                            src={this.state.iconSelectComp}
                                                                            style={{padding:'6px 8px 6px 6px'}}
                                                                        />)
                                                                }
                                                                MenuProps={{
                                                                    getContentAnchorEl:null,
                                                                    anchorOrigin:{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    },
                                                                    transformOrigin:{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }
                                                                }}>
                                                                <MenuItem value={0}>Sí, confirmo la atención</MenuItem>
                                                                <MenuItem value={1}>Deseo agendar otra visita</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    {   this.state.disabledContinue?
                                                        <div className="continue-button">
                                                            <Button 
                                                                id="btn-continue-home" 
                                                                className="absent-button"
                                                                variant="contained" 
                                                                color="primary" 
                                                                disabled={true} 
                                                            >
                                                                CONTINUAR
                                                            </Button>
                                                        </div>
                                                        :
                                                        <div className="continue-button">
                                                            <Button 
                                                                id="btn-continue-home" 
                                                                className="absent-button-active"
                                                                variant="contained" 
                                                                color="primary"  
                                                                onClick={this.onContinue}
                                                            >
                                                                CONTINUAR
                                                            </Button>
                                                        </div>
                                                    }
                                                    <AlertMessage className="alert-msg"/>
                                                </div>
                                            </div> 
                                    })()
                                    }
                                    
                                </div>  

                            </div>
                        <div>

                        </div>
                            { (this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_NOT_DONE && this.state.isNotDoneAvalaible) ?
                                <Returned onOpenPlaces = {this.onOpenPlaces} /> 
                                :
                                (this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_NOTDONE_TRAZA && this.state.isNotDoneReschedule) ?
                                <Returned onOpenPlaces = {this.onOpenPlaces} /> 
                                :
                                null
                            }
                            { 
                                this.props.orderDetail.activeStatus === CONSTANTS.STATUS.PENDIENTE_PAGO && this.props.orderDetail.isUpFront === true ?
                                <Payment onOpenPlaces = {this.onOpenPlaces} /> : null
                            }
                            {
                            this.state.isDevolution ?
                                    <DevolutionWeb onOpenPlaces = {this.onOpenPlaces} />
                                : this.props.orderDetail.activeStatus != CONSTANTS.STATUS.WO_PRENOTDONE ?
                                <div>
                                    <TabsHome
                                        provision={this.props.orderDetail}
                                        onTabClick = {this.onTabClick}
                                    /> 
                                </div>
                                : null
                              } 
                            {
                            (() => {
                                if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART 
                                    && this.props.orderDetail.woPreStart.availableTracking == true){
                                    return null;
                                }
                                else if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRENOTDONE){
                                    return null;
                                }
                                else{
                                    return <div className="bg-full-mob">
                                        <div className="container">
                                        <div className="box-links">
                                            { this.state.isDevolution && <div className="link-items d-flex justify-content-between" onClick ={this.onOpenDevolution} >
                                                    <div className="d-flex">
                                                        <div className="icon">
                                                            <img id="img-user-home" src={iconDevolution} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            <p id="lbl-contact-home" className="txt1">Devolución de pago</p>
                                                        </div>
                                                    </div>
                                                    <div className="act">
                                                        <img id="img-arrow1-home" src={iconArrow} alt="" />
                                                    </div>
                                                </div>
                                            }
                                                
                                            </div>
                                            {/* quien atendera al tecnico */}
                                            <div className={`box-links ${this.state.isDetailBlocked || this.state.isDevolution ? 'opacity' : ''}`}>
                                                <div className="link-items d-flex justify-content-between"
                                                    onClick ={this.onOpenContact}
                                                >
                                                    <div className="d-flex">
                                                        <div className="icon">
                                                            <img id="img-user-home" src={iconUser} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            <p id="lbl-contact-home" className="txt1">¿Quién atenderá al técnico?</p>
                                                        </div>
                                                    </div>
                                                    <div className="act">
                                                        <img id="img-arrow1-home" src={iconArrow} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* direccion de instalacion */}
                                            <div className={`box-links ${this.state.isDetailBlocked || this.state.isDevolution ? 'opacity' : ''}`}>
                                                <div className="link-items d-flex justify-content-between"
                                                    onClick = {this.onOpenAddress}>
                                                    <div className="d-flex">
                                                        <div className="icon">
                                                            <img id="img-location-home" src={iconLocation} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            <p id="lbl-location-home" className="txt1">Dirección de instalación</p>
                                                            <div className="more">
                                                                <p id="lbl-address-home" className="txt2">{this.addressCapitalize()}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="act">
                                                        <img id="img-arrow2-home" src={iconArrow} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Detalle de mi pedido */}
                                            <div className={`box-links ${(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.PENDIENTE_PAGO 
                                                                        || this.props.orderDetail.activeStatus === CONSTANTS.STATUS.CANCELADA_ATIS) ? 'opacity' : ''}`}>

                                                {this.props.orderDetail.components.length > 0 &&

                                                    <div className={`collapse-home ${this.state.collapse ? 'color-bluee' : 'color-greey'} `}>
                                                        <ExpansionPanel
                                                            expanded={this.state.collapse}
                                                            onChange={this.onChangeCollapse}
                                                        >
                                                            <ExpansionPanelSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography component="div">
                                                                    <div className="d-flex">
                                                                        <img id="img-detail-home" src={iconTrioMovPurple} alt="" />
                                                                        <span id="lbl-detail-home" className="txt1">Detalle de mi pedido</span>
                                                                    </div>
                                                                </Typography>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails>

                                                                <div className="wrp-detail flex-wrap">
                                                                    {
                                                                        this.props.orderDetail.components.map(element => {
                                                                            return (
                                                                                <div className="items-desc d-flex">
                                                                                    <div className="icon">
                                                                                        <img id="img-arrow3-home" src={iconArrow} alt="" />
                                                                                    </div>
                                                                                    <div className="desc">
                                                                                        <p id="lbl-title-home" className="txt1-desc">{element.title}</p>
                                                                                        <p id="lbl-description-home" className="txt2-desc">{element.description}</p>
                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                    
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div> 
                                }
                            })()
                            }
                            { (() => {
                                if(this.props.orderDetail.activeStatus == CONSTANTS.STATUS.WO_PRESTART 
                                    && this.props.orderDetail.woPreStart.availableTracking == true){
                                    return null;
                                } else if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE){
                                    return null;
                                } else{
                                    return <div className="wrp-cancel">

                                        {this.props.orderDetail.activeStatus != CONSTANTS.STATUS.ACTIVE ?
                                            <a id="lnk-cancel-home" className="link-off" disabled>CANCELAR PEDIDO</a>
                                            :
                                            <a id="lnk-cancel-home" onClick={this.onClickCancel} className="link">CANCELAR PEDIDO</a>
                                        }
                                    </div> 
                                }
                            })()
                            }
                        </div>
                        
                    }
                })()
                }

                <Rating 
                    openRating = { this.state.openRating } 
                    ratingSent = {this.state.ratingSent} 
                    selectedStar = {this.state.selectedStar} 
                    questionList = { this.state.questionList } 
                    onCloseRating={ this.onCloseRating } 
                    onAcceptRating={ this.onAcceptRating } 
                    onChangeValue = { this.onChangeValue } 
                    onChangeText = {this.onChangeText} 
                    starCount = { this.state.starCount} 
                />
                <Alert openAlert={ this.state.openAlert } errorMsj={ this.state.errorMsj } onClose={ this.onCloseAlert } onAccept={ this.onCloseAlert } />
                
                {
                (() => {
                    if(this.state.isFullMap){
                        return null;
                    } else if(this.props.orderDetail.activeStatus === CONSTANTS.STATUS.WO_PRENOTDONE){
                        return null;
                    }else{
                        return <Footer /> ;
                    }
                })()
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    
    return {
        user: state.security.userData,
        orderList: state.security.orderList, 
        orderDetail: state.security.orderDetail, 

        schedule: state.schedule.scheduleData,
        technician: state.schedule.scheduleTechnician,
        validHeader: state.security.userWebView,

        userLoginTime: state.security.userLoginTime,

        //config seguridad
        orderCode: state.security.orderCode,
        docNumber: state.security.documentNumber
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getScheduleAvailability: () => dispatch(scheduleActions.getScheduleAvailability()),
        getRating: (request) => dispatch(ratingActions.getRating(request)),
        setRating: (request) => dispatch(ratingActions.setRating(request)),

        provisionUpdate: (data) =>dispatch(securityActions.provisionUpdate(data)),
        setUpdateShowLocation: () => dispatch(provisionActions.setUpdateShowLocation()),

        //getOrders: () => dispatch(securityActions.getOrders()),
        getScheduledOrder : () => dispatch(scheduleActions.getScheduledOrder()),

        getProvisionDetail: (request) => dispatch(securityActions.getProvisionDetail(request)),
        setUpdateActivity: (value) => dispatch(securityActions.setUpdateActivity(value)),
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

