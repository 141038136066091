import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../header/Header';
import Footer from '../../common/elements/Footer';
import ReturnButton from '../../../components/common/elements/ReturnButton';

import * as provisionSelectors from '../../../store/selectors/provision.selectors';
import * as scheduleActions from '../../../store/actions/schedule.actions';
import Contacts from '../Contacts';
import { isMobile } from "react-device-detect";

class ContactsMovil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRadio: 'on'
        }
    }

   
    componentDidMount = () =>{
        console.log("prosp contact",this.props)
        if (this.props.orderDetail.contacts !== null) {
            if (this.props.orderDetail.contacts.length > 0) {
                this.setState({ selectedRadio: 'off' });
                this.props.setScheduleContactEdit(false);
            }

        }

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'datos de contacto',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });
    }
    back = () => {
        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'datos de contacto',
            eventAction: 'click',
            eventLabel:  'volver',
            valorElemento: this.props.orderDetail.activeStatus,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });


        this.props.history.goBack();
        
    }
    render() {
        return (
            <div className='contenedor-home'>
                <Header/>
                <div id="contacts-movil">
                    <div className="container">
                        <div className={this.props.validHeader === 1 && isMobile ? "content-body-wo-header":"content-body"}>
                            <div className="back">
                                <ReturnButton onClick={this.back}/>
                            </div>
                            <Contacts onContactClick = {this.props.location.onTabClick} />
                        </div>
                    </div>

                </div>
                <Footer/>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        orderDetail: state.security.orderDetail, 
        validHeader: state.security.userWebView

    }
}


const mapDispatchToProps = dispatch => {
    return {
        setScheduleContactEdit: (flag) => dispatch(scheduleActions.setScheduleContactEdit(flag)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsMovil);