import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Schedule.scss';

import 'moment/locale/es';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { isMobile } from "react-device-detect";

import { CONSTANTS } from '../../utils/Constants';
import Alert from '../modals/Alert'
import Loading from '../common/elements/Loading'
import Header from '../header/Header';
import Calendario from './calendar/Calendar';
import CalendarioMob from './calendar/CalendarMob';
import BotonVolver from '../common/elements/ReturnButton';
import iconCalendar from '../../resources/svg/agenda/iconCalendar.svg';
import iconAlerta from '../../resources/svg/agenda/iconAlerta.svg';
import ScheduleRadio from './radio/ScheduleRadio';
import NextRadio from './radio/TomorrowRadio';
import AfterRadio from './radio/TomorrowRadio';
import Footer from '../common/elements/Footer';
import { Avatar } from '@material-ui/core';
import iconBack from '../../resources/svg/token/iconBack.svg';
import * as scheduleActions from '../../store/actions/schedule.actions';
import * as commonSelectors from '../../store/selectors/common.selectors';
import * as provisionSelectors from '../../store/selectors/provision.selectors';

class Schedule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disabledContinue: true,
            disabledTurns: false,
            selectedTurn:'',
            calendarDate: this.getFirstEnabledDay(),
            disabledMorning: false,
            disabledEvening: false,

            isSelectedDate: false,
            isValueDate:false,

            nextDay:'',
            afterDay:'',
            valueNextDay:'',
            valueAfterDay:'',
            disabledNext:false,
            disabledAfter:false,
            disabledOptionNext:false,
            disabledOptionAfter:false,

            showTomorrowBox: true,
            showCalendarBox: true,
            showTurnsBox:true,
            showOtherDay:false,

            showLoading:false,
            showAlert: false,
            errorMsj: '',

            completeDays: this.completeArrayDays(),
        }

    }

    completeArrayDays = () => {
        let temporaryArray = [];
        console.log("cupos store",this.props.cupos.date)
        for(let i=0; i < this.props.cupos.date.length - 1; i++){
            temporaryArray.push(this.props.cupos.date[i]);

            let exist = false;
            let oneMore = 1;

            do{
                let nextGenerDay = moment(this.props.cupos.date[i].day).add(oneMore, "day").format('YYYY-MM-DD'); //2 día generado
                this.props.cupos.date.map(item =>{
                    if(item.day === nextGenerDay){ exist = true }
                })  
                if(!exist){
                    let complementaryDay = {
                        day: nextGenerDay,
                        slot: [
                            {
                                name: "PM",
                                quantity: "0"
                            },
                            {
                                name: "AM",
                                quantity: "0"
                            }
                        ]
                    };
                    oneMore++;
                    temporaryArray.push(complementaryDay);
                }
            }while(oneMore > 1 && moment(this.props.cupos.date[i].day).add(oneMore, "day").format('YYYY-MM-DD') < moment(this.props.cupos.date[i+1].day).format('YYYY-MM-DD'));
                
        }

        temporaryArray.push(this.props.cupos.date[this.props.cupos.date.length-1]);

        return temporaryArray;
    }

    componentDidMount = () => {
        
        console.log("compel arrya",this.state.completeDays)
        console.log("prosp",this.props);

        let next            = "";
        let after           = "";
        let nameDayNext     = "";
        let nameDayAfter    = "";

        let variosSlots     = this.state.completeDays.length > 1;

        
        if(!variosSlots) {

            next = this.state.completeDays[0]?.day.split('-');
            nameDayNext = moment(this.state.completeDays[0].day,'YYYY-MM-DD').format('dddd');


        } else {
            next = this.state.completeDays[0]?.day.split('-');
            after = this.state.completeDays[1]?.day.split('-');
    
            nameDayNext = moment(this.state.completeDays[0].day,'YYYY-MM-DD').format('dddd');
            nameDayAfter = moment(this.state.completeDays[1]?.day,'YYYY-MM-DD').format('dddd');
           
        }    

        this.setState({
            nextDay: `${'Mañana ' + nameDayNext.toLowerCase() + ', ' + next[2] + ' de ' + commonSelectors.getMonthName(next[1]).toLowerCase()}`,
            afterDay: nameDayAfter == "" ? '' : `${nameDayAfter.charAt(0).toUpperCase() + nameDayAfter.slice(1).toLowerCase() + ', ' + after[2]  + ' de ' + commonSelectors.getMonthName(after[1]).toLowerCase()}`,

            valueNextDay: this.state.completeDays[0].day,
            valueAfterDay: variosSlots ? this.state.completeDays[1].day : '',
            disabledOptionNext: this.onTomorrowAvailability(0),
            disabledOptionAfter: variosSlots ? this.onTomorrowAvailability(1) : true,
        })

        //Validar los turnos del primer día hábil en Desktop
        if(!isMobile){
            this.onValidateAvailability(moment(this.getFirstEnabledDay()).format("YYYY/MM/DD"));
        }

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'page view',
            eventAction: 'view',
            eventLabel: 'agendar pedido',
            valorElemento: this.props.orderDetail.activeStatus,
            path: this.props.history.location.pathname,
            nonInteraction: '1',

            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : ''
        });


    }

    onBack = () => {

        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.goBack();
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
        
    }

    saveDate = (date) => {
        
        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            
            this.setState({
                calendarDate: moment(new Date(date)).format("YYYY-MM-DD"),
                disabledContinue: true,
                disabledTurns: true,
                isSelectedDate: true,
    
                showTomorrowBox: false
            })
    
            this.onValidateAvailability(date);

        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
        
    }

    textFullMonth = () => {
        let allMonth = '';
        if(this.state.calendarDate != null){
            let scheduleDate = this.state.calendarDate.split('-');
            let getNameMonth = commonSelectors.getMonthName(scheduleDate[1]);
            allMonth = `${'de ' + getNameMonth.charAt(0).toUpperCase() + getNameMonth.slice(1).toLowerCase()}`;
        }
        return allMonth;
    }

    textFullDay = () => {
        let allDay = '';
        if(this.state.calendarDate != null){
            let nameDay = moment(this.state.calendarDate,'YYYY-MM-DD').format('dddd');
            let scheduleDate = this.state.calendarDate.split('-');
            allDay = `${nameDay.charAt(0).toUpperCase() + nameDay.slice(1) +" "+ scheduleDate[2]+' '}`
        }

        return allDay;
    }

    getFirstEnabledDay = () => {
        let arrayDates = [];
        this.props.cupos.date.map(item => {
            let cupos = 0;
            item.slot.map(slotDay => {
              cupos = cupos + Number(slotDay.quantity);
            })

            if(cupos>0){
            arrayDates.push(moment(item.day).format('YYYY-MM-DD'));
            }
            
          })

        return arrayDates[0]
    }

    onSchedule = () => {

        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.setSchedule();
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

    }

    setSchedule = () => {

        let scheduleDate = this.state.calendarDate.split('-');
        let request = {
            selectedDate: scheduleDate[2]+'/'+scheduleDate[1]+'/'+scheduleDate[0],
            selectedRange: this.state.selectedTurn
        }

        let scheduleType = this.props.schedule ? "1" : "0";

        this.setState({ showLoading: true });
        this.props.setSchedule(request).then(resp => {
            this.setState({ showLoading: false });

            if(resp.status === 'OK'){
                this.props.history.push({
                    pathname: CONSTANTS.PATH.SCHEDULE_CONFIRM
                }, { scheduleType: scheduleType })
            }
            else if(resp.status === 'ERROR'){
                this.setState({
                    showAlert: true,
                    errorMsj: resp.message
                })
            }
            
        });

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'agendar pedido',
            eventAction: 'click',
            idPedido: this.props.orderDetail.xaRequest,
            eventLabel: 'agendar pedido',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            fechaAgendamiento: moment(this.state.calendarDate).format('DD/MM/YYYY'),
            nonInteraction: '1',
            
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : '' 
        });
    }

    onChangeTurn = (value) => {

        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            
            this.setState({
                selectedTurn: value,
                disabledContinue: false,
                disabledTurns: false
            })

        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'agendar pedido',
            eventAction: 'click',
            idPedido: this.props.orderDetail.xaRequest,
            eventLabel: value === 'AM' ? 'turno mañana' : 'turno tarde',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            fechaAgendamiento: moment(this.state.calendarDate).format('DD/MM/YYYY'),
            nonInteraction: '1',
            
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : '' 
        });
    }

    onChangeNext = (value) => {
        
        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            
            this.setState({
                calendarDate: value,
    
                disabledAfter: true,
                isSelectedDate: true,
                showCalendarBox:false,
                showOtherDay:true
            })

        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

    }

    onChangeAfter = (value) => {
        
        if(commonSelectors.validSessionTime(this.props.userLoginTime)){
            
            this.setState({
                calendarDate: value,
    
                disabledNext: true,
                isSelectedDate: true,
                showCalendarBox:false,
                showOtherDay:true
            })

        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

    }

    onSelectOther = () => {

        if(commonSelectors.validSessionTime(this.props.userLoginTime)){

            this.setState({
                showOtherDay:false,
                showCalendarBox: true,
                isSelectedDate: true,
                showTomorrowBox: false,
                isValueDate:true,
                disabledTurns: true,
                disabledContinue: true
            })
            //console.log("en schedule",this.state.isValueDate)
        }else{
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }

        window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'agendar pedido',
            eventAction: 'click',
            idPedido: this.props.orderDetail.xaRequest,
            eventLabel: 'elegir otro día',
            valorElemento: this.props.orderDetail.activeStatus,
            correoElectronico: commonSelectors.sha256(this.props.orderDetail.customer.mail),
            nombrePedido: this.props.orderDetail.productName.toLowerCase(),
            fechaAgendamiento: moment(this.state.calendarDate).format('DD/MM/YYYY'),
            nonInteraction: '1',
            
            //datos genéricos
            userId: this.props.user.documentNumber,
            tipoCliente: this.props.user.documentType.toLowerCase(),
            numero: this.props.orderDetail.customer.phoneNumber,
            productos: this.props.orderDetail.productName ? this.props.orderDetail.productName.toLowerCase().trim() : '' 
        });

    }

    onCloseAlert = () => {
        this.setState({
            showAlert: false
        })
    }

    onValidateAvailability = (date) => {
        console.log("formato fecha antes edl if",date)
        if(moment(date).format("YYYY/MM/DD") > moment(new Date()).format("YYYY/MM/DD")){
            console.log("formato fecha",date)
            this.setState({
                disabledMorning: false,
                disabledEvening: false
            })
            this.state.completeDays.map(item => {
                if(item.day === moment(new Date(date)).format('YYYY-MM-DD')){
                    item.slot.map(slotDay => {
                        if(slotDay.name === 'AM'){
                            if(slotDay.quantity === '0'){
                                this.setState({disabledMorning: true})
                            }
                        }
                        else if(slotDay.name === 'PM'){
                            if(slotDay.quantity === '0'){
                                this.setState({disabledEvening: true})
                            }
                        }
                    })
                    if(item.slot.length===1){this.setState({disabledMorning: true})}
                }
            })
        }else{
            this.setState({
                disabledMorning: true,
                disabledEvening: true
            })
        }
        
    }

    onTomorrowAvailability = (position) => {
        let cupos = 0;
        this.state.completeDays[position].slot.map(slotDay => {
            cupos = cupos + Number(slotDay.quantity);
        })
        return cupos === 0 ? true: false ;
    }

    onTabClick = () => {
        if(!commonSelectors.validSessionTime(this.props.userLoginTime)){
            this.props.history.push({
                pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN
            });
        }
    }

    render() {

        return (
            <div>
                <Loading id="loading-schedule" showLoading={this.state.showLoading} />
                <Header onHeaderClick = {this.onTabClick} />
                <div id="schedule">
                    <div className="container">
                        <div className={this.props.validHeader === 1 && isMobile ? "content-body-wo-header":"content-body"}>
                            <BotonVolver onClick={this.onBack} />
                            <div className="boxSchedule d-flex flex-column">
                                <p id="lbl-title-schedule" className="titleSchedule">Agendamiento</p>
                                <p id="lbl-subtitle-schedule" className="detailSchedule">Selecciona la fecha <span>y hora </span>en la que estarás en casa para {this.props.schedule != null ? 'reprogramar' : 'programar'} la instalación de tu pedido.</p>

                                <div className= {this.state.showTomorrowBox? "boxDaysMob":"boxDaysMobHide"}>
                                    <div style={{display:this.state.disabledNext?'none':'block'}}>
                                        <NextRadio descriptionDay={this.state.nextDay} valueDay={this.state.valueNextDay} onChangeTomorrow={this.onChangeNext} disabledOption={this.state.disabledOptionNext}/>
                                    </div>
                                    <div style={{display:this.state.disabledAfter?'none':'block'}}>
                                        <AfterRadio descriptionDay={this.state.afterDay} valueDay={this.state.valueAfterDay} onChangeTomorrow={this.onChangeAfter} disabledOption={this.state.disabledOptionAfter}/>
                                    </div>
                                </div>
                                {/* calendario movil */}
                                <div className={this.state.showCalendarBox?"boxCalendarMob":"boxCalendarMobHide"}>
                                    <CalendarioMob 
                                        id={this.state.isSelectedDate?'calendarioMobSelected':'calendarioMob'} 
                                        clase={this.state.isSelectedDate?'calendarioMobSelected':'calendarioMob'} 
                                        saveDate={this.saveDate} 
                                        isSelectedDate={this.state.isSelectedDate} 
                                        valueDate={this.state.isValueDate} 
                                        cupos={this.state.completeDays} 
                                        moreWeeks={true}/>
                                </div>
                                {/* calendario desktop */}
                                
                                {
                                    (() => {
                                        return  !isMobile 
                                            && <div className="boxCalendario d-flex justify-content-between mb-flex-column showDeskTable">
                                                    <Calendario 
                                                        id='calendario' 
                                                        clase="calendario" 
                                                        saveDate={this.saveDate} 
                                                        cupos={this.state.completeDays} 
                                                        moreWeeks={true}/>
                                                    <div className="boxTurns">
                                                        <p id="lbl-detailturno-schedule" className="detailTurns">Fecha seleccionada:</p>
                                                        <div className="dateTurns d-flex flex-row">
                                                            <img src={iconCalendar} alt=""/>
                                                            <p id="lbl-descriptionturn-schedule" className="descriptionTurns"><span>{this.textFullDay()}</span>{this.textFullMonth()}</p>
                                                        </div>
                                                        
                                                        <ScheduleRadio onChangeTurn={this.onChangeTurn} disabledTurns={this.state.disabledTurns} disabledMorning={this.state.disabledMorning} disabledEvening={this.state.disabledEvening}></ScheduleRadio>
                                                        
                                                    </div>
                                                </div>
                                    })()
                                    
                                }
                                <div className={this.state.isSelectedDate?"boxTurnsMob":"boxTurnsMobHide"}>
                                    <p id="lbl-detailturnmob-schedule" className="detailTurnsMob">Elige el horario que más te acomode para la visita del técnico. </p>
                                    <ScheduleRadio onChangeTurn={this.onChangeTurn} disabledTurns={this.state.disabledTurns} disabledMorning={this.state.disabledMorning} disabledEvening={this.state.disabledEvening}></ScheduleRadio>
                                </div>
                            </div>
                            {/* {   (this.props.schedule != null &&
                                    Number(this.props.schedule.scheduleLeftTimes) === 1 ) ? 
                                    <div className = "boxReschedulePolity d-flex">
                                        <div className="icon">
                                            <img id="img-warning-Schedule" className="logo" src={iconAlerta} alt=""/>
                                        </div>
                                        <p id="lbl-warning-Schedule" className="descSingle">Reagendaste tu cita de instalación anteriormente, sólo podrás hacerlo una vez más</p>
                                    </div> 
                                :
                                    null
                            } */}
                            <div className={this.state.isSelectedDate?"boxButton":"boxButtonHide"}>
                                <Button  onClick={this.onBack} 
                                        id="btn-back-token" 
                                        className="back-button" 
                                        variant="outlined"
                                        startIcon={<Avatar src={iconBack}/>}
                                        endIcon={<Avatar src={iconBack}/>}>
                                    ATRÁS
                                </Button>
                                <Button 
                                    id="btn-schedule" 
                                    className="buttonSchedule pointer" 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={this.onSchedule} 
                                    disabled={this.state.disabledContinue}>
                                    {(isMobile && this.props.schedule != null) ? "REAGENDAR PEDIDO" : "AGENDAR PEDIDO"}
                                </Button>
                            </div>
                            <div className={this.state.showOtherDay?"boxButtonOther":"boxButtonOtherHide"} >
                                <Button 
                                    id="btn-otherday-schedule" 
                                    className="buttonOtherDay pointer" 
                                    variant="contained" color="primary" 
                                    onClick={this.onSelectOther} 
                                    startIcon={<Avatar src={iconBack}/>}
                                    endIcon={<Avatar src={iconBack}/>}>
                                    ELEGIR OTRO DÍA
                                </Button>
                            </div>
                        </div>
                    </div> 
                </div>
                {/* <Footer/> */}
                <Alert id="mdl-error-schedule" openAlert={this.state.showAlert} errorMsj={this.state.errorMsj} onClose={this.onCloseAlert} onAccept={this.onCloseAlert} />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.security.userData,
        //provision: provisionSelectors.getOrderById(state.provision.provisionIdSelected, state.provision.provisionData),
        orderDetail: state.security.orderDetail,
        schedule: state.schedule.scheduleData,
        technician: state.schedule.scheduleTechnician,
        cupos: state.schedule.scheduleCupos,
        //cupos: commonSelectors.dummyDataCupos(),
        validHeader: state.security.userWebView,

        userLoginTime: state.security.userLoginTime,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSchedule: (request) => dispatch(scheduleActions.setSchedule(request)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);