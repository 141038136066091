import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CONSTANTS } from './utils/Constants';

import StoreConfig from './store/configuration/Store.configuration.js'
import IsAuthUser from './components/common/routes/IsAuthUser';
import HistoryValid from './components/common/routes/HistoryValid';

import Login from './components/login/Login';
import Onboarding from './components/onboarding/Onboarding';
import Token from './components/token/Token';
import List from './components/list/List';
import Home from './components/home/Home';
import Schedule from './components/schedule/Schedule';
import Address from './components/address/Address';
import Contacts from './components/contacts/movil/ContactsMovil';
import Confirmation from './components/confirmation/Confirmation';
import Cancel from './components/cancel/Cancel';
import ConfirmationCancel from './components/cancel/confirmationCancel/ConfirmationCancel';
import Maintenance from './components/maintenance/Maintenance';
import Admin from './components/admin/LoginAdmin';
import ExternalAccess from './components/external/External';
import Map from './components/map/Map';
import Devolution from './components/devolution/Devolution';
import Fault from './components/fault/Fault';
import Absent from './components/absent/Absent';

const store = StoreConfig().store;
const persistor = StoreConfig().persistor;

class App extends Component {
  render() {  
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router basename={CONSTANTS.PATH.BASENAME} history={createBrowserHistory} >
            <Route render={({ location }) => (
              <div className='prueba'>
                <IsAuthUser location={location}>
                  <Route exact path={CONSTANTS.PATH.BASE} render={() => <Redirect to={CONSTANTS.PATH.LOGIN} />}></Route>

                  {/*ADMIN*/}
                  <Route exact path={CONSTANTS.PATH.EXTERAL_ACCESS} component={ExternalAccess}></Route>

                  {/*ADMIN*/}
                  <Route exact path={CONSTANTS.PATH.LOGIN_ADMIN} component={Admin}></Route>

                  {/*MANTENIMIENTO*/}
                  <Route exact path={CONSTANTS.PATH.MAINTENANCE} component={Maintenance}></Route>

                  {/* PAGINAS DE LOGIN */}
                  <Route exact path={CONSTANTS.PATH.ONBOARDING} component={Onboarding}></Route>
                  <Route exact path={CONSTANTS.PATH.LOGIN} component={Login}></Route>

                  {/* PAGINAS DE TOKEN */}
                  <Route exact path={CONSTANTS.PATH.TOKEN} component={Token}></Route>

                  {/* PAGINAS DE TOKEN */}
                  <Route exact path={CONSTANTS.PATH.LIST} component={List}></Route>

                  {/* PAGINAS DE FAULT */}
                  <Route exact path={CONSTANTS.PATH.FAULT} component={Fault}></Route>

                  {/* PAGINAS DE Home */}
                  <Route exact path={CONSTANTS.PATH.HOME} component={Home}></Route>

                  {/* PAGINAS DE SCHEDULE */}
                  <Route exact path={CONSTANTS.PATH.SCHEDULE} component={Schedule}></Route>
                  <Route exact path={CONSTANTS.PATH.SCHEDULE_CONFIRM} component={Confirmation}></Route>

                  {/* PAGINAS DE CONTACTS */}
                  <Route exact path={CONSTANTS.PATH.CONTACTS} component={Contacts}></Route>
                  {/* PAGINAS DE ADDRESS */}
                  <Route exact path={CONSTANTS.PATH.ADDRESS} component={Address}></Route>

                  {/* PAGINAS DE CANCEL */}
                  <Route exact path={CONSTANTS.PATH.CANCEL} component={Cancel}></Route>

                  {/* PAGINAS DE CONFIRMATION CANCEL */}
                  <Route exact path={CONSTANTS.PATH.CONFIRMATION_CANCEL} component={ConfirmationCancel}></Route>

                  {/* PAGINAS DE MAPA */}
                  <Route exact path={CONSTANTS.PATH.MAP} component={Map}></Route>

                  {/* PAGINAS DE DEVOLUCIÓN DE PAGOS */}
                  <Route exact path={CONSTANTS.PATH.DEVOLUTION} component={Devolution}></Route>

                  {/* PAGINAS DE CLIENTE AUSENTE */}
                  <Route exact path={CONSTANTS.PATH.ABSENT} component={Absent}></Route>

                </IsAuthUser>
                <HistoryValid location={location} />
              </div>
            )} />
          </Router>
        </PersistGate>
      </Provider>
    )
  }
}

export default App;
